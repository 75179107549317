import {
    BODY_MESSAGE_MAX_LENGTH,
    ButtonTypes,
    FOOTER_MESSAGE_MAX_LENGTH,
    HEADER_MESSAGE_MAX_LENGTH,
    HeaderTypes,
    NotificationCategory,
    SupportedLanguage,
    TEMPLATE_NAME_MAX_LENGTH,
    TemplateStatusType,
} from "../../../../utils/FacebookAPI";
import {
    ButtonCompoundType,
    ButtonType, HeaderType,
    ModelItem,
    ModelListItem,
    TemplatePayload,
} from "../../types/PretemplateDataModel";
import {i18n} from "../../../../i18n/i18n";
import {Store, VerifiableStore} from "../../core/Store";
import {DetailVerifiable} from "../../core/Validation";
import {reaction} from "mobx";
import {PretemplateExample} from "./PretemplateExampleFormStore";
import {PretemplateButtons} from "./PretemplateButtonsFormStore";
import {QuickReply} from "./buttons/QuickReply";
import {PhoneNumber} from "./buttons/PhoneNumber";
import {Url} from "./buttons/Url";

export namespace Pretemplates {
    export type FormData = {
        name: string,
        code: string,
        category: NotificationCategory,
        language: SupportedLanguage,
        header: {
            type: HeaderTypes,
            data?: string,
        },
        body: string,
        footer: string,
        created_at?: number,
    }

    export type State = {
        processing: "idle"|"processing"|"processing-failed",
        mode: "idle"|"modifying"|"making",
        isModified: boolean,
    }

    export class FormState extends Store<State> {
        // eslint-disable-next-line
        constructor(initial?: State) {
            super(initial);
        }

        protected makeEmpty(): State {
            return {
                processing: "idle",
                mode: "idle",
                isModified: false,
            };
        }
    }

    export class FormStore extends VerifiableStore<FormData> {
        private pretemplates: Map<string, ModelListItem>;
        private state: FormState;

        public buttonsStore: PretemplateButtons.FormStore;
        public exampleStore: PretemplateExample.FormStore;

        constructor(pretemplates: Map<string, ModelListItem>, state: FormState, exampleStore: PretemplateExample.FormStore, buttonsStore: PretemplateButtons.FormStore) {
            super();

            this.state = state;

            this.pretemplates = pretemplates;
            this.exampleStore = exampleStore;
            this.buttonsStore = buttonsStore;

            /* Обновляем данные примеров */
            reaction(() => this.data.body, body => {
                const markers = body.match(/\{\{\d+\}\}/g) ?? [];
                const isOrdered = !markers.some((marker, index) => marker !== `{{${index+1}}}`)

                const exampleBody = this.exampleStore.data.body;
                const updated = markers.map((_, index) => (exampleBody && exampleBody[index]) ? exampleBody[index] : "");
                this.exampleStore.replaceData({body: updated});

                if (this.exampleStore.data.body?.length === 0 || !isOrdered) delete this.exampleStore.data.body;
            });

            reaction(() => this.data.header.data, header => {
                const markers = header?.match(/\{\{\d+\}\}/g) ?? [];
                if (markers.length === 1 && markers[0] === "{{1}}") {
                    this.exampleStore.replaceData({header: this.exampleStore.data.header ?? ""});
                } else {
                    delete this.exampleStore.data.header;
                }
            });

            // Стираем пустую строку "", чтобы она не попала в пример шаблона на выходе(при отправке результата)
            reaction(() => this.data.header.type, type => {
                if (type !== HeaderTypes.TEXT) delete this.exampleStore.data.header;
            });
        }

        clearStore() {
            super.clearStore();
            this.exampleStore?.clearStore();
            this.buttonsStore?.clearStore();
        }

        makeEmpty(): FormData {
            return {
                name: "",
                code: "",
                category: "UTILITY",
                language: SupportedLanguage.RUSSIAN,
                header: {type: HeaderTypes.NONE},
                body: "",
                footer: "",
            }
        }

        reflectModel(model: ModelItem) {
            let headerType = HeaderTypes.NONE;
            switch (model.payload.template.header?.type) {
                case "text": headerType = HeaderTypes.TEXT; break;
                case "document": headerType = HeaderTypes.DOCUMENT; break;
                case "image": headerType = HeaderTypes.IMAGE; break;
                case "video": headerType = HeaderTypes.VIDEO; break;
            }

            this.replaceData({
                name: model.name,
                code: model.code,
                header: {
                    type: headerType,
                    data: model.payload.template.header?.payload,
                },
                body: model.payload.template.body,
                footer: model.payload.template.footer ?? "",
                category: model.category,
                language: model.language,
            });

            this.exampleStore.replaceData({
                header: model.payload.example?.header,
                body: model.payload.example?.body,
                buttonUrl: model.payload.example?.buttonUrl,
            });

            if (model.payload.template.buttons) {
                const result: PretemplateButtons.FormData = [];
                for (let button of model.payload.template.buttons) {
                    switch (button.type) {
                        case "quick_reply": {
                            const store = new QuickReply.FormStore();
                            store.replaceData({caption: button.caption});
                            result.push({type: ButtonTypes.QUICK_REPLY, substore: store});
                        } break;
                        case "phone_number": {
                            const store = new PhoneNumber.FormStore();
                            store.replaceData({caption: button.caption, phone: button.payload});
                            result.push({type: ButtonTypes.PHONE_NUMBER, substore: store});
                        } break;
                        case "url": {
                            const store = new Url.FormStore(this.exampleStore);
                            store.replaceData({caption: button.caption, url: button.payload});
                            result.push({type: ButtonTypes.URL, substore: store});
                        } break;
                    }
                }

                this.buttonsStore.replaceData(result);
            }
        }

        makeModel(): ModelItem {
            const payload: TemplatePayload = {template: {body: this.data.body}};

            if (this.data.header.type !== HeaderTypes.NONE) {
                let headerType: HeaderType["type"] = "text";
                switch (this.data.header.type) {
                    case HeaderTypes.TEXT: headerType = "text"; break;
                    case HeaderTypes.DOCUMENT: headerType = "document"; break;
                    case HeaderTypes.IMAGE: headerType = "image"; break;
                    case HeaderTypes.VIDEO: headerType = "video"; break;
                }

                payload.template.header = {
                    type: headerType,
                    payload: this.data.header.data
                }
            }

            if (this.data.footer) {
                payload.template.footer = this.data.footer;
            }

            payload.example = {
                header: this.exampleStore.data.header,
                body: this.exampleStore.data.body,
                buttonUrl: this.exampleStore.data.buttonUrl,
            };

            const result: ButtonType[] = [];
            for (let button of this.buttonsStore.data) {
                switch (button.type) {
                    case ButtonTypes.QUICK_REPLY: {
                        const data: QuickReply.FormData = button.substore.data;
                        result.push({type: "quick_reply", caption: data.caption});
                    } break;
                    case ButtonTypes.PHONE_NUMBER: {
                        const data: PhoneNumber.FormData = button.substore.data;
                        result.push({type: "phone_number", caption: data.caption, payload: data.phone});
                    } break;
                    case ButtonTypes.URL: {
                        const data: Url.FormData = button.substore.data;
                        result.push({type: "url", caption: data.caption, payload: data.url});
                    } break;
                }
            }

            if (result.length > 0) {
                payload.template.buttons = result as ButtonCompoundType;
            }

            return {
                name: this.data.name,
                code: this.data.code,
                status: {type: TemplateStatusType.PENDING},
                language: this.data.language,
                category: this.data.category,
                created_at: this.data.created_at ?? Date.now(),
                updated_at: Date.now(),
                payload: payload,
            }
        }

        get validationBlacklist(): (keyof FormData)[] {
            const blacklist: (keyof FormData)[] = [];

            if (this.state.data.mode === "modifying") { // Убираем из валидации заблокированные поля, если мы модифицируем существующий шаблон
                return [...blacklist, "code", "header", "body", "footer"];
            }

            if (this.data.header.type !== HeaderTypes.TEXT) { // Убираем из валидации "заголовок" если его нет
                return [...blacklist, "header"];
            }

            return blacklist;
        }

        get validationSchema(): DetailVerifiable.Schema<FormData> {
            return {
                name: [
                    [(value: string) => !value.length, i18n("templates.pretemplates.form.error.empty_name")]
                ],
                code: [
                    [(value: string) => !!this.pretemplates.get(value), i18n("templates.pretemplates.form.error.same_code")],
                    [(value: string) => !value.length, i18n("templates.pretemplates.form.error.template_code_is_missing")],
                    [(value: string) => value.length > TEMPLATE_NAME_MAX_LENGTH, i18n("templates.pretemplates.form.error.template_code.too_long")],
                    [(value: string) => !/^[a-z0-9_]*$/.test(value), i18n("templates.pretemplates.form.error.invalid_char")],
                ],
                header: [
                    [(value: FormData["header"]) => value.type === HeaderTypes.TEXT && (value.data?.length ?? 0) === 0, i18n("templates.pretemplates.form.error.header.empty")],
                    [(value: FormData["header"]) => value.type === HeaderTypes.TEXT && (value.data?.length ?? 0) > HEADER_MESSAGE_MAX_LENGTH, i18n("templates.pretemplates.form.error.header.too_long")],
                ],
                body: [
                    [(value: string) => !value.length, i18n("templates.pretemplates.form.error.body.empty")],
                    [(value: string) => value.length > BODY_MESSAGE_MAX_LENGTH, i18n("templates.pretemplates.form.error.body.too_long")],
                    [
                        (value: string) => {
                            const markers = value.match(/\{\{\d+\}\}/g) ?? [];
                            const isValid = markers.length > 0 && !markers.every((marker, index) => marker === `{{${index+1}}}`);
                            return isValid;
                        },
                        i18n("templates.pretemplates.form.error.invalid_sequence")
                    ],
                ],
                footer: [
                    [(value: string) => value.length > FOOTER_MESSAGE_MAX_LENGTH, i18n("templates.pretemplates.form.error.footer.too_long")]
                ],
            };
        }

        validate(): DetailVerifiable.Result<FormData> {
            const report = super.validate();

            // Валидация зависимой формы, возможена рекурсивная проверка, если у зависимых форм есть свои зависимые формы
            const childrenIsValid = (this.buttonsStore?.validate()[0] ?? true);
            report[0] = report[0] && childrenIsValid;

            this.result = report;
            return report;
        }
    }
}
