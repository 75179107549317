import {useContext, useState, useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Link, Route, Switch, useRouteMatch, Redirect} from "react-router-dom";
import AnalyticsChart from './AnalyticsChart';
import authorizedAxios from '../../http/authorizedAxios';
import {withConditional} from "../../../hooks/ConditionalHOC";
import Loading from "../../component/loading/Loading";
import {MobXProviderContext} from "mobx-react";
import { AppStore } from '../../stores/AppStore';

import {PhonePreview} from "../../component/phone_preview/PhonePreview";
import Darts from "../../../image/Darts.png";
import Geoanalytics from "../../../image/Geoanalytics.svg";
import {getTimeString, useRefreshData} from "./Campaign";
import {RecipientsTableElement} from "./local_component/RecipientsTableElement";
import {CampaignSearchField} from "./local_component/CampaignSearchField";
import {ChartDataModel} from './AnalyticsChart';
import {DocumentStore} from "../../stores/DocumentStore";
import {Config} from "../../../config";
import {i18n} from "../../../i18n/i18n";

//Параметр fetchData - временный костыль, сделанный для обновления данных на странице списка рассылок при нажатии кнопки "Назад" в рассылке
export const CampaignElement = observer( ({element:{name, status, analytics_data, campaign_id, type, created_at, started_at, finished_at}, fetchData}: any) => {
    const getDefaultChartData = () => {
        const chartData: ChartDataModel = [
            {
                hour: parseInt(created_at),
                read: 0,
                replied: 0
            }
        ];
        for (var i = 1; i < 25; i++) {
            chartData.push({hour: chartData[i - 1].hour as number + 60 * 60,  read: null, replied: null})
        }
        return chartData;
    };
    const [statusData, setStatusData] = useState({status: status, timeString: ''});
    const [analyticsData, setAnalyticsData] = useState(analytics_data);
    const [chartData, setChartData] = useState<ChartDataModel>(getDefaultChartData());
    const [recipients, setRecipients] = useState<any>({
        loading: true,
        data: null
    });
    const [phonePreview, setPhonePreview] = useState<any>({
        loading: true,
        data: null
    });

    const getCampaignTimeString = () => {
        let campaignTimeString = '';
        if(finished_at) {
            campaignTimeString = getTimeString(finished_at);
        } else if(started_at) {
            campaignTimeString = getTimeString(started_at);
        } else if(created_at) {
            campaignTimeString = getTimeString(created_at);
        }
        return campaignTimeString;
    };

    const loadPhonePreview = async () => {
        if(phonePreview.loading) {
            await authorizedAxios.post(
                '/campaigns.php',
                {action: 'preview', campaign_id: campaign_id},
                {headers: {"Content-Type": "application/json"}}
            ).then(result => {
                let res:any = {
                    fileType: '',
                    fileLink: '',
                    messageHeader: '',
                    messageText: '',
                    messageFooter: '',
                    messageBtns: []
                };
                result.data.data.forEach((elem:any) => {
                    switch (elem.type) {
                        case 'HEADER':
                            if (elem.format === 'TEXT') {
                                res.messageHeader = elem.text.replace("{{1}}", result.data.params.header_parameter ?? "{{1}}");
                            } else if (elem.format === 'IMAGE') {
                                res.fileType = 'image';
                                res.fileLink = result.data.params.header_parameter;
                            } else if (elem.format === 'VIDEO') {
                                res.fileType = 'video';
                                res.fileLink = result.data.params.header_parameter;
                            } else if (elem.format === 'DOCUMENT') {
                                res.fileType = 'pdf';
                                res.fileLink = result.data.params.header_parameter.split('/').pop();
                            }
                            break;
                        case 'BODY':
                            res.messageText = elem.text.replaceAll(/\{\{(\d+)\}\}/g, (match: any, content: string) => {
                                const index = parseInt(content)-1;
                                const payload = result.data.params.body_substitutions;
                                if (payload) {
                                    const replacement = payload[index];
                                    if (replacement) {
                                        return replacement;
                                    }
                                }
                                return match;
                            });
                            break;
                        case 'FOOTER':
                            res.messageFooter = elem.text;
                            break;
                        case 'BUTTONS':
                            elem.buttons.forEach((button: { text: any, type: any }) => {
                                res.messageBtns.push({title: button.text, type: button.type});
                            })
                            break;
                    }
                });
                setPhonePreview({loading: false, data: res});
            }).catch(err => {
                setPhonePreview({loading: false, data: null});
            });
        }
    };

    const loadCampaignAnalytics = async () => {
        authorizedAxios.post(
            '/analytics_data.php',
                {action: 'campaign_analytics', campaign_id: campaign_id},
                {headers: {"Content-Type": "application/json"}}
        ).then(response => {
            if (response.data) {
                setAnalyticsData(response.data);
            }
        });
    };

    const loadChartData = () => {
        authorizedAxios.post<ChartDataModel>(
            '/analytics_data.php',
            JSON.stringify({action: "chart", campaign_id: campaign_id}),
            {headers: {"Content-Type": "application/json"}}
        ).then((response) => {
            if (response && response.data && Object.keys(response.data).length !== 0) {
                setChartData(response.data);
            }
        });
    };

    const loadRecipients = async () => {
        await authorizedAxios.post(
            '/analytics_data.php',
            {action: 'campaign', campaign_id: campaign_id, order_by: 'recipient_id'},
            {headers: {"Content-Type": "application/json"}}
        ).then(result => {
            setRecipients({loading: false, data: result.data});
        }).catch(err => {
            setRecipients({loading: false, data: null});
        });
    }

    const loadCampaignStatus = async () => {
        authorizedAxios.post(
            '/analytics_data.php',
            {action: 'campaign_status', campaign_id: campaign_id},
            {headers: {"Content-Type": "application/json"}}
        ).then(response => {
            if (response.data && response.data.timestamp) {
                setStatusData({
                    status: response.data.status,
                    timeString: getTimeString(response.data.timestamp)
                });
            }
        });
    };

    useEffect(() => {
        loadCampaignAnalytics();
        loadChartData();
        loadRecipients();
        loadPhonePreview();
        setStatusData({status: status, timeString: getCampaignTimeString()});
        // eslint-disable-next-line
    }, []);

    useRefreshData(created_at, [loadCampaignStatus, loadCampaignAnalytics, loadChartData, loadRecipients]);

    function TabsItemLink({ label, to, active }: any) {
        let match = useRouteMatch({
          path: to,
          exact: active
        });
        return <Link to={to} className={`tabs__item ${match ? "tabs__item--active" : ""}`}>{label}</Link>
    }

    return <>
        <ul className="main__header main__header--indent">
            <li className="main__header-cell">
                <Link to="/campaigns" className="button button--back" onClick={fetchData}>
                    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.246 4.25007V5.75007H3.62503L6.05728 8.18232L4.99603 9.24282L0.754028 5.00007L4.99603 0.757324L6.05728 1.81782L3.62503 4.25007H13.246Z" fill="rgba(14, 14, 14, 0.5)"/>
                    </svg>
                    <span>{i18n("button.back")}</span>
                </Link>
            </li>
            <li className="main__header-cell">
                <h2 className="main__title">
                    <div className="main__title-text">{name}</div>
                </h2>
            </li>
            <li className="main__header-cell">
                {/* {status === 1 ? <span className="status status--draft">{i18n("campaigns.status.draft)}</span>: ''}
                {status === 3 ? <span className="status status--waiting">{i18n("campaigns.status.waiting")}</span>: ''} */}
                {status === "working" ? <span className="status status--process">{i18n("campaigns.status.working")}</span>: ''}
                {status === "created" || status === "started" ? <span className="status status--process">{i18n("campaigns.status.process")}</span>: ''}
                {status === "sent" ? <span className="status status--sent">{i18n("campaigns.status.sent")}</span>: ''}
            </li>
            <li className="main__header-cell">
                <span className="main__header-gray_text">{statusData.timeString}</span>
            </li>
            {/* <li className="main__header-cell main__header-cell--left">
                <button className="button button--stop">
                    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="6" width="4" height="12" fill="#C4C4C4"/>
                        <rect width="4" height="12" fill="#C4C4C4"/>
                    </svg>
                    Остановить
                </button>
            </li> */}
        </ul>
        <div className="tabs">
            <TabsItemLink active={true} to={`/campaigns/${campaign_id}/preview`} label={i18n("campaigns.tab.preview")}/>
            <TabsItemLink to={`/campaigns/${campaign_id}/analytics`} label={i18n("campaigns.tab.analytics")}/>
            <TabsItemLink to={`/campaigns/${campaign_id}/recipients`} label={i18n("campaigns.tab.recipients")}/>
        </div>
        <Switch>
            <Route exact path={`/campaigns/${campaign_id}`}>
                <Redirect to={`/campaigns/${campaign_id}/preview`}/>
            </Route>
            <Route path={`/campaigns/${campaign_id}/preview`}>
                <PreviewTab phonePreview={phonePreview}/>
            </Route>
            <Route path={`/campaigns/${campaign_id}/analytics`}>
                <AnalyticsTab campaignId={campaign_id} campaignType={type} analyticsData={analyticsData} chartData={chartData} loadCampaignAnalytics={loadCampaignAnalytics} loadChartData={loadChartData}/>
            </Route>
            <Route path={`/campaigns/${campaign_id}/recipients`}>
                <RecipientsTab campaignId={campaign_id} recipients={recipients} loadRecipients={loadRecipients}/>
            </Route>
        </Switch>
    </>;
});

const Conversions = (props: {analyticsData: any}) => {
    const [Processed, setProcessed] = useState('-');
    const [Delivered, setDelivered] = useState('-');
    const [Read, setRead] = useState('-');
    const [ReadRate, setReadRate] = useState(' ');
    const [Replied, setReplied] = useState('-');
    const [RepliedRate, setRepliedRate] = useState(' ');

    useEffect(() => {
        if (props.analyticsData && props.analyticsData.length) {
            props.analyticsData.forEach(({param_code, value}:any) => {
                switch (param_code) {
                    case "processed":
                        setProcessed(value);
                        break;
                    case "delivered":
                        setDelivered(value);
                        break;
                    case "read":
                        setRead(value);
                        break;
                    case "read_rate":
                        setReadRate(value);
                        break;
                    case "replied":
                        setReplied(value);
                        break;
                    case "replied_rate":
                        setRepliedRate(value);
                        break;
                }
            });
        }
    }, [props.analyticsData]);

    return <div className="analytics-section">
        <p className="bold-Medium">{i18n("campaigns.analytics.conversions")}</p>
        <div className="analytics-row">
            <div className="analytics-col grids-3">
                <div className="gray-block analytics-block">
                    <div className="analytics-block__title">{i18n("campaigns.analytics.conversions.sent")}</div>
                    <span className="analytics-block__quantity">{Processed}</span>
                </div>
            </div>
            <div className="analytics-col grids-3">
                <div className="gray-block analytics-block">
                    <div className="analytics-block__title">{i18n("campaigns.analytics.conversions.delivered")}</div>
                    <span className="analytics-block__quantity">{Delivered}</span>
                </div>
            </div>
            <div className="analytics-col grids-3">
                <div className="gray-block analytics-block">
                    <div className="analytics-block__title">{i18n("campaigns.analytics.conversions.read")}</div>
                    <span className="analytics-block__quantity">{Read}</span>
                    <span className="analytics-block__interest">{ReadRate}</span>
                </div>
            </div>
            <div className="analytics-col grids-3">
                <div className="gray-block analytics-block">
                    <div className="analytics-block__title">{i18n("campaigns.analytics.conversions.replied")}</div>
                    <span className="analytics-block__quantity">{Replied}</span>
                    <span className="analytics-block__interest">{RepliedRate}</span>
                </div>
            </div>
        </div>
    </div>;
};

const PreviewTab = observer((props: {phonePreview: any}) => {
    const {selectedAccountPhoto, selectedAccountPhone} = useContext(MobXProviderContext).root.app as AppStore;
    return withConditional(
            <div className="gray-block gray-block--preview flex flex--jc-center">
                <PhonePreview
                    classes="divause-iPhone--circumcised divause-iPhone--zoom0_54"
                    headerImg={selectedAccountPhoto}
                    headerTitle={selectedAccountPhone}
                    {...props.phonePreview.data}
                />
            </div>
           , <Loading/>, () => props.phonePreview.loading === false)
});

const AnalyticsTab = (props: {campaignId: string, campaignType: string, analyticsData: any, chartData: ChartDataModel, loadCampaignAnalytics: CallableFunction, loadChartData: CallableFunction}) => {
    const docStore = useContext(MobXProviderContext).root.document as DocumentStore;

    useEffect(() => {
        props.loadCampaignAnalytics();
        props.loadChartData();
    // eslint-disable-next-line
    }, []);

    const [modal, setModal] = useState<ModalElement>(() => docStore.modals["analytics_target"]);
    function handleModalOpen() {
        if (modal) {
            modal.show();
        } else {
            if (WTRG_BUTTON.showModal) {
                const modal = WTRG_BUTTON.showModal({
                    modal: {
                        link: {phone: Config.whatsapp.numbers.ceo, text: "Начать чат"},
                        caption: "Настройте цели через аккаунт-менеджера"
                    }
                });
                docStore.modals["analytics_target"] = modal;
                setModal(modal);
            }
        }
    }

    return <>
        <Conversions analyticsData={props.analyticsData}/>

        <div className="analytics-section">
            {props.campaignType !== 'trigger' ? <AnalyticsChart chartData={props.chartData}/> : ''}
        </div>

        <div className="analytics-section">
            <p className="bold-Medium">{i18n("campaigns.analytics.goals")}</p>
            <div className="analytics-row">
                <div className="analytics-col grids-4">
                    <button className="analytics-btn" onClick={handleModalOpen}>
                        <div className="analytics-btn__block">
                            <img className="analytics-btn__img" src={Darts} alt="Darts"/>
                            <div className="analytics-btn__text">{i18n("campaigns.analytics.goals.button")}</div>
                        </div>
                    </button>
                </div>
            </div>
        </div>

        <div className="analytics-section">
            <p className="bold-Medium">{i18n("campaigns.analytics.geoanalytics")}</p>
            <img className="geoanalytics" src={Geoanalytics} alt="Geoanalytics"/>
        </div>
    </>;
};

function RecipientsTab(props: {campaignId: string, recipients: any, loadRecipients: CallableFunction}) {
    const [searchString, setSearchString] = useState<any>("");

    useEffect(() => {
        props.loadRecipients();
    // eslint-disable-next-line
    }, []);

    if (props.recipients.loading) {
        return <Loading/>;
    }

    if (!props.recipients.data) {
        return <div className="bold-Medium">{i18n("campaigns.recipients.table.empty")}</div>;
    }

    const foundRecipients = props.recipients.data.filter((recipient: any) => {
        const preparedName = recipient.name.toLowerCase();
        const preparedPhone = recipient.wa_id;
        const preparedSearch = searchString.toLowerCase();
        const preparedSearchForPhone = preparedSearch.replaceAll(/[ ()\-+]*/g, "");

        return preparedName.indexOf(preparedSearch) !== -1 || preparedPhone.indexOf(preparedSearchForPhone) !== -1;
    });

    const elements = foundRecipients.map((item:any) => <RecipientsTableElement key={item.recipient_id} data={item} campaignId={props.campaignId}/>);

    return <>
        <ul className="main__header main__header--indent">
            <li className="main__header-cell">
                <CampaignSearchField
                    debounceDelayMs={0}
                    searchString={searchString}
                    onDebounce={value => setSearchString(value)}
                />
            </li>
        </ul>
        <div className="table">
            <div className="table-row table__head">
                <div className="table-row__cell" style={{width:'30%'}}>
                    {i18n("campaigns.recipients.table.head.name")}
                </div>
                <div className="table-row__cell table-row__cell-right" style={{width:'22%'}}>{i18n("campaigns.recipients.table.head.phone")}</div>
                <div className="table-row__cell" style={{width:'11%'}}>{i18n("campaigns.recipients.table.head.status")}</div>
                <div className="table-row__cell" style={{width:'25%'}}>{i18n("campaigns.recipients.table.head.goals")}</div>
                <div className="table-row__cell"></div>
            </div>
            {(elements.length > 0) ? elements : <div className="table__status">{i18n("campaigns.table.status_search.empty")}</div>}
        </div>
    </>;
};