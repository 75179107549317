import React, {useContext, useState} from "react";
import {MobXProviderContext, observer} from "mobx-react";
import {Link, useRouteMatch} from "react-router-dom";

import {Icon} from "../../../component/icon/IconGroup";
import {DocumentStore, ItemNav} from "../../../stores/DocumentStore";
import {Config} from "../../../../config";
import {i18n} from '../../../../i18n/i18n';

export const Sidebar = observer(() => {
    const store = useContext(MobXProviderContext).root.document as DocumentStore;

    const [settingsModal, setSettingsModal] = useState<ModalElement>(() => store.modals["sidebar_settings"]);
    function handleSettingsModalOpen() {
        if (settingsModal) {
            settingsModal.show();
        } else {
            if (WTRG_BUTTON.showModal) {
                const modal = WTRG_BUTTON.showModal({
                    modal: {
                        link: {phone: Config.whatsapp.numbers.ceo, text: "Начать чат"},
                        caption: "Напишите нам в WhatsApp для изменения настроек платформы"
                    }
                });
                store.modals["sidebar_settings"] = modal;
                setSettingsModal(modal);
            }
        }
    }

    const [supportModal, setSupportModal] = useState<ModalElement>(() => store.modals["sidebar_support"]);
    function handleSupportModalOpen() {
        if (supportModal) {
            supportModal.show();
        } else {
            if (WTRG_BUTTON.showModal) {
                const modal = WTRG_BUTTON.showModal({
                    modal: {
                        link: {phone: Config.whatsapp.numbers.ceo, text: "Начать чат"},
                        caption: "Ваш аккаунт-менеджер на связи и готов помочь с любым вопросом"
                    }
                });
                store.modals["sidebar_support"] = modal;
                setSupportModal(modal);
            }
        }
    }

    const buildItems = () => {
        return store.routes.map( // Примечание: используется index, так как список статичен
            (element, index) => {
                if (!element.isDelimiter) return <OldSchoolMenuLink key={index} {...element} />;
                return <li key={index} className="sidebar__list--topline"></li>;
            })
    }

    function OldSchoolMenuLink(props: ItemNav) {
        const {caption, icon, isDisabled, isInactive} = props;
        const {path} = props.route;

        const match = useRouteMatch({path: path, exact: false});

        return <li className={match ? "active" : ""}>
            <Link
                className={isDisabled ? "sidebar__link disabled" : "sidebar__link"}
                to={path}
                onClick={(e) => {
                    if (isDisabled) {
                        e.preventDefault();
                    }

                    if (isInactive) {
                        switch (path) {
                            case '/settings': handleSettingsModalOpen(); break;
                            case '/support': handleSupportModalOpen(); break;
                        }
                        e.preventDefault();
                    }
                }}
            >
                <div className="sidebar__link-img">
                    <Icon src={icon}/>
                </div>
                <span className="sidebar__link-text">{i18n(caption)}</span>
            </Link>
        </li>;
    }

    return <div className="sidebar">
        <nav className="sidebar__content">
            <ol className="sidebar__list">
                {buildItems()}
            </ol>
        </nav>
    </div>;
});