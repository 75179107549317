import React, {Children, PropsWithChildren, useEffect, useRef, useState, ReactNode} from "react";
import {observer} from "mobx-react-lite";
import {buildClassName, varClass} from "../../../../utils/StringUtils";
import { Scrollbar } from "smooth-scrollbar-react";

export type SelectProps = {
    className?: string,
    defaultOptedIndex?: number,
    name: string,
    disabled?: boolean,
    onOpted?: (optedIndex: number) => void
}

export type SelectItemProps = {
    value?: string,
    onOpted?: () => void
}

export const SelectItem = observer((props: PropsWithChildren<SelectItemProps>) => <></>);
export const Select = observer((props: PropsWithChildren<SelectProps>) => {
    const {children, disabled, defaultOptedIndex = 0, onOpted, name, className} = props;
    let defaultOpted = defaultOptedIndex;
    const [isExpanded, setExpanded] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let isTracked = false;
        if (isExpanded && !isTracked) {
            isTracked = true;
            document.addEventListener('mousedown', handleMissClick);
        }
        return () => {
            if (isTracked) {
                isTracked = false;
                document.removeEventListener('mousedown', handleMissClick);
            }
        }
    // eslint-disable-next-line
    }, [isExpanded]);

    const handleMissClick = (event: MouseEvent) => {
        if (event?.composedPath) {
            let outbound = true;
            for (let element of event.composedPath()) {
                if (element === ref.current) {
                    outbound = false;
                    break;
                }
            }

            if (isExpanded && outbound) {
                setExpanded(false);
            }
        }
    }

    const handleItemClick = (event: React.MouseEvent, clickedIndex: number) => {
        if (event.button === 0) defaultOpted = clickedIndex;
        if (onOpted) onOpted(clickedIndex);
    }

    const handleSelectClick = (event: React.MouseEvent) => {
        if ((event.target as HTMLInputElement).className === 'scrollbar-thumb scrollbar-thumb-y') return;
        if (!disabled && event.button === 0) setExpanded(!isExpanded);
    }

    let optedValue: string = "";
    let optedChildren: JSX.Element = <></>;
    const indexedChildren = Children.map<{index: number, child: ReactNode}, ReactNode>(children, (child, index) => ({index, child}));
    if (indexedChildren) {
        const deferredChildren = indexedChildren
            .sort((up, down) => {
                if (up.index === defaultOpted) return -1;
                else if (down.index === defaultOpted) return 1;

                return 0;
            })
            .map(({index, child}) => {
                const {children, value, onOpted} = (child as JSX.Element).props;
                const opted = (index === defaultOpted);
                const itemClasses = buildClassName(varClass(opted, "form__select-selected"));
                if (opted) {
                    optedValue = value;
                    optedChildren = children;
                }
                return <li key={index} data-option data-value={value} className={itemClasses} onMouseDown={e => {
                    handleItemClick(e, index);
                    if (onOpted) onOpted();
                }}>
                    <div>{children}</div>
                </li>
            });

        const rootClass = buildClassName("form__select", varClass(!!className, className), varClass(isExpanded, "form__select--active"), varClass(disabled, "form__select--disabled"));
        return <div ref={ref} className={rootClass} onMouseDown={handleSelectClick}>
            <span className="form__select-placeholder">{optedChildren}</span>
            <div className="form__select-options">
                <Scrollbar alwaysShowTracks className="form__select-scroll" continuousScrolling={false}>
                    <div>
                        <ul>{deferredChildren}</ul>
                    </div>
                </Scrollbar>
            </div>
            <input type="hidden" disabled={disabled} name={name} value={optedValue}/>
        </div>;
    }

    return <></>;
});