import React, {useState, useEffect, useContext} from "react";
import authorizedAxios from '../../http/authorizedAxios';
import {observer} from "mobx-react-lite";
import {MobXProviderContext} from "mobx-react";
import {DocumentStore} from "../../stores/DocumentStore";
import {Config} from "../../../config";
import {i18n} from "../../../i18n/i18n";

type AccountProps = {
    balance?: number;
}

export const Account = observer((props: AccountProps) => {

    const docStore = useContext(MobXProviderContext).root.document as DocumentStore;

    const [dateSubscriptions, setDateSubscriptions] = useState({
        date: 0
    });

    useEffect(() => {
        const fetchData = async () => {
            await authorizedAxios.get(
                '/subscriptions.php'
            ).then(result => {
                if (result.data) {
                    setDateSubscriptions({date: result.data.expires_at})
                }
            }).catch(err => {
                console.log(err);
            });
        };
        fetchData();
    }, []);

    const getTimeString = (timestamp: number): string => {
        const dateObject: Date = new Date(timestamp * 1000);
        const date: string = dateObject.toLocaleString(i18n("time.lang"), {day:"numeric", month:"long", year: 'numeric'});
        return `${i18n("account.until")} ${date}`;
    };

    const [modal, setModal] = useState<ModalElement>(() => docStore.modals["account_renew"]);
    function handleModalOpen() {
        if (modal) {
            modal.show();
        } else {
            if (WTRG_BUTTON.showModal) {
                const modal = WTRG_BUTTON.showModal({
                    modal: {
                        link: {phone: Config.whatsapp.numbers.ceo, text: "Начать чат"},
                        caption: "Обратитесь к менеджеру для продления аккаунта",
                    }
                });
                docStore.modals["account_renew"] = modal;
                setModal(modal);
            }
        }
    }

    function colorDate(subDate: any) {
        const currentDate = Math.floor(Date.now() / 1000);
        if (currentDate <= subDate) {
            return ' date-subscription--light_gray';
        } else {
            return ' date-subscription--red';
        }
    }

    const subscriptionDate = dateSubscriptions.date ? getTimeString(dateSubscriptions.date) : <></>;

    return <>
        <li className="header__nav-item">
            <span className={`date-subscription${colorDate(dateSubscriptions.date)}`}>
                {subscriptionDate}
            </span>
            {/* <span className="rub">
                {balance + ' '}
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.97959 7.61932C9.65005 7.61932 11.1785 6.14773 11.1785 4.01705C11.1785 1.96023 9.68414 0.363636 6.97959 0.363636H2.59323V5.88636H0.883001V7.61932H2.59323V8.48295H0.883001V10.2159H2.59323V12H4.6955V10.2159H7.24664V8.48295H4.6955V7.61932H6.97959ZM4.6955 5.88636V2.11364H6.97959C8.39437 2.11364 9.07618 2.94318 9.0705 4.03409C9.07618 5.14773 8.40573 5.88636 6.93414 5.88636H4.6955Z" fill="#F6522E"/>
                </svg>
            </span> */}
        </li>
        <li className="header__nav-item">
            <button className="button button--border" onClick={handleModalOpen}>{i18n("button.extend")}</button>
        </li>
    </>;
});

