import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AuthResponse } from '../stores/types/AuthDataBackend';
import { BASE_URL } from './config';

const authorizedAxios = axios.create({
    baseURL: BASE_URL
});

authorizedAxios.interceptors.request.use((config: AxiosRequestConfig) => {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});

authorizedAxios.interceptors.response.use((config: AxiosResponse) => {
    return config;
}, async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const refreshToken = localStorage.getItem('refresh_token');
            if (refreshToken) {
                const response = await axios.post<AuthResponse>(
                    `${BASE_URL}/auth/refresh_tokens.php`,
                    JSON.stringify({refresh_token: refreshToken}),
                    {headers: {"Content-Type": "application/json"}}
                );
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('refresh_token', response.data.refresh_token);
                return authorizedAxios.request(originalRequest);
            }
            window.location.href = '/login';
        } catch (e) {
            window.location.href = '/login';
        }
    }
    throw error;
});

export default authorizedAxios;