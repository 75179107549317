import {observer} from "mobx-react-lite";

import React, {FunctionComponent, useRef, useState} from "react";
import {MiB} from "../../../utils/Unit";
import {mime2ext} from "../../../utils/MimeHelper";
import {i18n} from "../../../i18n/i18n";

type Props = {
    disabled?: boolean,
    initial?: string,
    size?: number // Bytes
    types?: Array<string>,
    onChange?: (event: React.ChangeEvent, props: {file?: File, isAccepted: boolean}) => void,
}

export const FileLoad: FunctionComponent<Props> = observer((props) => {
    const {initial, size = 2*MiB, types, disabled = false, onChange} = props;
    const [file, load] = useState<File>();
    const fileRef = useRef<HTMLInputElement>(null);

    function acceptFile(file?: File): boolean {
        return (file && file.size <= size && types?.some((type) => "." + mime2ext(file.type) === type)) ?? false;
    }

    function handleFileLoad(event: React.ChangeEvent<HTMLInputElement>): void {
        let file: File|undefined;
        if (event.target.files?.length === 1) {
            file = event.target.files[0];
            load(file);
        }

        if (onChange) onChange(event, {
            file: file,
            isAccepted: acceptFile(file),
        });
    }

    function buildInfo(): string {
        const build = (info: string) => `${info ? info + ", " : `${i18n("templates.amotemplates.form.file.any")} `}${i18n("templates.amotemplates.form.file.up_to")} ${size/MiB}${i18n("unit.mb")}`;

        if (types) {
            if (types.length > 1) {
                const lastType = types[types.length-1];
                const stringifiedTypes = types.slice(0, -1).join(', ');

                return build(stringifiedTypes + ` ${i18n("templates.amotemplates.form.file.or")} ` + lastType);
            } else if (types.length === 1) {
                return build(types[0]);
            }
        }

        return build("");
    }

    const initialName = (initial) ? initial?.substr(initial.lastIndexOf("/")+1) : undefined;
    return <div className="form__profile-file">
        <label className="button button--border">
            {(file || initialName) ? i18n("button.change") : i18n("button.upload")}
            <input disabled={disabled} ref={fileRef} type="file" accept={types?.join(', ')} onChange={handleFileLoad}/>
        </label>
        <div className="form__profile-file__info form__profile-file__info--download">
            {(file || initialName) ? file?.name ?? (file || initialName) : buildInfo()}
        </div>
    </div>;
});