import {Store, VerifiableStore} from "../../core/Store";
import {DetailVerifiable} from "../../core/Validation";
import {i18n} from "../../../../i18n/i18n";
import {BODY_MESSAGE_MAX_LENGTH, HEADER_MESSAGE_MAX_LENGTH} from "../../../../utils/FacebookAPI";
import {Pretemplate} from "../../types/PretemplateDataModel";
import {countConcatedDigits} from "../../../../utils/StringUtils";
import {ModelItem} from "../../types/AmotemplateDataModel";

export namespace Amotemplates {
    export type FormData = {
        name: string,
        pretemplate_code?: string,
        template_id?: number,
        isActive?: number,
        sort?: number,
        header?: {
            content?: string, // Когда используется маска в текстовом заголовке или прямая ссылка на контент
            media?: File, // Когда амошаблон создан\отредактирован в админке
        },
        body?: string[],
        buttonUrl?: string,
        created_at?: number,
    }

    export type State = {
        processing: "idle"|"processing"|"processing-failed",
        mode: "idle"|"modifying"|"making",
        isModified: boolean,
    }

    export class FormState extends Store<State> {
        protected makeEmpty(): State {
            return {
                processing: "idle",
                mode: "idle",
                isModified: false,
            };
        }
    }

    export class FormStore extends VerifiableStore<FormData> {
        private pretemplates: Map<string, Pretemplate>;

        constructor(pretemplates: Map<string, Pretemplate>) {
            super();

            this.pretemplates = pretemplates;
        }

        protected makeEmpty(): FormData {
            return {
                name: "",
                isActive: 1,
            };
        }

        reflectModel(model: ModelItem) {
            this.data = {
                name: model.name,
                template_id: model.template_id,
                pretemplate_code: model.code,
                sort: model.sort,
                isActive: model.isActive,
                header: {
                    content: model.payload?.header,
                },
                body: (model.payload?.body) ? [...model.payload?.body] : undefined,
                buttonUrl: model.payload?.buttonUrl,
                created_at: model.created_at,
            };
        }

        makeModel(): ModelItem {
            return {
                name: this.data.name,
                code: this.data.pretemplate_code ?? "",
                isActive: this.data.isActive ?? 0,
                template_id: this.data.template_id ?? 0,
                sort: this.data.sort ?? 1,
                payload: {
                    media: this.data.header?.media,
                    header: this.data.header?.content,
                    body: (this.data.body) ? [...this.data.body] : undefined,
                    buttonUrl: this.data.buttonUrl,
                },
                created_at: this.data.created_at ?? Date.now(),
                updated_at: Date.now(),
            }
        }

        get validationBlacklist(): (keyof FormData)[] {
            return [];
        }

        get validationSchema(): DetailVerifiable.Schema<FormData> {
            return {
                name: [
                    [value => !value.length, i18n("templates.amotemplates.error.empty_name")],
                ],
                header: [
                    [value => {
                        const pretemplate = this.pretemplates.get(this.data.pretemplate_code ?? "");
                        const text = pretemplate?.payload.template.header?.payload;

                        if (text && text.length > 0) {
                            const textLenWithoutMarker = (text.length - 5);
                            const summaryLength = textLenWithoutMarker + (value?.content?.length ?? 0);

                            return summaryLength > HEADER_MESSAGE_MAX_LENGTH;
                        }

                        return false;
                    }, i18n("templates.amotemplates.error.header.too_long")],
                    [value => !!value?.content?.match(/\s{4,}/), i18n("templates.amotemplates.error.whitespace_sequence")],
                ],
                body: [
                    [value => {
                        // Вычисляем общую длину подстановок(текста, замещающего маркеры)
                        const contentLen = value
                            ?.map(value => value.length)
                            .reduce((prev, curr) => prev + curr) ?? 0;

                        // Вычисляем длину строки "{{1}}{{2}}{{3}} ... {{<markers>}}"
                        // 4 - это постоянный член, т.е. длина строки "{{}}"
                        const markers = this.data.body?.length ?? 0;
                        const markersLen = 4*markers + countConcatedDigits(markers);

                        // Вычисляем длину текста прешаблона за вычетом длины текста маркеров с учетом длины подстановок
                        const pretemplate = this.pretemplates.get(this.data.pretemplate_code ?? "");
                        const text = pretemplate?.payload.template.body;
                        if (text && text.length > 0) {
                            const textLenWithoutMarkers = (text.length - markersLen);
                            const summaryLength = textLenWithoutMarkers + contentLen;

                            return summaryLength > BODY_MESSAGE_MAX_LENGTH;
                        }

                        return false;
                    }, i18n("templates.amotemplates.error.body.too_long")],
                    [value => !!value?.some(str => !!str.match(/\s{4,}/)), i18n("templates.amotemplates.error.whitespace_sequence")],
                ],
                buttonUrl: [
                    [value => {
                        if (value) {
                            const pretemplate = this.pretemplates.get(this.data.pretemplate_code ?? "");
                            const text = pretemplate?.payload.template.buttons
                                ?.find(button => button.type === "url")
                                ?.payload;

                            if (text && text.length > 0) {
                                const compiledValue = text.replace("{{1}}", value);
                                return /^(http|https):\/\/([a-zа-я\d._-]{2,256})\.([a-zа-я]{2,63})($|(\/|\?)[a-zа-яA-ZА-Я\d@:%-_+.~#?&/="'{}]*$)/gm.test(compiledValue) === false;
                            }
                        }

                        return false;
                    }, i18n("templates.amotemplates.error.incorrect_url")],
                    [value => !!value?.match(/\s{4,}/), i18n("templates.amotemplates.error.whitespace_sequence")],
                ],
            };
        }
    }
}