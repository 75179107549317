import React, {useState} from "react";
import {i18n} from "../../../../i18n/i18n";
export type SearchFieldProps = {
    searchString?: string,
    onChange?: (value: string) => void,
}

export const SearchField = ((props: SearchFieldProps) => {
    const {searchString = "", onChange} = props;

    const [value, setValue] = useState(searchString);

    function handleChange(newValue: string) {
        setValue(newValue);
        if (onChange) onChange(newValue);
    }

    return <div className="list__search">
        <input
            className="list__search-input"
            type="search"
            placeholder={i18n("search.placeholder")}
            value={value}
            onChange={e => handleChange(e.target.value)}
        />
    </div>;
});