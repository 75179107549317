import {
    BODY_MESSAGE_MAX_LENGTH,
    BUTTON_MESSAGE_MAX_LENGTH,
    FOOTER_MESSAGE_MAX_LENGTH,
    HEADER_MESSAGE_MAX_LENGTH,
    TEMPLATE_NAME_MAX_LENGTH,
    NUMBERS_EMAIL_MAX_LENGTH,
    NUMBERS_SHORT_DESCRIPTION_MAX_LENGTH,
    NUMBERS_EXTENDED_DESCRIPTION_MAX_LENGTH,
    NUMBERS_ADDRESS_MAX_LENGTH,
    NUMBERS_WEBSITE_MAX_LENGTH
} from "../../utils/FacebookAPI";

const en = {
    "time.lang": "en-US",

    "unit.mb": "MB",

    "title.authorization": "Authorization",
    "title.campaigns": "Campaigns",
    "title.templates": "Templates",
    "title.numbers": "WhatsApp Numbers",

    "sidebar.campaigns": "Campaigns",
    "sidebar.templates": "Templates",
    "sidebar.numbers": "Numbers",
    "sidebar.integrations": "Integrations",
    "sidebar.qr_pages": "QR-Pages",
    "sidebar.button": "Button",
    "sidebar.settings": "Settings",
    "sidebar.support": "Support",

    "button.enter": "Enter",
    "button.extend": "Extend",
    "button.create": "Create",
    "button.back": "Back",
    "button.send": "Send",
    "button.save": "Save",
    "button.cancel": "Cancel",
    "button.remove": "Remove",
    "button.upload": "Upload",
    "button.change": "Change",

    "search.placeholder": "Search",

    "account.until": "Until",

    "authorization.tab.via_whatsapp": "Via WhatsApp",
    "authorization.tab.by_password": "By password",
    "authorization.form.login.label": "Login",
    "authorization.form.password.label": "Password",
    "authorization.form.error.invalid_password": "Invalid password",
    "authorization.button.forgot_password": "Forgot your password?",

    "campaigns.table.head.date": "Date",
    "campaigns.table.head.recipients": "Recipients",
    "campaigns.table.head.status": "Status",
    "campaigns.table.head.read": "Read",
    "campaigns.table.head.replied": "Answered",
    "campaigns.table.status_search.empty": "Nothing found",
    "campaigns.table.button.open": "Open",
    "campaigns.status.sent": "Sent",
    "campaigns.status.started": "Started",
    "campaigns.status.changed": "Changed",
    "campaigns.status.draft": "Draft",
    "campaigns.status.waiting": "Waiting",
    "campaigns.status.process": "In process",
    "campaigns.status.working": "Working",
    "campaigns.time.in": " ",
    "campaigns.time.at": " at ",
    "campaigns.empty.title": "You haven't launched any newsletters yet:(",
    "campaigns.empty.subtitle": "You can do bulk template submissions via lists in amoCRM",
    "campaigns.empty.button": "Go over",
    "campaigns.tab.preview": "Preview",
    "campaigns.tab.analytics": "Analytics",
    "campaigns.tab.recipients": "Recipients",
    "campaigns.analytics.conversions": "Conversions",
    "campaigns.analytics.conversions.sent": "Sent",
    "campaigns.analytics.conversions.delivered": "Delivered",
    "campaigns.analytics.conversions.read": "Read",
    "campaigns.analytics.conversions.replied": "Answered",
    "campaigns.analytics.chart.title": "Reaction ⏱ 24 hours",
    "campaigns.analytics.chart.status.read": "Read",
    "campaigns.analytics.chart.status.replied": "Answered",
    "campaigns.analytics.goals": "Goals",
    "campaigns.analytics.goals.button": "Add a new goal",
    "campaigns.analytics.geoanalytics": "Geoanalytics",
    "campaigns.recipients.table.head.name": "Name",
    "campaigns.recipients.table.head.phone": "Phone",
    "campaigns.recipients.table.head.status": "Status",
    "campaigns.recipients.table.head.goals": "Goals",
    "campaigns.recipients.table.empty": "There are no recipients",
    "campaigns.recipients.status.sent": "Sent",
    "campaigns.recipients.status.error": "Mistake",
    "campaigns.recipients.status.delivered": "Delivered",
    "campaigns.recipients.status.read": "Read",
    "campaigns.recipients.status.replied": "Answered",
    "campaigns.recipients.goals.no_goals": "No goals",

    "templates.tab.pre_templates": "Pre-templates",
    "templates.tab.amocrm_templates": "Templates amoCRM",
    "templates.button.add_template": "Add a template",
    "templates.pretemplates.form.name.label": "Title",
    "templates.pretemplates.form.template_code.label": "Template сode",
    "templates.pretemplates.form.category.label": "Category",
    "templates.pretemplates.form.language.label": "Language",
    "templates.pretemplates.form.status.label": "Status",
    "templates.pretemplates.form.header.label": "Cap",
    "templates.pretemplates.form.message.label": "Message",
    "templates.pretemplates.form.signature.label": "Signature",
    "templates.pretemplates.form.structure.label": "Structure",
    "templates.pretemplates.form.example": "Example of filling",
    "templates.pretemplates.form.button.label": "button",
    "templates.pretemplates.form.error.emojis_not_allowed": "Signature cannot contain emoji",
    "templates.pretemplates.form.error.empty_name": "The template name is missing",
    "templates.pretemplates.form.error.same_code": "This code has already been registered",
    "templates.pretemplates.form.error.template_code_is_missing": "Template code is missing",
    "templates.pretemplates.form.error.template_code.too_long": `Maximum length: ${TEMPLATE_NAME_MAX_LENGTH} characters`,
    "templates.pretemplates.form.error.invalid_char": "Invalid character used",
    "templates.pretemplates.form.error.header.empty": "The title text is missing",
    "templates.pretemplates.form.error.header.too_long": `Maximum length: ${HEADER_MESSAGE_MAX_LENGTH} characters`,
    "templates.pretemplates.form.error.body.empty": "The text of the message is missing",
    "templates.pretemplates.form.error.body.too_long": `Maximum length: ${BODY_MESSAGE_MAX_LENGTH} characters`,
    "templates.pretemplates.form.error.invalid_sequence": "Incorrect order of parameters",
    "templates.pretemplates.form.error.footer.too_long": `Maximum length: ${FOOTER_MESSAGE_MAX_LENGTH} characters`,
    "templates.pretemplates.form.error.button.empty": "The button name is missing",
    "templates.pretemplates.form.error.button.too_long": `Maximum length: ${BUTTON_MESSAGE_MAX_LENGTH} characters`,
    "templates.pretemplates.form.error.incorrect_phone": "Invalid number",
    "templates.pretemplates.form.error.incorrect_url": "Invalid URL",
    "templates.pretemplates.add_template.form.subtext.before": "The templates undergo mandatory moderation on",
    "templates.pretemplates.add_template.form.subtext.after": "Facebook lasting from 1 hour to 2 days.",
    "templates.pretemplates.form.buttons.label": "Buttons",
    "templates.pretemplates.form.buttons.name_button.placeholder": "Enter the name of the button",
    "templates.pretemplates.form.buttons.url.placeholder": "Enter the link",
    "templates.pretemplates.form.buttons.phone.placeholder": "Enter your phone number",
    "templates.pretemplates.form.buttons.type.quick_reply": "Answer",
    "templates.pretemplates.form.buttons.type.url": "Link",
    "templates.pretemplates.form.buttons.type.phone_number": "Phone",
    "templates.pretemplates.textarea.marker": "Parameter",
    "templates.amotemplates.empty.title": "There's nothing here",
    "templates.amotemplates.empty.subtitle": "To manage amoCRM templates, first enable integration",
    "templates.amotemplates.empty.button": "Customize",
    "templates.amotemplates.form.name.label": "Title",
    "templates.amotemplates.form.pre_template.label": "Pre-template",
    "templates.amotemplates.form.visibility.label": "Visibility",
    "templates.amotemplates.form.visibility.visible": "Manually and Salesbot",
    "templates.amotemplates.form.visibility.visible_dp": "Only Salesbot",
    "templates.amotemplates.form.file.any": "any",
    "templates.amotemplates.form.file.up_to": "up to",
    "templates.amotemplates.form.file.or": "or",
    "templates.amotemplates.form.header.label": "Cap",
    "templates.amotemplates.form.image.label": "Image",
    "templates.amotemplates.form.document.label": "Document",
    "templates.amotemplates.form.video.label": "Video",
    "templates.amotemplates.form.message.label": "Message",
    "templates.amotemplates.form.button.label": "Button",
    "templates.amotemplates.error.empty_name": "The template name is missing",
    "templates.amotemplates.error.header.too_long": `Maximum length: ${HEADER_MESSAGE_MAX_LENGTH} characters`,
    "templates.amotemplates.error.body.too_long": `Maximum length: ${BODY_MESSAGE_MAX_LENGTH} characters`,
    "templates.amotemplates.error.whitespace_sequence": "No more than 4 spaces in a row",
    "templates.amotemplates.error.incorrect_url": "Invalid URL",

    "numbers.button.add_number": "Add a number",
    "numbers.form.profile_photo.label": "Profile photo",
    "numbers.form.short_description.label": "Short description",
    "numbers.form.short_description.placeholder": "Enter a description",
    "numbers.form.extended_description.label": "Extended Description",
    "numbers.form.extended_description.placeholder": "Enter the description text",
    "numbers.form.address.label": "Address",
    "numbers.form.address.placeholder": "Enter the address",
    "numbers.form.type_of_activity.label": "Type of activity",
    "numbers.form.website.label": "Website",
    "numbers.form.website.placeholder": "Enter the website",
    "numbers.form.email.label": "Email",
    "numbers.form.email.placeholder": "Enter email",
    "numbers.form.error.photo_size": "The file size exceeds the limit of 5 MB",
    "numbers.form.error.photo_type": "The file type is not suitable, only jpg or png",
    "numbers.form.error.photo_status": "Unable to process profile photo",
    "numbers.form.error.short_description.should_not_be_empty": "A short description cannot be empty",
    "numbers.form.error.short_description.too_long": `Maximum length: ${NUMBERS_SHORT_DESCRIPTION_MAX_LENGTH} characters`,
    "numbers.form.error.extended_description.too_long": `Maximum length: ${NUMBERS_EXTENDED_DESCRIPTION_MAX_LENGTH} characters`,
    "numbers.form.error.address.too_long": `Maximum length: ${NUMBERS_ADDRESS_MAX_LENGTH} characters`,
    "numbers.form.error.website.incorrect_url": "The website must be an http or https URL.",
    "numbers.form.error.website.too_long": `Maximum length: ${NUMBERS_WEBSITE_MAX_LENGTH} characters`,
    "numbers.form.error.email.incorrect_format": "Incorrect format",
    "numbers.form.error.email.first_character": "The first character must be a Latin letter (a-z) or a digit (0-9)",
    "numbers.form.error.email.consecutive_signs": "Mail cannot contain consecutive underscores, periods, and minus signs.",
    "numbers.form.error.email.too_long": `Maximum length: ${NUMBERS_EMAIL_MAX_LENGTH} characters`,

    "phone_preview.preview": "Preview",
    "phone_preview.today": "Today",
    "phone_preview.notification.before": "Messages and calls in this chat are now protected by end-to-end encryption.",
    "phone_preview.notification.after": "may use a third-party service to store and read your messages and answer messages and calls. In more detail.",
    "phone_preview.information": "This correspondence is conducted with a business account. In more detail.",

    "whatsapp.number_category.automotive": "Car Dealership",
    "whatsapp.number_category.beauty_spa_and_salon": "Beauty, SPA and salon",
    "whatsapp.number_category.clothes": "Clothes",
    "whatsapp.number_category.education": "Education",
    "whatsapp.number_category.entertainment": "Entertainment",
    "whatsapp.number_category.event_planning_and_service": "Event planning and maintenance",
    "whatsapp.number_category.finance_and_banking": "Finance and banking",
    "whatsapp.number_category.food_and_grocery": "Food and groceries",
    "whatsapp.number_category.public_service": "Public service",
    "whatsapp.number_category.hotel_and_lodging": "Hotel and accommodation",
    "whatsapp.number_category.medical_and_health": "Medicine and Health",
    "whatsapp.number_category.non_profit": "Non-commercial",
    "whatsapp.number_category.professional_services": "Professional services",
    "whatsapp.number_category.shopping_and_retail": "Shopping and retail",
    "whatsapp.number_category.travel_and_transportation": "Travel and transport",
    "whatsapp.number_category.restaurant": "Restaurant",
    "whatsapp.number_category.other": "Other",
    "whatsapp.notification_category.account_update": "Account update",
    "whatsapp.notification_category.alert_update": "Alert update",
    "whatsapp.notification_category.appointment_update": "Appointment update",
    "whatsapp.notification_category.auto_reply": "Auto reply",
    "whatsapp.notification_category.issue_resolution": "Issue resolution",
    "whatsapp.notification_category.payment_update": "Payment update",
    "whatsapp.notification_category.personal_finance_update": "Personal finance_update",
    "whatsapp.notification_category.shipping_update": "Shipping update",
    "whatsapp.notification_category.reservation_update": "Reservation update",
    "whatsapp.notification_category.ticket_update": "Ticket update",
    "whatsapp.notification_category.transportation_update": "Transportation update",
    "whatsapp.notification_category.transactional": "Transactional",
    "whatsapp.notification_category.marketing": "Marketing",
    "whatsapp.notification_category.authentication": "Authentication",
    "whatsapp.notification_category.utility": "Utility",
    "whatsapp.notification_category.otp": "Password",
    "whatsapp.template.status.pending": "Pending",
    "whatsapp.template.status.approved": "Approved",
    "whatsapp.template.status.rejected": "Rejected",
    "whatsapp.template.status.flagged": "Flagged",
    "whatsapp.template.status.disabled": "Disabled",
    "whatsapp.template.header_type.text": "Text",
    "whatsapp.template.header_type.image": "Image",
    "whatsapp.template.header_type.video": "Video",
    "whatsapp.template.header_type.document": "Document",
    "whatsapp.template.header_type.none": "None",
    "whatsapp.template.header_type.sticker": "Sticker",
    "whatsapp.template.header_type.audio": "Audio",
    "whatsapp.language.ru_RU": "RU",
    "whatsapp.language.en_US": "EN (US)",
    "whatsapp.language.de_DE": "DE",
    "whatsapp.language.fr_FR": "FR",
    "whatsapp.language.pt_PT": "PT",
    "whatsapp.language.es_ES": "ES",
    "whatsapp.language.it_IT": "IT",
    "whatsapp.language.id_ID": "ID",
    "whatsapp.language.fil_FIL": "FIL",
    "whatsapp.language.hi_HI": "HI",
    "whatsapp.language.kk_KK": "KK"
}

export default en;