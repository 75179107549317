import {useState} from "react";
import {useFlipFlopRef} from "./FlipFlopHook";

export function useStore<T>(tuner: (...args: any[]) => T, ...args: any[]): [T, number, (...args: any[]) => T] {
    const [difference, toggle] = useFlipFlopRef();
    const [store, setStore] = useState<T>(() => tuner(...args));

    function refresh(...args: any[]): T {
        const actualValue = tuner(...args);

        toggle();
        setStore(actualValue);

        return actualValue;
    }

    return [store, (difference) ? 0 : 1, refresh];
}