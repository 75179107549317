import {observer} from "mobx-react-lite";
import React, {useContext, useEffect, useState} from "react";
import {MobXProviderContext} from "mobx-react";
import {ModelItem} from "../../../stores/types/AmotemplateDataModel";
import {Input} from "../local_component/Input";
import {Select, SelectItemProps} from "../local_component/Select";
import {
    DOCUMENT_MAX_SIZE,
    IMAGE_MAX_SIZE,
    VIDEO_MAX_SIZE
} from "../../../../utils/FacebookAPI";
import {Amotemplates} from "../../../stores/form/amotemplate/AmotemplateFormStore";
import {FileLoad} from "../../../component/file_load/FileLoad";
import {AmotemplateModel} from "../../../stores/AmotemplatesModel";
import {buildClassName, varClass} from "../../../../utils/StringUtils";
import {PretemplateModel} from "../../../stores/PretemplatesModel";
import {i18n} from "../../../../i18n/i18n";
import {PhonePreview} from "../../../component/phone_preview/PhonePreview";
import { AppStore } from '../../../stores/AppStore';

export type AmotemplateFormProps = {
    formStore: Amotemplates.FormStore,
    formState: Amotemplates.FormState,
    initialPtCode: string,
    onSave?: (amotemplate: ModelItem) => void,
    onCancel?: () => void,
    onDelete?: () => void,
    editable?: boolean,
    PhonePreviewState: string,
    setPhonePreviewState?: (s: string) => void,
}

export const AmotemplateForm = ((props: AmotemplateFormProps) => {
    const {formStore, formState, editable = true, initialPtCode, onSave, onCancel, onDelete} = props;
    const model = useContext(MobXProviderContext).root.amotemplates as AmotemplateModel;
    const modelPt = useContext(MobXProviderContext).root.pretemplates as PretemplateModel;
    const appStore = useContext(MobXProviderContext).root.app as AppStore;

    const [, setValidation] = useState(formStore.validationResult);
    const [optedPt, setOptedPt] = useState<{index: number, code: string}>(() => {
        const index = Array.from(model.approvedPretemplates.keys()).findIndex(approvedPtCode => approvedPtCode === initialPtCode);
        return {index: index, code: initialPtCode};
    });
    const [loadingPt, setLoadingPt] = useState(false);

    function markDirty() {
        if (!formState.data.isModified) {
            if (formState.data.mode === "idle") {
                formState.storeData({mode: "modifying", isModified: true});
            } else if (formState.data.mode === "making") {
                formState.storeData({isModified: true});
            }
        }
    }

    function makePretemplateItems(): SelectItemProps[] {
        return Array.from(model.approvedPretemplates.values()).map(({code, name}, index) => ({
            id: code,
            jsx: <>{name}</>,
            onOpted: () => {
                setLoadingPt(true);
                modelPt.downloadItem(code)
                    .then(successfully => {
                        setOptedPt({index: index, code: code});

                        const name = formStore.data.name;
                        const visibility = formStore.data.isActive;

                        formStore.clearStore();
                        formStore.replaceData({
                            pretemplate_code: code,
                            name: name,
                            isActive: visibility,
                        });

                        markDirty();
                    })
                    .finally(() => {
                        setLoadingPt(false);
                    })
            }
        }));
    }

    const OptionsBlock = observer(() => {
        return <>
            <div className="form__block">
                <label className="form__label" htmlFor="form-amotemplate-name">{i18n("templates.amotemplates.form.name.label")}</label>
                <Input id="form-amotemplate-name"
                       disabled={formState.data.processing !== "idle" || !editable}
                       value={formStore.data.name}
                       onChange={value => {
                           formStore.replaceData({name: value});
                           markDirty();
                       }}
                       coldVerified={formStore.validationResult[1].name}
                />
            </div>
            <div className="form__block">
                <label className="form__label">{i18n("templates.amotemplates.form.pre_template.label")}</label>
                <Select
                    name="form-amotemplate-pretemplate-select"
                    disabled={formState.data.mode !== "making" || !editable}
                    defaultOptedId={optedPt.code}
                    items={makePretemplateItems()}
                />
            </div>
            <div className="form__block">
                <label className="form__label">{i18n("templates.amotemplates.form.visibility.label")}</label>
                <Select name="form-amotemplate-visibility-select"
                        disabled={formState.data.processing !== "idle" || !editable}
                        defaultOptedId={(formStore.data.isActive === 1) ? 'visible' : 'visible_dp'}
                        onOpted={optedId => {
                            formStore.replaceData({isActive: (optedId === 'visible') ? 1 : 2});
                            markDirty();
                        }}
                        items={[
                            {id: 'visible', jsx: <>{i18n("templates.amotemplates.form.visibility.visible")}</>},
                            {id: 'visible_dp', jsx: <>{i18n("templates.amotemplates.form.visibility.visible_dp")}</>}
                        ]}
                />
            </div>
        </>;
    });

    const HeaderBlock = (() => {
        const handleChange = (isAccepted: boolean, file?: File): void => {
            if (isAccepted) {
                // media - это объект файла загруженного пользователем, хранится только локально
                // пока не будет выгружен в облако, тогда уже ссылка на него будет лежать в поле content
                formStore.storeData({header: {media: file}});
            }
            markDirty();
        }

        const Text = (() => {
            useEffect(() => {
                if (typeof formStore.data.header?.content !== "string") {
                    formStore.storeData({header: {content: ""}});
                }
            }, []);

            return <div className="form__block">
                <label className="form__label"
                       htmlFor="form-amotemplate-header">{i18n("templates.amotemplates.form.header.label")}: &#123;&#123;1&#125;&#125;</label>
                <Input id="form-amotemplate-header"
                       disabled={formState.data.processing !== "idle" || !editable}
                       value={formStore.data.header?.content}
                       onChange={value => {
                           if (typeof formStore.data.header?.content === "string") {
                               formStore.storeData({header: {content: value}});
                           }
                           markDirty();
                       }}
                       hotVerifier={() => formStore.validateField("header")}
                       coldVerified={formStore.validationResult[1].header}
                       data-autoresize
                />
            </div>;
        });

        const Image = ((props: {content?: string}) => {
            useEffect(() => {
                if (typeof formStore.data.header?.content === "undefined") {
                    formStore.storeData({header: {content: ""}});
                }
            }, []);

            return <div className="form__block">
                <label className="form__label">{i18n("templates.amotemplates.form.image.label")}</label>
                <FileLoad types={[".png", ".jpg", ".jpeg"]}
                          size={IMAGE_MAX_SIZE}
                          disabled={formState.data.processing !== "idle" || !editable}
                          initial={props.content}
                          onChange={(e, {file, isAccepted}) => handleChange(isAccepted, file)}
                />
            </div>;
        });

        const Document = ((props: {content?: string}) => {
            useEffect(() => {
                if (typeof formStore.data.header?.content === "undefined") {
                    formStore.storeData({header: {content: ""}});
                }
            }, []);

            return <div className="form__block">
                <label className="form__label">{i18n("templates.amotemplates.form.document.label")}</label>
                <FileLoad types={[".pdf"]}
                          size={DOCUMENT_MAX_SIZE}
                          disabled={formState.data.processing !== "idle" || !editable}
                          initial={props.content}
                          onChange={(e, {file, isAccepted}) => handleChange(isAccepted, file)}
                />
            </div>;
        });

        const Video = ((props: {content?: string}) => {
            useEffect(() => {
                if (typeof formStore.data.header?.content === "undefined") {
                    formStore.storeData({header: {content: ""}});
                }
            }, []);

            return <div className="form__block">
                <label className="form__label">{i18n("templates.amotemplates.form.video.label")}</label>
                <FileLoad types={[".mp4"]}
                          size={VIDEO_MAX_SIZE}
                          disabled={formState.data.processing !== "idle" || !editable}
                          initial={props.content}
                          onChange={(e, {file, isAccepted}) => handleChange(isAccepted, file)}
                />
            </div>;
        });

        const pretemplate = modelPt.pretemplateItems.get(optedPt.code)?.payload.template;
        if (pretemplate) {
            const text = pretemplate.header?.payload;
            const type = pretemplate.header?.type;
            if (type) {
                const content = formStore.data.header?.media?.name ?? formStore.data.header?.content;
                switch (type) {
                    case "text": return (text && text.indexOf("{{1}}") !== -1) ? <Text/> : <></>;
                    case "image": return <Image content={content}/>;
                    case "document": return <Document content={content}/>;
                    case "video": return <Video content={content}/>;
                }
            }
        }

        return <></>;
    });

    const BodyBlock = observer(() => {
        const pretemplate = modelPt.pretemplateItems.get(optedPt.code)?.payload.template;
        const markers = pretemplate?.body.match(/\{\{\d+\}\}/g) ?? [];

        return <>{
            markers.map((marker, index) =>
                <div key={index} className="form__block">
                    <label className="form__label" htmlFor={`form-amotemplate-body-${index+1}`}>{i18n("templates.amotemplates.form.message.label")}: &#123;&#123;{index+1}&#125;&#125;</label>
                    <Input id={`form-amotemplate-body-${index+1}`}
                           disabled={formState.data.processing !== "idle" || !editable}
                           value={formStore.data.body?.length ? formStore.data.body[index] : ""}
                           onChange={value => {
                               const modified = (formStore.data.body) ? [...formStore.data.body] : markers.map(value => "");
                               modified[index] = value;
                               formStore.replaceData({body: modified});
                               markDirty();
                           }}
                           hotVerifier={() => formStore.validateField("body")}
                           coldVerified={formStore.validationResult[1].body}
                           data-autoresize
                    />
                </div>
            )
        }</>;
    });

    const ButtonsBlock = observer(() => {
        const pretemplate = modelPt.pretemplateItems.get(optedPt.code)?.payload.template;
        const hasButtonUrl = pretemplate?.buttons?.some(button => button.type === "url" && button.payload.match(/\{\{\d+\}\}/g));

        return (hasButtonUrl) ? <div className="form__block">
            <label className="form__label" htmlFor="form-amotemplate-buttons">{i18n("templates.amotemplates.form.button.label")}: &#123;&#123;1&#125;&#125;</label>
            <Input id="form-amotemplate-buttons"
                   disabled={formState.data.processing !== "idle" || !editable}
                   value={formStore.data.buttonUrl}
                   onChange={value => {
                       formStore.replaceData({buttonUrl: value});
                       markDirty();
                   }}
                   hotVerifier={() => formStore.validateField("buttonUrl")}
                   data-autoresize
            />
        </div> : <></>;
    });

    const ControlsBlock = observer(() => {
        function SaveButton(props: {disabled?: boolean, visible: boolean, processing: boolean, processingFailed: boolean}) {
            return (props.visible) ? <button
                disabled={props.disabled}
                type="button"
                className={buildClassName(
                    "button button--save",
                    varClass(props.processing,
                        varClass(props.processingFailed, "button-floppy-disk shake", "button-loader"),
                        "button-floppy-disk"
                    )
                )}
                onClick={e => {
                    const validation = formStore.validate();
                    setValidation(validation);

                    if (validation[0] && onSave) {
                        onSave(formStore.makeModel());
                    }
                }}
            >
                <span className="button__text">
                    {i18n("button.save")}
                </span>
            </button> : <></>;
        }

        function DeleteButton(props: {disabled?: boolean, visible: boolean}) {
            function TrashBinIcon() {
                return <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.33333 2.6665V1.99984C9.33333 1.64622 9.19286 1.30708 8.94281 1.05703C8.69276 0.80698 8.35362 0.666504 8 0.666504H4C3.64638 0.666504 3.30724 0.80698 3.05719 1.05703C2.80714 1.30708 2.66667 1.64622 2.66667 1.99984V2.6665H0.666667C0.489856 2.6665 0.320286 2.73674 0.195262 2.86177C0.0702379 2.98679 0 3.15636 0 3.33317C0 3.50998 0.0702379 3.67955 0.195262 3.80457C0.320286 3.9296 0.489856 3.99984 0.666667 3.99984H1.33333V11.3332C1.33333 11.8636 1.54405 12.3723 1.91912 12.7474C2.29419 13.1225 2.8029 13.3332 3.33333 13.3332H8.66667C9.1971 13.3332 9.70581 13.1225 10.0809 12.7474C10.456 12.3723 10.6667 11.8636 10.6667 11.3332V3.99984H11.3333C11.5101 3.99984 11.6797 3.9296 11.8047 3.80457C11.9298 3.67955 12 3.50998 12 3.33317C12 3.15636 11.9298 2.98679 11.8047 2.86177C11.6797 2.73674 11.5101 2.6665 11.3333 2.6665H9.33333ZM8 1.99984H4V2.6665H8V1.99984ZM9.33333 3.99984H2.66667V11.3332C2.66667 11.51 2.7369 11.6796 2.86193 11.8046C2.98695 11.9296 3.15652 11.9998 3.33333 11.9998H8.66667C8.84348 11.9998 9.01305 11.9296 9.13807 11.8046C9.26309 11.6796 9.33333 11.51 9.33333 11.3332V3.99984Z" fill="rgba(14, 14, 14, 0.35)"/>
                    <path d="M4 5.33301H5.33333V10.6663H4V5.33301Z" fill="rgba(14, 14, 14, 0.35)"/>
                    <path d="M6.6665 5.33301H7.99983V10.6663H6.6665V5.33301Z" fill="rgba(14, 14, 14, 0.35)"/>
                </svg>;
            }

            return (props.visible) ? <button
                type="button"
                className="button button--cancellation"
                disabled={props.disabled}
                onClick={e => onDelete && onDelete()}
            >
                <TrashBinIcon/>
                {i18n("button.remove")}
            </button> : <></>;
        }

        function CancelButton(props: {disabled?: boolean, visible: boolean}) {
            return (props.visible) ? <button
                type="button"
                className="button button--cancellation ml14"
                disabled={props.disabled}
                onClick={e => onCancel && onCancel()}
            >
                {i18n("button.cancel")}
            </button> : <></>;
        }

        return <div className="form__block flex">
            <SaveButton
                visible={formState.data.mode !== "idle"}
                disabled={!formState.data.isModified || formState.data.processing !== "idle"}
                processing={formState.data.processing !== "idle"}
                processingFailed={formState.data.processing === "processing-failed"}
            />
            <CancelButton
                visible={formState.data.isModified}
                disabled={!formState.data.isModified || formState.data.processing !== "idle"}
            />
            <DeleteButton
                visible={formState.data.mode === "idle"}
            />
        </div>;
    });

    const PreviewBlock = observer(() => {
        if (optedPt) {
            const pretemplate = modelPt.pretemplateItems.get(optedPt.code);
            if (pretemplate) {
                let fileLink = undefined;
                let headerMappedType = undefined;
                const headerType = pretemplate.payload.template.header?.type;
                switch (headerType) {
                    case "image":
                        headerMappedType = "image";

                        if (formStore.data.header?.media) {
                            fileLink = URL.createObjectURL(formStore.data.header?.media);
                        } else {
                            fileLink = formStore.data.header?.content;
                        }

                        break;
                    case "video":
                        headerMappedType = "video";
                        if (formStore.data.header?.media) {
                            fileLink = URL.createObjectURL(formStore.data.header?.media)
                        } else {
                            fileLink = formStore.data.header?.content;
                        }
                        break;
                    case "document":
                        headerMappedType = "pdf";
                        if (formStore.data.header?.media) {
                            fileLink = formStore.data.header?.media.name;
                        } else {
                            fileLink = formStore.data.header?.content;
                        }
                        break;
                }

                return <PhonePreview {...{
                    phoneMode: props.PhonePreviewState,
                    phoneModeState: props.setPhonePreviewState,
                    fileType: headerMappedType,
                    fileLink: fileLink,
                    headerTitle: appStore.selectedAccountPhone,
                    headerImg: appStore.selectedAccountPhoto,
                    messageHeader: pretemplate.payload.template.header?.payload,
                    messageText: pretemplate.payload.template.body,
                    messageFooter: pretemplate.payload.template.footer,
                    messageBtns: pretemplate.payload.template.buttons
                        ?.map(button => ({title: button.caption, type: button.type.toUpperCase()})) ?? [],
                    messageHeaderMarker: formStore.data.header?.content,
                    messageTextMarkers: formStore.data.body,
                    markersRevealStrategy: (isHover) => !isHover,
                    markerReplacementStrategy: (vacancy, marker, index) => false,
                }}/>
            }
        }

        return <></>
    });

    return <>
        <form className={`form ${(!props.editable || loadingPt) ? "blur" : ""}`}>
            <OptionsBlock/>
            <HeaderBlock/>
            <BodyBlock/>
            <ButtonsBlock/>
            <ControlsBlock/>
        </form>
        <PreviewBlock/>
    </>
});