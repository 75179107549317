import {useEffect} from "react";

type ValidatorCallback = (...values: Array<any>) => boolean
type ValidatorCondition = [ValidatorCallback, string|undefined];

export type ValidatorPropsExtension = {
    onValidate?: (isValid: boolean) => void,
    conditions?: Array<ValidatorCondition>,
}

export type ValidatorResult = {
    isValid: boolean,
    details: Array<string>
}

function validate(conditions?: Array<ValidatorCondition>, ...values: Array<any>): ValidatorResult {
    let isValid: ValidatorResult["isValid"] = true;
    let details: ValidatorResult["details"] = [];
    if (conditions) {
        for (let condition of conditions) {
            const result = condition[0](...values);
            if (!result) details.push(condition[1] ?? "Ошибка валидации");

            isValid = isValid && result;
        }
    }

    return {isValid, details};
}

export function useValidator(conditions?: Array<ValidatorCondition>, onValidate?: ValidatorPropsExtension["onValidate"], ...initialValues: Array<any>) {
    useEffect(() => {
        if (onValidate) onValidate(validate(conditions, ...initialValues).isValid);
        return () => onValidate && onValidate(true);
    // eslint-disable-next-line
    }, []);

    return [(...values: Array<any>) => validate(conditions, ...values)];
}