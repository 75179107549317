import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

import {App} from "./admin_panel/App";
import {Provider} from "mobx-react";
import {RootStore} from "./admin_panel/stores/RootStore";

const mobxStores = {
    root: new RootStore()
};

ReactDOM.render(
    <React.StrictMode>
        <Provider {...mobxStores}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
