import {action, computed, makeObservable, observable} from "mobx";

interface Mappable<K, V> {
    getValue(key: K): V|null;
    hasKey(key: K): boolean;
}

interface OrderedMap<K, V> extends Mappable<K, V>{
    getIndex(key: K): number|null;
}

interface DedicatedHolder<I, K> {
    downloadItems(): Promise<boolean>;
    deleteItem(key: K): Promise<boolean>;
    downloadItem(key: K): Promise<boolean>;
    updateItem(item: I): Promise<boolean>;
    uploadItem(item: I): Promise<K>;
}

export abstract class Model<KEY, PARTIAL_ITEM, FULL_ITEM=PARTIAL_ITEM> implements OrderedMap<KEY, PARTIAL_ITEM>, DedicatedHolder<FULL_ITEM, KEY> {
    public list = new Map<KEY, PARTIAL_ITEM>();

    abstract get lastUpdatedTemplateId(): KEY|null;
    abstract get lastCreatedTemplateId(): KEY|null;
    abstract get asSortedByCreatedDate(): PARTIAL_ITEM[]|null;

    abstract getIndex(key: KEY): number | null;
    abstract getValue(key: KEY): PARTIAL_ITEM | null;
    abstract hasKey(key: KEY): boolean;

    abstract deleteItem(key: KEY): Promise<boolean>;
    abstract downloadItem(key: KEY): Promise<boolean>;
    abstract downloadItems(): Promise<boolean>;
    abstract updateItem(item: FULL_ITEM): Promise<boolean>;
    abstract uploadItem(item: FULL_ITEM): Promise<KEY>;

    public create(initial: [KEY, PARTIAL_ITEM][]): void {
        this.list = new Map<KEY, PARTIAL_ITEM>(initial);
    }

    public set(key: KEY, value: PARTIAL_ITEM): Map<KEY, PARTIAL_ITEM> {
        return this.list.set(key, value);
    }

    public delete(key: KEY): boolean {
        return this.list.delete(key);
    }

    protected constructor() {
        makeObservable(this, {
            list: observable,
            lastUpdatedTemplateId: computed,
            lastCreatedTemplateId: computed,
            asSortedByCreatedDate: computed,
            set: action,
            delete: action,
            deleteItem: action,
            downloadItems: action,
            downloadItem: action,
            updateItem: action,
            uploadItem: action,
        });
    }
}
