import {
    PacketCommonResponse,
    PacketRequestCreate, PacketRequestUpdate, PacketResponseCreate, PacketResponseCreateWABAReject,
    PacketResponseGet, PacketResponseList,
} from "./types/PretemplateDataBackend";
import {Config} from "../../config";
import authorizedAxios from "../http/authorizedAxios";

const pretemplatesApiUrl = Config.network.base + Config.network.api.pretemplates;

export async function updatePretemplate(template: PacketRequestUpdate): Promise<PacketCommonResponse> {
    const result = await authorizedAxios.post(
        pretemplatesApiUrl,
        JSON.stringify({template_id: template.template_id, name: template.name}),
        {headers: {Action: "updated", "Content-Type": "application/json"}}
    );
    return result.data;
}

export async function uploadPretemplate(template: PacketRequestCreate): Promise<PacketResponseCreate|PacketResponseCreateWABAReject> {
    function isPacketResponseCreate(data: any): boolean {
        const hasSuccess = data.hasOwnProperty("success");
        const hasMessage = data.hasOwnProperty("message");

        return hasSuccess && hasMessage;
    }

    function isPacketResponseCreateWABAReject(data: any): boolean {
        const hasCode = data.hasOwnProperty("code");
        const hasMessage = data.hasOwnProperty("message");
        const hasDetails = data.hasOwnProperty("details");

        return hasCode && hasMessage && hasDetails;
    }

    const result = await authorizedAxios.post(
        pretemplatesApiUrl,
        JSON.stringify(template),
        {headers: {Action: "created", "Content-Type": "application/json"}}
    );

    let type = undefined;
    if (isPacketResponseCreate(result.data)) type = "PacketResponseCreate";
    else if (isPacketResponseCreateWABAReject(result.data)) type = "PacketResponseCreateWABAReject";

    return {
        type: type,
        ...result.data,
    };
}

export async function loadPretemplate(internalId: number): Promise<PacketResponseGet> {
    const result = await authorizedAxios.get(
        pretemplatesApiUrl,
        {params: {template_id: internalId}}
    );
    return result.data;
}

export async function loadPretemplatesList(): Promise<PacketResponseList> {
    const result = await authorizedAxios.get(pretemplatesApiUrl);
    return result.data;
}