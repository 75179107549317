import {useRef, useEffect, useState, useCallback} from "react";
import {buildClassName, varClass} from "../../../../utils/StringUtils";
import {useValidator, ValidatorPropsExtension, ValidatorResult} from "./hooks/Validator";

type TextareaProps = {
    value?: string,
    handleChange?: (value: any) => void,
    inputClassName?: string,
    [attr: string]: any // Возможность передать любые атрибуты
}

export const TextareaWithMarker = (props: TextareaProps & ValidatorPropsExtension) => {
    const {value = "", handleChange, onValidate, disabled, conditions, className, inputClassName, ...attributes} = props;
    const [revalidate] = useValidator(conditions, onValidate, value);
    const ref = useRef<HTMLTextAreaElement>(null);
    let textValidation: ValidatorResult;

    const [textareaSize, setTextareaSize] = useState(ref.current?.offsetWidth);

    const handleTextareaResize = useCallback(() => {
        setTextareaSize(ref.current?.offsetWidth);
    }, []);

    useEffect(() => {
      window.addEventListener('resize', handleTextareaResize);
      return () => {
        window.removeEventListener('resize', handleTextareaResize);
      }
    }, [handleTextareaResize]);

    useEffect(() => {
        const targetElement = (ref.current as HTMLElement);
        targetElement.style.height = "auto";
        targetElement.style.height = (targetElement.scrollHeight > 342 ? 342 : targetElement.scrollHeight) + "px";
    }, [textareaSize, value]);

    function onChange(event: React.ChangeEvent<HTMLTextAreaElement|HTMLInputElement>) {
        const actualValidation = revalidate(event.target.value);

        if (onValidate && (actualValidation.isValid !== textValidation.isValid)) onValidate(actualValidation.isValid);
        if (handleChange) handleChange(event.target.value);

        textValidation = actualValidation;
    }

    textValidation = revalidate(value);

    const inputClasses = buildClassName("form__textarea", inputClassName);
    const rootClasses = buildClassName("form__group", varClass(!textValidation.isValid, "valid-error"), varClass(disabled, "form__group--disabled"), className);
    return <>
        <div className={rootClasses}>
            <div className={inputClasses}>
                <textarea disabled={disabled} ref={ref} {...attributes} value={value} onChange={onChange}/>
            </div>
        </div>
        {(textValidation.details.length === 0) ? <></> : <div className="valid-feedback">
            {textValidation.details.map((detail, index) => <p key={index}>{detail}</p>)}
        </div>}
    </>;
};