import {observer} from "mobx-react-lite";
import {PretemplateExample} from "../../../stores/form/pretemplate/PretemplateExampleFormStore";
import {Input} from "../local_component/Input";
import {i18n} from "../../../../i18n/i18n";

type PretemplateExampleFormProps = {
    store: PretemplateExample.FormStore,
    editable?: boolean,
    creationMode?: boolean,
    hasTextHeader: boolean,
}
export const PretemplateExampleForm = ((props: PretemplateExampleFormProps) => {
    const {store, editable = true, creationMode = false} = props;

    const Caption = observer(() => {
        const hasHeader = typeof store.data.header !== "undefined";
        const hasBody = typeof store.data.body !== "undefined";
        const hasUrl = typeof store.data.buttonUrl !== "undefined";
        const headerVisibility = hasHeader && props.hasTextHeader;
        if (headerVisibility || hasBody || hasUrl) {
            return <div className="form__block"><p className="form__block-title">{i18n("templates.pretemplates.form.example")}</p></div>;
        }

        return <></>;
    });

    const HeaderBlock = observer(() => {
        if (typeof store.data.header !== "undefined" && props.hasTextHeader) {
            return <div className="form__block">
                <label className="form__label" htmlFor="form-example-header">{i18n("templates.pretemplates.form.header.label")}: &#123;&#123;1&#125;&#125;</label>
                <Input id="form-example-header"
                       disabled={!editable}
                       asText={!creationMode}
                       value={store.data.header}
                       onChange={value => {
                           store.storeData({header: value});
                       }}
                       onFocus={() => {
                           store.storeData({_focusedInput: 0});
                       }}
                       onBlur={() => {
                           store.replaceData({_focusedInput: undefined});
                       }}
                       data-autoresize
                />
            </div>;
        }

        return <></>;
    });

    const BodyBlock = observer(() => {
        if (typeof store.data.body !== "undefined") {
            return <>{store.data.body.map((marker, index) =>
                <div key={index} className="form__block">
                    <label className="form__label" htmlFor={`form-example-body-${index+1}`}>{i18n("templates.pretemplates.form.message.label")}: &#123;&#123;{index+1}&#125;&#125;</label>
                    <Input id={`form-example-body-${index+1}`}
                           disabled={!editable}
                           asText={!creationMode}
                           value={store.data.body && store.data.body[index]}
                           onChange={value => {
                               if (store.data.body) {
                                   const modified = [...store.data.body];
                                   modified[index] = value;
                                   store.replaceData({body: modified});
                               }
                           }}
                           onFocus={() => {
                               store.storeData({_focusedInput: 1+index});
                           }}
                           onBlur={() => {
                               store.replaceData({_focusedInput: undefined});
                           }}
                           data-autoresize
                    />
                </div>
            )}</>;
        }

        return <></>;
    });

    const ButtonsBlock = observer(() => {
        const hasUrl = typeof store.data.buttonUrl !== "undefined";
        if (hasUrl) {
            return <div className="form__block">
                <label className="form__label" htmlFor="form-example-button-url">{i18n("templates.pretemplates.form.button.label")}: &#123;&#123;1&#125;&#125;</label>
                <Input id="form-example-button-url"
                       disabled={!editable}
                       asText={!creationMode}
                       value={store.data.buttonUrl}
                       onChange={value => {
                           store.storeData({buttonUrl: value});
                       }}
                       data-autoresize
                />
            </div>;
        }

        return <></>;
    });

    return <>
        <Caption/>
        <HeaderBlock/>
        <BodyBlock/>
        <ButtonsBlock/>
    </>;
});