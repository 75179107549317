import React, {PropsWithChildren, useState} from "react";
import {observer} from "mobx-react-lite";
import {buildClassName, varClass} from "../../../../utils/StringUtils";
import { Scrollbar } from "smooth-scrollbar-react";
import {useOutboundClickHandler} from "../../../../hooks/OutboundClickHook";

export type SelectProps = {
    items: SelectItemProps[],
    defaultOptedId?: string,
    name: string,
    className?: string,
    disabled?: boolean,
    onOpted?: (optedId: string) => void
}

export type SelectItemProps = {
    id: string,
    jsx: JSX.Element,
    onOpted?: () => void
}

export const Select = observer((props: PropsWithChildren<SelectProps>) => {
    const {items, disabled, defaultOptedId = items[0].id, onOpted, name, className} = props;
    const [optedId, setOpted] = useState(defaultOptedId);
    const [isExpanded, ref, setExpanded] = useOutboundClickHandler<HTMLDivElement>();

    const handleItemClick = (event: React.MouseEvent, clickedItem: SelectItemProps) => {
        if (event.button === 0) setOpted(clickedItem.id);
        if (clickedItem.onOpted) clickedItem.onOpted();
        if (onOpted) onOpted(clickedItem.id);
    }

    const handleSelectClick = (event: React.MouseEvent) => {
        if ((event.target as HTMLInputElement).className === 'scrollbar-thumb scrollbar-thumb-y') return;
        if (!disabled && event.button === 0) setExpanded(!isExpanded);
    }

    // Сортировка, поднимающая выбранный элемент наверх списка
    const sorted = items.sort((up, down) =>
        (up.id !== optedId) ? (down.id !== optedId) ? 0 : 1 : -1
    );

    // Отрисовка списка элементов
    const elements = sorted.map((item) => {
        const isOpted = item.id === optedId;
        const itemClasses = buildClassName(varClass(isOpted, "form__select-selected"));
        return <li key={item.id} id={item.id} className={itemClasses} onMouseDown={e => handleItemClick(e, item)}>
            <div>{item.jsx}</div>
        </li>;
    });

    const rootClass = buildClassName("form__select", varClass(!!className, className), varClass(isExpanded, "form__select--active"), varClass(disabled, "form__select--disabled"));
    return <div ref={ref} className={rootClass} onMouseDown={handleSelectClick}>
        <span className="form__select-placeholder">{sorted[0].jsx}</span>
        <div className="form__select-options">
            <Scrollbar alwaysShowTracks className="form__select-scroll" continuousScrolling={false}>
                <div>
                    <ul>{elements}</ul>
                </div>
            </Scrollbar>
        </div>
        <input type="hidden" disabled={disabled} name={name} value={sorted[0].id}/>
    </div>;
});