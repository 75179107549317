export function buildClassName(first?: string, ...classes: Array<string|undefined>): string {
    let className = first ?? '';
    for (let name of classes) {
        const gap = className ? ' ' : '';
        if (name) className += gap + name;
    }

    return className;
}

export function varClass(condition?: boolean, clazz?: string, otherwise?: string): string {
    return (condition && clazz) ? clazz : (otherwise ?? '');
}

/**
 * Вычисляет длину строки из последовательности чисел от 1 до n.
 * Например: n = 15; 123456789101112131415 -> 21;
 * @param {number} n Число, до которого вычисляется длина строки(включительно)
 * @return {number} Вычисленная длина итоговой строки
 * @throws {Error} Если аргумент не целый или отрицательный(т.е. n ∉ N)
 */
export function countConcatedDigits(n: number): number {
    if (!Number.isInteger(n)) throw new Error("Argument #1 should be a integer");
    if (n > 0) {
        const digits = 1+Math.floor(Math.log10(n));
        return digits + countConcatedDigits(n-1);
    } else if (n === 0) {
        return 0;
    } else {
        throw new Error("Argument #1 cannot be a negative");
    }
}