/*
* Описание:
*  - Парсинг и первичная обработка пакетов данных пришедших по сети;
*  - Кодирование пакетов данных для отправки по сети;
*/

import {
    ComponentBody,
    ComponentButtonPhoneNumber,
    ComponentButtonQuickReply,
    ComponentButtons, ComponentButtonType,
    ComponentButtonUrl,
    ComponentFooter,
    ComponentHeader, ComponentType,
    PacketRequestCreate,
    PacketRequestUpdate,
    PacketResponseGet,
    PacketResponseGetData,
    PacketResponseListData
} from "../types/PretemplateDataBackend";
import {
    LanguageTag,
    NotificationCategory,
    statusSurjection,
    SupportedLanguage,
    TemplateStatusType,
} from "../../../utils/FacebookAPI";
import {
    ButtonCompoundType,
    ButtonType, HeaderType,
    ModelItem,
    ModelListItem,
    PretemplateStatus, TemplatePayload,
} from "../types/PretemplateDataModel";
import {getKeyByValue} from "../../../utils/Assoc";
import {Config} from "../../../config";
import {merge} from "lodash"

const unpack2dExample = (example?: string[]|string[][]) => Array.isArray(example) ? example.flat() : undefined;
const unpack1dExample = (example?: string|string[]) => Array.isArray(example) ? example[0] : example;
const dupeCases = (array: string[]) => array.map(s => [s.toLowerCase(), s.toUpperCase()]).flatMap(s => s);

export function decodePacketResponseListData(packet: PacketResponseListData): ModelListItem {
    const languageTag = getKeyByValue(LanguageTag, packet.language.trim(),"RUSSIAN");
    const language = SupportedLanguage[languageTag as keyof typeof SupportedLanguage];
    const status = statusSurjection[packet.status];
    return {
        template_id: packet.template_id,
        code: packet.code,
        name: packet.name,
        status: status,
        language: language,
        created_at: Date.parse(packet.created_at),
        updated_at: Date.parse(packet.updated_at),
    };
}

export function decodePacketResponseGet(backend: PacketResponseGet): ModelItem {
    const template: PacketResponseGetData = JSON.parse(backend.data);

    const payload: TemplatePayload = {template: {body: ""}};
    const buttonReaders = {
        "QUICK_REPLY": (button: ComponentButtonQuickReply) => ( {type: "quick_reply", caption: button.text} ),
        "PHONE_NUMBER": (button: ComponentButtonPhoneNumber) => ( {type: "phone_number", caption: button.text, payload: button.phone_number} ),
        "URL": (button: ComponentButtonUrl) => {
            const example = unpack1dExample(button.example);
            merge(payload, {example: {buttonUrl: example}});
            return {type: "url", caption: button.text, payload: button.url};
        },
    };
    const componentReaders = {
        "HEADER": (component: ComponentHeader) => {
            if (dupeCases(["TEXT", "IMAGE", "VIDEO", "DOCUMENT"]).includes(component.format)) {
                const example = unpack1dExample(component.example?.header_text ?? component.example?.header_handle);
                const type = component.format.toLowerCase() as HeaderType["type"];
                payload.template.header = {type: type, payload: component.text};
                merge(payload, {example: {header: example}});
            }
        },
        "BODY": (component: ComponentBody) => {
            const example = unpack2dExample(component.example?.body_text);
            payload.template.body = component.text;
            merge(payload, {example: {body: example}});
        },
        "FOOTER": (component: ComponentFooter) => {
            payload.template.footer = component.text;
        },
        "BUTTONS": (component: ComponentButtons) => {
            const buttons = component.buttons
                .filter(button => dupeCases(["QUICK_REPLY", "PHONE_NUMBER", "URL"]).includes(button.type))
                .map(button => buttonReaders[button.type.toUpperCase() as ComponentButtonType](button as any));
            if (buttons.length > 0) {
                payload.template.buttons = buttons as ButtonCompoundType;
            }
        },
    };

    template.components
        .filter(component => dupeCases(["HEADER", "BODY", "FOOTER", "BUTTONS"]).includes(component.type))
        .forEach(component => componentReaders[component.type.toUpperCase() as ComponentType](component as any));

    function translateStatus(rawStatus: string, rawReason?: string): PretemplateStatus {
        const status = statusSurjection[rawStatus];
        return {type: status, reason: status === TemplateStatusType.REJECTED ? rawReason : undefined};
    }

    const status = translateStatus(template.status, template.rejected_reason);
    const category = backend.category as NotificationCategory;
    const languageKey = getKeyByValue(LanguageTag, backend.language.trim(),"RUSSIAN");
    const language = SupportedLanguage[languageKey as keyof typeof SupportedLanguage];

    return {
        name: backend.name,
        code: backend.code,
        status: status,
        category: category,
        language: language,
        payload: payload,
        created_at: Date.parse(backend.created_at),
        updated_at: Date.parse(backend.updated_at),
    };
}

export function encodePacketRequestUpdate(template_id: number, model: ModelItem): PacketRequestUpdate {
    return {
        template_id: template_id,
        name: model.name,
    };
}

export function encodePacketRequestCreate(model: ModelItem, wa_id: string): PacketRequestCreate {
    const components = new Array<ComponentHeader|ComponentBody|ComponentFooter|ComponentButtons>();

    if (model.payload.template.header && dupeCases(["text", "image", "video", "document"]).includes(model.payload.template.header.type)) {
        const wrapText = (example: string|undefined) => (example) ? {header_text: [example]} : undefined;
        const wrapHandle = (example: string) => ( {header_handle: [example]} );
        const exampleGetters = {
            "text": () => wrapText(model.payload.example?.header),
            "video": () => wrapHandle(Config.whatsapp.plug.video),
            "image": () => wrapHandle(Config.whatsapp.plug.image),
            "document": () => wrapHandle(Config.whatsapp.plug.document),
        }

        const header = model.payload.template.header;
        components.push({
            type: "HEADER",
            format: header.type.toUpperCase(),
            text: header.payload,
            example: exampleGetters[header.type](),
        });
    }

    {
        const bodyText = model.payload.template.body;
        const example = model.payload.example?.body;

        components.push({
            type: "BODY",
            text: bodyText,
            example: (example) ? {body_text: [example]} : undefined,
        });
    }

    if (model.payload.template.footer) {
        const footerText = model.payload.template.footer;

        components.push({
            type: "FOOTER",
            text: footerText,
        });
    }

    if (model.payload.template.buttons) {
        const wrapExample = (example: string|undefined) => (example) ? [example] : undefined;
        const buttonBuilders = {
            "quick_reply": (button: ButtonType) => ( {type: "QUICK_REPLY", text: button.caption} ),
            "phone_number": (button: ButtonType) => ( {type: "PHONE_NUMBER", text: button.caption, phone_number: button.payload} ),
            "url": (button: ButtonType) => ( {type: "URL", text: button.caption, url: button.payload, example: wrapExample(model.payload.example?.buttonUrl)} ),
        }

        const buttons = model.payload.template.buttons;
        components.push({
            buttons: buttons
                .filter(button => dupeCases(["quick_reply", "phone_number", "url"]).includes(button.type))
                .map(button => buttonBuilders[button.type](button)),
            type: "BUTTONS"
        });
    }

    const language = LanguageTag[SupportedLanguage[model.language] as keyof typeof LanguageTag];
    return {
        name: model.name,
        code: model.code,
        category: model.category,
        language: language,
        wa_id: wa_id,
        components: components,
    };
}