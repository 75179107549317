import {observer} from "mobx-react-lite";
import React, {PropsWithChildren, useEffect, useState} from "react";
import {ButtonTypes} from "../../../../utils/FacebookAPI";
import {Input, InputWithMarker} from "../local_component/Input";
import {buildClassName, varClass} from "../../../../utils/StringUtils";
import {PretemplateButtons} from "../../../stores/form/pretemplate/PretemplateButtonsFormStore";
import {useDisposableFlag} from "../../../../hooks/DisposableHook";
import {QuickReply} from "../../../stores/form/pretemplate/buttons/QuickReply";
import {Url} from "../../../stores/form/pretemplate/buttons/Url";
import {PhoneNumber} from "../../../stores/form/pretemplate/buttons/PhoneNumber";
import {PretemplateExample} from "../../../stores/form/pretemplate/PretemplateExampleFormStore";
import {i18n} from "../../../../i18n/i18n";

type QuickReplyFormProps = {
    store: QuickReply.FormStore,
    editable?: boolean,
    creationMode?: boolean,
};
const QuickReplyForm = observer((props: QuickReplyFormProps) => {
    const {store, editable = true, creationMode = false} = props;

    const [, markDirty] = useDisposableFlag();

    return <><Input placeholder={i18n("templates.pretemplates.form.buttons.name_button.placeholder")}
                    disabled={!editable}
                    value={store.getData().caption}
                    onChange={value => {
                        store.storeData({caption: value});
                        markDirty();
                    }}
                    hotVerifier={() => store.validateField("caption")}
                    coldVerified={store.validationResult[1].caption}
                    asText={!creationMode}
                    autoFocus
    /></>;
});

type UrlFormProps = {
    store: Url.FormStore,
    editable?: boolean,
    creationMode?: boolean,
};
const UrlForm = observer((props: UrlFormProps) => {
    const {store, editable = true, creationMode = false} = props;

    const [, markDirty] = useDisposableFlag();

    return <>
        <div className="mb8">
            <Input placeholder={i18n("templates.pretemplates.form.buttons.name_button.placeholder")}
                   disabled={!editable}
                   value={store.getData().caption}
                   onChange={value => {
                       store.storeData({caption: value});
                       markDirty();
                   }}
                   hotVerifier={() => store.validateField("caption")}
                   coldVerified={store.validationResult[1].caption}
                   asText={!creationMode}
                   autoFocus
            />
        </div>
        <InputWithMarker placeholder={i18n("templates.pretemplates.form.buttons.url.placeholder")}
                         disabled={!editable}
                         value={store.getData().url}
                         onChange={value => {
                             store.storeData({url: value});
                             markDirty();
                         }}
                         hotVerifier={() => store.clearField("url")}
                         coldVerified={store.validationResult[1].url}
                         asText={!creationMode}
        />
    </>;
});

type PhoneNumberFormProps = {
    store: PhoneNumber.FormStore,
    editable?: boolean,
    creationMode?: boolean,
};
const PhoneNumberForm = observer((props: PhoneNumberFormProps) => {
    const {store, editable = true, creationMode = false} = props;

    const [, markDirty] = useDisposableFlag();

    return <>
        <div className="mb8">
            <Input placeholder={i18n("templates.pretemplates.form.buttons.name_button.placeholder")}
                   disabled={!editable}
                   value={store.getData().caption}
                   onChange={value => {
                       store.storeData({caption: value});
                       markDirty();
                   }}
                   hotVerifier={() => store.validateField("caption")}
                   coldVerified={store.validationResult[1].caption}
                   asText={!creationMode}
                   autoFocus
            />
        </div>
        <Input placeholder={i18n("templates.pretemplates.form.buttons.phone.placeholder")}
               disabled={!editable}
               value={store.getData().phone}
               onChange={value => {
                   store.storeData({phone: value});
                   markDirty();
               }}
               hotVerifier={() => store.clearField("phone")}
               coldVerified={store.validationResult[1].phone}
               asText={!creationMode}
        />
    </>;
});

type ButtonsFormProps = {
    store: PretemplateButtons.FormStore,
    exampleStore: PretemplateExample.FormStore,
    visible?: boolean,
    editable?: boolean,
    creationMode?: boolean,
    onChange?: () => void,
};
export const ButtonsForm = observer((props: ButtonsFormProps) => {
    const {store, editable = true, visible = true, onChange, exampleStore, creationMode = false} = props;

    const [, markDirty] = useDisposableFlag();
    const [opted, opt] = useState(0);

    // Автоматическая перерисовка компонента observer-ом MobX
    // Необходимо для корректной отрисовки после удаления кнопок
    // eslint-disable-next-line
    useEffect(() => {}, [store.getCount()]);

    function handleCreateButton(type: ButtonTypes) {
        let newStore;
        switch (type) {
            case ButtonTypes.QUICK_REPLY: newStore = {type: ButtonTypes.QUICK_REPLY, substore: new QuickReply.FormStore()}; break;
            case ButtonTypes.PHONE_NUMBER: newStore = {type: ButtonTypes.PHONE_NUMBER, substore: new PhoneNumber.FormStore()}; break;
            case ButtonTypes.URL:
                newStore = {type: ButtonTypes.URL, substore: new Url.FormStore(exampleStore)};
                break;
        }

        if (store.addStore(newStore)) {
            opt(store.getCount()-1);
            markDirty();

            if (onChange) onChange();
        }
    }

    function handleDeleteButton(deleteIndex: number) {
        store.removeStore(deleteIndex)
        opt(0);
        markDirty();

        if (onChange) onChange();
    }

    function ButtonTab(props: {index: number, caption: string}) {
        const {index, caption} = props;
        const className = buildClassName("form__tags-item", varClass(opted === index, "form__tags-item--active"));
        const isActive = opted === index;

        const DeleteButton = () => <button
            type="button"
            className="button form__tags-button"
            onClick={e => {
                handleDeleteButton(index);
                e.stopPropagation();
            }}
        >
            <svg visibility="none" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.91667 1.91675V1.33341C8.91667 1.024 8.79375 0.727249 8.57496 0.508457C8.35616 0.289664 8.05942 0.166748 7.75 0.166748H4.25C3.94058 0.166748 3.64383 0.289664 3.42504 0.508457C3.20625 0.727249 3.08333 1.024 3.08333 1.33341V1.91675H1.33333C1.17862 1.91675 1.03025 1.97821 0.920854 2.0876C0.811458 2.197 0.75 2.34537 0.75 2.50008C0.75 2.65479 0.811458 2.80316 0.920854 2.91256C1.03025 3.02196 1.17862 3.08341 1.33333 3.08341H1.91667V9.50008C1.91667 9.96421 2.10104 10.4093 2.42923 10.7375C2.75742 11.0657 3.20254 11.2501 3.66667 11.2501H8.33333C8.79746 11.2501 9.24258 11.0657 9.57077 10.7375C9.89896 10.4093 10.0833 9.96421 10.0833 9.50008V3.08341H10.6667C10.8214 3.08341 10.9697 3.02196 11.0791 2.91256C11.1885 2.80316 11.25 2.65479 11.25 2.50008C11.25 2.34537 11.1885 2.197 11.0791 2.0876C10.9697 1.97821 10.8214 1.91675 10.6667 1.91675H8.91667ZM7.75 1.33341H4.25V1.91675H7.75V1.33341ZM8.91667 3.08341H3.08333V9.50008C3.08333 9.65479 3.14479 9.80316 3.25419 9.91256C3.36358 10.022 3.51196 10.0834 3.66667 10.0834H8.33333C8.48804 10.0834 8.63642 10.022 8.74581 9.91256C8.85521 9.80316 8.91667 9.65479 8.91667 9.50008V3.08341Z" fill="rgba(14, 14, 14, 0.35)"/>
                <path d="M4.25 4.25H5.41667V8.91667H4.25V4.25Z" fill="rgba(14, 14, 14, 0.35)"/>
                <path d="M6.58334 4.25H7.75V8.91667H6.58334V4.25Z" fill="rgba(14, 14, 14, 0.35)"/>
            </svg>
        </button>;

        return <div onClick={e => opt(index)} className={className}>
            <span>{caption}</span>
            {(editable && isActive) ? <DeleteButton/> : <></>}
        </div>;
    }

    function CreateButton(props: PropsWithChildren<{type: ButtonTypes}>) {
        return editable ? <button type="button" onClick={e => handleCreateButton(props.type)} className="button form__tags-add">{props.children}</button> : <></>;
    }

    function TabPanelRuntime() {
        let form = <></>, button = <></>;

        const current = store.getStore(opted);
        switch (current?.type) {
            case ButtonTypes.QUICK_REPLY:
                form = <QuickReplyForm
                    store={current.substore as QuickReply.FormStore}
                    editable={editable}
                    creationMode={creationMode}
                />;
                if (store.getCount() < 3)
                    button = <CreateButton type={ButtonTypes.QUICK_REPLY}>+ {i18n("templates.pretemplates.form.buttons.type.quick_reply")}</CreateButton>;
                break;
            case ButtonTypes.PHONE_NUMBER:
                form = <PhoneNumberForm
                    store={current.substore as PhoneNumber.FormStore}
                    editable={editable}
                    creationMode={creationMode}
                />;
                if (store.getCount() < 2)
                    button = <CreateButton type={ButtonTypes.URL}>+ {i18n("templates.pretemplates.form.buttons.type.url")}</CreateButton>;
                break;
            case ButtonTypes.URL:
                form = <UrlForm
                    store={current.substore as Url.FormStore}
                    editable={editable}
                    creationMode={creationMode}
                />;
                if (store.getCount() < 2)
                    button = <CreateButton type={ButtonTypes.PHONE_NUMBER}>+ {i18n("templates.pretemplates.form.buttons.type.phone_number")}</CreateButton>;
                break;
        }

        return <div>
            <div className="form__tags form__tags--bg">
                {store.data.map((tuple, index) => {
                    let caption = "";
                    switch (tuple.type) {
                        case ButtonTypes.QUICK_REPLY: caption = "#" + (index+1); break;
                        case ButtonTypes.PHONE_NUMBER: caption = i18n("templates.pretemplates.form.buttons.type.phone_number"); break;
                        case ButtonTypes.URL: caption = i18n("templates.pretemplates.form.buttons.type.url"); break;
                    }

                    return <ButtonTab key={caption} index={index} caption={caption}/>
                })}
                {button}
            </div>
            <div>{form}</div>
        </div>;
    }

    function TabPanelInitial() {
        return <div className="form__tags">
            <CreateButton type={ButtonTypes.QUICK_REPLY}>+ {i18n("templates.pretemplates.form.buttons.type.quick_reply")}</CreateButton>
            <CreateButton type={ButtonTypes.PHONE_NUMBER}>+ {i18n("templates.pretemplates.form.buttons.type.phone_number")}</CreateButton>
            <CreateButton type={ButtonTypes.URL}>+ {i18n("templates.pretemplates.form.buttons.type.url")}</CreateButton>
        </div>;
    }

    function TabPanel() {
        if (store.getCount() > 0) {
            return <TabPanelRuntime/>;
        } else if (editable) {
            return <TabPanelInitial/>;
        }

        return <></>;
    }

    function FormCaption() {
        return <label className="form__label">{i18n("templates.pretemplates.form.buttons.label")}</label>;
    }

    const isVisible = visible && (editable || store.getCount() > 0);
    return (isVisible) ? <div>
        <FormCaption/>
        <TabPanel/>
    </div> : <></>;
});