import {AmotemplateModel} from "./AmotemplatesModel";
import {DocumentStore} from "./DocumentStore";
import {AuthStore} from './AuthStore';
import {AppStore} from './AppStore';
import {PretemplateModel} from "./PretemplatesModel";
import {NumbersStore} from '../page/numbers/store/NumbersStore';

export class RootStore {
    public readonly app: AppStore;
    public readonly auth: AuthStore;
    public readonly amotemplates: AmotemplateModel;
    public readonly document: DocumentStore;

    public readonly pretemplates: PretemplateModel;
    public readonly numbers: NumbersStore;

    public constructor() {
        this.app = new AppStore();
        this.auth = new AuthStore();
        this.amotemplates = new AmotemplateModel();
        this.document = new DocumentStore();

        this.pretemplates = new PretemplateModel(this.app);
        this.numbers = new NumbersStore();
    }
}