import React, {useState, useContext, useEffect, KeyboardEvent} from "react";
import {MobXProviderContext, observer} from "mobx-react";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {Redirect} from "react-router-dom";
import {AuthStore} from "../stores/AuthStore";

import LogoCompany from "../../image/Logo.svg";
import PasswordEye from "../../image/password-eye.svg";
import PasswordEyeCrossed from "../../image/password-eye-crossed.svg";
import {DocumentStore} from "../stores/DocumentStore";
import {Config} from "../../config";
import {i18n} from "../../i18n/i18n";

export const Logout = observer(() => {
    const authStore = useContext(MobXProviderContext).root.auth as AuthStore;
    authStore.logout();
    return <Redirect to='/login'/>;
});

export const Authorization = observer(() => {
    useEffect(() => {
        document.title = 'W-Target | ' + i18n("title.authorization");
    }, []);
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [invalidPassword, setInvalidPassword] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const authStore = useContext(MobXProviderContext).root.auth as AuthStore;
    const docStore = useContext(MobXProviderContext).root.document as DocumentStore;

    const onPasswordInput = (value: string): void => {
        setInvalidPassword(false);
        setPassword(value);
    };

    const onLogin = async (login: string, password: string): Promise<void> => {
        await authStore.login(login, password);
        setInvalidPassword(!authStore.isAuthorized);
    };

    const loginOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            onLogin(login, password)
        }
    };

    const [modal, setModal] = useState<ModalElement>(() => docStore.modals["auth_forgot_password"]);
    function handleModalOpen() {
        if (modal) {
            modal.show();
        } else {
            if (WTRG_BUTTON.showModal) {
                const modal = WTRG_BUTTON.showModal({
                    modal: {
                        link: {phone: Config.whatsapp.numbers.ceo, text: 'Начать чат'},
                        caption: 'Забыли пароль?\n\nНапишите в WhatsApp для восстановления.',
                    }
                });
                docStore.modals["auth_forgot_password"] = modal;
                setModal(modal);
            }
        }
    }

    return authStore.isAuthorized ? <Redirect to="/campaigns"/> : 
        <div className="auth">
            <div className="auth__cont">
                <a href="https://wtarget.ru" target="_blank" rel = "noreferrer">
                    <img className="auth__img" src={LogoCompany} alt="logo"/>
                </a>
                <div className="auth__block">
                    <h2 className="main__title main__header--small_indent">
                        <div className="main__title-text">
                            {i18n("title.authorization")}
                        </div>
                    </h2>
                    <Tabs className='tabs-container' selectedTabClassName="tabs__item--active" selectedTabPanelClassName="active">
                        <TabList className='tabs tabs--mini tabs--small_indent'>
                            {/* <Tab className="tabs__item">{i18n("authorization.tab.via_whatsapp")}</Tab> */}
                            <Tab className="tabs__item">{i18n("authorization.tab.by_password")}</Tab>
                        </TabList>
                            {/* <TabPanel className="tabs__content-pane">
                                <div className="qr-code">
                                    <div className="qr-code__content">
                                        <div className="qr-code__wrapper"></div>
                                        <div>
                                            <div className="qr-code__step">
                                                <span className="qr-code__step-index">Шаг 1</span>
                                                <p className="qr-code__step-text">Откройте WhatsApp</p>
                                            </div>
                                            <div className="qr-code__step">
                                                <span className="qr-code__step-index">Шаг 2</span>
                                                <p className="qr-code__step-text">Перейдите во <br/>вкладку “Камера”</p>
                                            </div>
                                            <div className="qr-code__step">
                                                <span className="qr-code__step-index">Шаг 3</span>
                                                <p className="qr-code__step-text">Наведите на код слева</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="qr-code__footer">
                                        <p className="qr-code__footer-question">Хотите войти через ПК?</p>
                                        <div className="flex flex--ai-center flex--space-between">
                                            <a href="/#" className="button button--save">Открыть WhatsApp Web</a>
                                            <a href="/#" className="auth__link">Как это работает?</a>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel> */}
                            <TabPanel className="tabs__content-pane">
                                <form>
                                    <div className="form__block">
                                        <label className="form__label" htmlFor="email">{i18n("authorization.form.login.label")}</label>
                                        <div className="form__group">
                                            <input
                                                className="form__input"
                                                type="email"
                                                name="email"
                                                id="email"
                                                value={login}
                                                onChange={e => setLogin(e.target.value)}
                                                onKeyPress={loginOnEnter}
                                            />
                                        </div>
                                    </div>
                                    <div className="form__block">
                                        <label className="form__label" htmlFor="password">{i18n("authorization.form.password.label")}</label>
                                        <div className={invalidPassword ? 'form__group valid-error' : 'form__group'}>
                                            <input
                                                className="form__input"
                                                type={showPassword ? "text" : "password"}
                                                name="password"
                                                id="password"
                                                value={password}
                                                onChange={e => onPasswordInput(e.target.value)}
                                                onKeyPress={loginOnEnter}
                                            />
                                            <div className="form__group-div">
                                                <button type="button" className="button" onClick={() => setShowPassword(!showPassword)}>
                                                    <img src={showPassword ? PasswordEye : PasswordEyeCrossed} alt="icon"/>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="valid-feedback" hidden={!invalidPassword}>{i18n("authorization.form.error.invalid_password")}</div>
                                    </div>
                                    <div className="form__block flex flex--ai-center flex--space-between">
                                        <button type="button" className="button button--save button-waving-hand" onClick={() => onLogin(login, password)}>{i18n("button.enter")}</button>
                                        <a href="/#" className="auth__link" onClick={e => {
                                            handleModalOpen();
                                            e.preventDefault();
                                        }}>{i18n("authorization.button.forgot_password")}</a>
                                    </div>
                                </form>
                            </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>;
});