/* Глобальное хранилище информации об страницах сайта */

import React from "react";

import {Empty} from "../page/empty/Empty";
import {Templates} from "../page/templates/Templates";
import {Integrations} from "../page/integrations/Integrations";
import {WtQR} from "../page/wtqr/WtQR";
import {Campaign} from "../page/campaign/Campaign";
import {Numbers} from "../page/numbers/Numbers";

import IconSidebarWritingHand from "../../image/writing-hand.png";
import IconSidebarRobot from "../../image/robot.png";
import IconSidebarSelfie from "../../image/selfie.png";
import IconSidebarLetter from "../../image/mailbox.png";
import IconSidebarTelephone from "../../image/telephone.png";
import IconSidebarPuzzle from "../../image/puzzle-piece.png";
// import IconSidebarNerd from "../../image/nerd-face.png";
import IconSidebarGear from "../../image/gear.png";
import IconSidebarSupport from "../../image/support-1.png";
// import IconSidebarMoney from "../../image/money-with-wings.png";

type RouteEntry = { // Для библиотеки 'react-router': Router\Switch\Link
    path: string,
    page: React.ComponentType,
}

type ItemDelimiter = { // Разделитель
    isDelimiter: true
}

export type ItemNav = { // Другой отображаемый контент для главного меню, навигации
    route: RouteEntry,
    caption: string,
    icon: string,
    isDelimiter?: false,
    isDisabled?: boolean,
    isInactive?: boolean,
}

type Item = ItemDelimiter|ItemNav;

export class DocumentStore {
    public readonly routes: Item[] = [
        {
            route: {path: "/campaigns", page: Campaign},
            caption: "sidebar.campaigns",
            icon: IconSidebarLetter
        },
        {
            route: {path: "/templates", page: Templates},
            caption: "sidebar.templates",
            icon: IconSidebarWritingHand
        },
        {
            route: {path: "/numbers", page: Numbers},
            caption: "sidebar.numbers",
            icon: IconSidebarTelephone
        },
        {
            route: {path: "/integrations", page: Integrations},
            caption: "sidebar.integrations",
            icon: IconSidebarRobot,
            isDisabled: true,
        },
        {
            route: {path: "/wtQR", page: WtQR},
            caption: "sidebar.qr_pages",
            icon: IconSidebarSelfie,
            isDisabled: true,
        },
        {
            route: {path: "/button", page: Empty},
            caption: "sidebar.button",
            icon: IconSidebarPuzzle,
            isDisabled: true,
        },
        // {
        //     route: {path: "/analytics", page: Empty},
        //     caption: "Аналитика",
        //     icon: IconSidebarNerd,
        //     isDisabled: true,
        // },
        {
            isDelimiter: true,
        },
        {
            route: {path: "/settings", page: Empty},
            caption: "sidebar.settings",
            icon: IconSidebarGear,
            isInactive: true
        },
        {
            route: {path: "/support", page: Empty},
            caption: "sidebar.support",
            icon: IconSidebarSupport,
            isInactive: true
        },
        // {
        //     route: {path: "/payment", page: Empty},
        //     caption: "Оплата",
        //     icon: IconSidebarMoney
        // },
    ];

    public readonly modals: {[key: string]: ModalElement} = {};
}