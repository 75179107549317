import React, {useContext} from "react";
import {MobXProviderContext, observer} from "mobx-react";
import {AppStore} from '../../stores/AppStore';
import { AuthStore } from "../../stores/AuthStore";

export const Profile = observer(() => {
    const appStore = useContext(MobXProviderContext).root.app as AppStore;
    const authStore = useContext(MobXProviderContext).root.auth as AuthStore;
    return <li className="header__nav-item">
        <a className="profile" href="/logout" onClick={e =>{e.preventDefault(); authStore.logout();}}>
            <div className="profile__initials">{appStore.userProfile?.initials}</div>
            <div className="profile__img" style={{backgroundImage: `url(${appStore.userProfile?.photo})`}}></div>
            <div className="profile__icon">
                <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.7298 12H12.7298C13.1276 12 13.5092 11.842 13.7905 11.5607C14.0718 11.2794 14.2298 10.8978 14.2298 10.5V1.5C14.2298 1.10218 14.0718 0.720644 13.7905 0.43934C13.5092 0.158035 13.1276 0 12.7298 0H9.7298V1.5H12.7298V10.5H9.7298V12Z" fill="#128C7E"/>
                    <path d="M5.73375 10.0387L4.66875 8.98268L7.60875 6.01793H0.75C0.551088 6.01793 0.360322 5.93891 0.21967 5.79826C0.0790176 5.6576 0 5.46684 0 5.26793C0 5.06901 0.0790176 4.87825 0.21967 4.7376C0.360322 4.59694 0.551088 4.51793 0.75 4.51793H7.62225L4.63725 1.55768L5.69325 0.492676L10.4865 5.24543L5.73375 10.0387Z" fill="#128C7E"/>
                </svg>
            </div>
        </a>
    </li>;
});