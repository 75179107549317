import sad_smiley from "../../../../image/sad_smiley.gif";
import React, {PropsWithChildren} from "react";

export type MessageProps = {
    title?: string,
    subtitle?: string,
};

export const Message = ((props: PropsWithChildren<MessageProps>) => {
    const {title = "", subtitle = "", children} = props;

    return <div className="gray-block empty">
        <img className="empty__img" src={sad_smiley} alt="gif"/>
        <h2 className="empty__title">{title}</h2>
        <p className="empty__subtitle">{subtitle}</p>
        {children}
    </div>;
});