import {RefObject, useEffect, useRef, useState} from "react";

export function useOutboundClickHandler<T extends HTMLElement>(): [boolean, RefObject<T>, (active: boolean) => void] {
    const [isActive, setActive] = useState(false);
    const ref = useRef<T>(null);

    useEffect(() => {
        let isTracked = false;
        if (isActive) {
            isTracked = true;
            document.addEventListener('mousedown', handleMissClick);
        }
        return () => {
            if (isTracked) {
                isTracked = false;
                document.removeEventListener('mousedown', handleMissClick);
            }
        }
        // eslint-disable-next-line
    }, [isActive]);

    const handleMissClick = (event: MouseEvent) => {
        if (event?.composedPath) {
            let outbound = true;
            for (let element of event.composedPath()) {
                if (element === ref.current) {
                    outbound = false;
                    break;
                }
            }

            if (isActive && outbound) {
                setActive(false);
            }
        }
    }

    function set(active: boolean): void {
        setActive(active);
    }

    return [isActive, ref, set];
}