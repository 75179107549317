import React from "react";
import {observer} from "mobx-react-lite"

export const Empty = observer(() => {
    return <div>
                <h2 className="main__title main__header--indent">
                    <div className="main__title-text">
                        Страничка разработчика
                    </div>
                </h2> 
           </div>;
});
