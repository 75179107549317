import {action, makeObservable, observable} from "mobx";
import {DetailVerifiable} from "./Validation";
import {merge, cloneDeep} from "lodash"

export interface Translatable<INNER, OUTER> {
    encode(data: INNER): OUTER,
    decode(data: OUTER): INNER,
}

export abstract class Store<T> {
    public data: T;

    public constructor(readonly initial?: T) {
        this.data = initial ?? this.makeEmpty();

        makeObservable(this, {
            data: observable,
            storeData: action,
            clearStore: action,
            replaceData: action,
        });
    }

    protected abstract makeEmpty(): T;

    protected copyData(data: T): T {
        return JSON.parse(JSON.stringify(data));
    }

    protected mergeData(newer: Partial<T>): T {
        return Object.assign({}, this.data, newer);
    }

    public clearStore(): void {
        this.data = this.makeEmpty();
    }

    public storeData(data: Partial<T>): void {
        this.data = merge(this.data, data);
    }

    public replaceData(data: Partial<T>): void {
        Object.assign(this.data, data);
    }

    public getData(): T {
        return cloneDeep(this.data);
    }
}

export abstract class VerifiableStore<T> extends Store<T> implements DetailVerifiable.Typed<T> {
    protected result: DetailVerifiable.Result<T> = [true, {}] as DetailVerifiable.Result<T>;

    private fieldIsBlocked(field: keyof T): boolean {
        return this.validationBlacklist.some(blocked => field === blocked);
    }

    public clearField(field: keyof T): DetailVerifiable.PartialResult {
        return (this.result[1][field] = {isValid: true});
    }

    public spoilField(field: keyof T, errors?: DetailVerifiable.Detail[]) {
        this.result[0] = false;
        this.result[1][field] = {isValid: false, details: errors}
    }

    public validateField(field: keyof T): DetailVerifiable.PartialResult {
        if (this.fieldIsBlocked(field)) return {isValid: true};

        const partialResult = DetailVerifiable.verify(this.validationSchema[field], this.data[field]);

        this.result[0] = this.result[0] && partialResult.isValid;
        this.result[1][field] = partialResult;

        return partialResult;
    }

    public validate(): DetailVerifiable.Result<T> {
        const report = [true, {}] as DetailVerifiable.Result<T>;
        Object.entries<DetailVerifiable.ValidatorCondition[]>(this.validationSchema)
            .forEach(entry => {
                const field = entry[0] as keyof Partial<T>;
                const conditions = entry[1];

                if (!this.fieldIsBlocked(field)) {
                    const value = this.data[field];
                    const partialResult = DetailVerifiable.verify(conditions, value);

                    report[0] = report[0] && partialResult.isValid;
                    report[1][field] = partialResult;
                }
            });

        this.result = report;
        return report;
    }

    public get validationResult(): DetailVerifiable.Result<T> {
        return this.result;
    }

    public abstract get validationSchema(): DetailVerifiable.Schema<T>;
    public abstract get validationBlacklist(): (keyof T)[];
}
