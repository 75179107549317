import React from "react";
import {observer} from "mobx-react";

import LogoCompany from "../../../../image/Logo.svg";
import {Account} from "../../../component/account/Account";
// import {Notifications} from "../../../component/notifications/Notifications";
import {Profile} from "../../../component/profile/Profile";

export const Header = observer(() => {
    return <header className="header">
        <div className="page-header header__container">
            <a href="/">
                <img src={LogoCompany} alt="W-Target Logo"/>
            </a>
            <ul className="header__nav">
                <Account/>
                {/* <Notifications count={3}/> */}
                <Profile/>
            </ul>
        </div>
    </header>;
});


