import React, {useContext, useEffect} from "react";
import {MobXProviderContext} from "mobx-react";
import {TemplateStatusType} from "../../../utils/FacebookAPI";
import {withConditional} from "../../../hooks/ConditionalHOC";
import {PretemplateModel} from "../../stores/PretemplatesModel";
import {AmotemplateModel} from "../../stores/AmotemplatesModel";
import {Route, Switch, withRouter, useRouteMatch, Link, Redirect} from "react-router-dom";
import Loading from "../../component/loading/Loading";
import {PretemplateController} from "./PretemplateController";
import {AmotemplateController} from "./AmotemplateController";
import {useDisposableFlag} from "../../../hooks/DisposableHook";
import {i18n} from "../../../i18n/i18n";

export const Templates = withRouter(() => {
    const pretemplatesStore = useContext(MobXProviderContext).root.pretemplates as PretemplateModel;
    const amotemplatesStore = useContext(MobXProviderContext).root.amotemplates as AmotemplateModel;

    const [amotemplateIsLoaded, setAmotemplateLoadedFlag] = useDisposableFlag();
    const [pretemplateIsLoaded, setPretemplateLoadedFlag] = useDisposableFlag();

    useEffect(() => {
        document.title = 'W-Target | ' + i18n("title.templates");
    }, []);

    useEffect(() => {
        pretemplatesStore.downloadItems()
            .then(successfully => {
                // Загружаем промодерированные прешаблоны
                const approvedPretemplates = Array.from(pretemplatesStore.list.values())
                    .filter(pretemplate => pretemplate.status === TemplateStatusType.APPROVED);
                amotemplatesStore.setApprovedPretemplates(approvedPretemplates);

                setPretemplateLoadedFlag();
            });
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        amotemplatesStore.downloadItems()
            .then(successfully => {
                setAmotemplateLoadedFlag();
            })
            .catch(reason => {
                console.error("Unexpected error while loading a pretemplate list.");
            });
    // eslint-disable-next-line
    }, []);

    function TabsItemLink({ label, to, active }: any) {
        let match = useRouteMatch({
          path: to,
          exact: active
        });
        return <Link to={to} className={`tabs__item ${match ? "tabs__item--active" : ""}`}>{label}</Link>
    }

    return withConditional(
        <>
            <h2 className="main__title main__header--indent">
                <div className="main__title-text">
                    {i18n("title.templates")}
                </div>
            </h2>

            <div className="tabs">
                <TabsItemLink active={true} to="/templates/pretemplates:first" label={i18n("templates.tab.pre_templates")}/>
                <TabsItemLink to="/templates/amotemplates:first" label={i18n("templates.tab.amocrm_templates")}/>
            </div>
            <Switch>
                <Route exact path="/templates">
                    <Redirect to="/templates/pretemplates:first"/>
                </Route>
                <Route path="/templates/pretemplates:id">
                    <PretemplateController/>
                </Route>
                <Route path="/templates/amotemplates:id">
                    <AmotemplateController/>
                </Route>
            </Switch>
        </>,
        <Loading/>,
        () => pretemplateIsLoaded && amotemplateIsLoaded
    );
});