import {observer} from "mobx-react-lite";
import React, {useRef, useState} from "react";
import {buildClassName, varClass} from "../../../../utils/StringUtils";
import {InputCore, InputCoreProps} from "./Input";
import {i18n} from "../../../../i18n/i18n";

type TextareaCommonProps = InputCoreProps&{ inputClassName?: string };

export const TextareaWithParameter = observer((props: TextareaCommonProps) => {
    const {value = "", onChange, inputClassName, ...rest} = props;

    const [text, setText] = useState(value);
    const ref = useRef<HTMLTextAreaElement>(null);

    const ButtonInsertParameter = () => {
        return <button type="button" className="button form__group-div__textarea">+ {i18n("templates.pretemplates.textarea.marker")}</button>;
    };

    return <InputCore {...rest}
        isTextarea
        forwardedRef={ref}
        value={text}
        onChange={value => {
          setText(value);
          onChange && onChange(value);
        }}
        toolbar={<ButtonInsertParameter/>}
        wrapper={(inner, isValid) => {
          const inputClasses = buildClassName("form__textarea", inputClassName);
          const rootClasses = buildClassName("form__group",
              varClass(!isValid, "valid-error"),
              varClass(props.disabled, "form__group--disabled"),
              props.className
          );
          return <div className={rootClasses}>
              <div className={inputClasses}>{inner}</div>
          </div>;
        }}
    />
});

export const TextareaWithMarker = observer((props: TextareaCommonProps) => {
    const {value = "", onChange, inputClassName, ...rest} = props;

    const [inputValidator, setInputValidator] = useState<(value: string) => void>();
    const [text, setText] = useState(value);
    const ref = useRef<HTMLTextAreaElement>(null);

    function extractMarkers(): Array<string> {
        return text.match(/\{\{\d+\}\}/g) ?? [];
    }

    function insertMarker(markers: number, value: string, start: number, end: number): string {
        return `${value.substring(0, start)}{{${markers+1}}}${value.substring(end)}`;
    }

    function fixMarkerSequence(value: string) {
        let counter = 0;
        return value.replaceAll(/\{\{\d+\}\}/g, () => `{{${++counter}}}`);
    }

    function setCursorPosition(position: number) {
        setTimeout(() => ref.current?.setSelectionRange(position, position), 20);
    }

    function getSelectionRange() {
        return {start: ref.current?.selectionStart ?? 0, end: ref.current?.selectionEnd ?? 0};
    }

    const markers = extractMarkers().length;
    const ButtonInsertMarker = () => {
        return <button type="button" className="button form__group-div__textarea" disabled={props.disabled} onClick={e => {
            const element = ref.current;
            if (element) {
                element.focus();

                const range = getSelectionRange();
                if (range.start >= 0 && range.end >= 0) {
                    const newText = fixMarkerSequence(insertMarker(markers, text, range.start, range.end));

                    setText(newText);
                    if (inputValidator) inputValidator(newText); // (1) Также вызовет местный onChange

                    const markerLength = `{{${markers+1}}}`.length;
                    setCursorPosition(range.start + markerLength);
                }
            }

            e.preventDefault();
        }}>
            {(!props.disabled) ? `+ ${i18n("templates.pretemplates.textarea.marker")} {{${markers+1}}}` : "᠎"}
        </button>;
    };

    return <InputCore {...rest}
        validateSupplier={handler => setInputValidator(() => handler)}
        isTextarea
        forwardedRef={ref}
        value={text}
        onChange={value => {
            setText(value);
            onChange && onChange(value); // (1)
        }}
        toolbar={<ButtonInsertMarker/>}
        wrapper={(inner, isValid) => {
            const inputClasses = buildClassName("form__textarea", inputClassName);
            const rootClasses = buildClassName("form__group",
                varClass(!isValid, "valid-error"),
                varClass(props.disabled, "form__group--disabled"),
                props.className
            );
            return <div className={rootClasses}>
                <div className={inputClasses}>{inner}</div>
            </div>;
        }}
    />
});