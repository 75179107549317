import {VerifiableStore} from "../../../core/Store";
import {DetailVerifiable} from "../../../core/Validation";
import {i18n} from "../../../../../i18n/i18n";
import {BUTTON_MESSAGE_MAX_LENGTH} from "../../../../../utils/FacebookAPI";
import {reaction} from "mobx";
import {PretemplateExample} from "../PretemplateExampleFormStore";

export namespace Url {
    export type FormData = {
        caption: string,
        url: string,
    }

    export class FormStore extends VerifiableStore<FormData> {
        private exampleStore: PretemplateExample.FormStore;

        constructor(exampleStore: PretemplateExample.FormStore) {
            super();

            this.exampleStore = exampleStore;

            reaction(() => this.data.url, url => {
                const markers = url?.match(/\{\{\d+\}\}/g) ?? [];
                if (markers.length === 1 && markers[0] === "{{1}}") {
                    this.exampleStore.replaceData({buttonUrl: this.exampleStore.data.buttonUrl ?? ""});
                } else {
                    delete this.exampleStore.data.buttonUrl;
                }
            });
        }

        protected makeEmpty(): FormData {
            return {
                caption: "",
                url: "",
            };
        }

        public get validationBlacklist(): (keyof FormData)[] {
            return [];
        }

        get validationSchema(): DetailVerifiable.Schema<FormData> {
            return {
                caption: [
                    [(value: string) => !value.length, i18n("templates.pretemplates.form.error.button.empty")],
                    [(value: string) => value.length > BUTTON_MESSAGE_MAX_LENGTH, i18n("templates.pretemplates.form.error.button.too_long")],
                ],
                url: [
                    [
                        (value: string) =>
                            /^(http|https):\/\/([a-zа-я\d._-]{2,256})\.([a-zа-я]{2,63})($|(\/|\?)[a-zа-яA-ZА-Я\d@:%-_+.~#?&/="'{}]*$)/gm.test(value) === false,
                        i18n("templates.pretemplates.form.error.incorrect_url")
                    ]
                ],
            };
        }

        get validationResult(): DetailVerifiable.Result<FormData> {
            return this.result;
        }
    }
}