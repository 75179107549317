import {ModelItem} from "./types/AmotemplateDataModel";
import {loadAmotemplates, updateAmotemplate, uploadAmotemplate, uploadFileToCloud} from "./NetworkAmotemplateWorker";
import {
    decodePacketResponseListData,
    encodePacketRequestCreate,
    encodePacketRequestUpdate
} from "./translator/AmotemplateTranslator";
import {ModelListItem} from "./types/PretemplateDataModel";
import {Model} from "./core/Model";

export class AmotemplateModel extends Model<number, ModelItem> {
    public approvedPretemplates = new Map<string, ModelListItem>();

    private cachedSortedList: ModelItem[]|undefined;

    public constructor() {
        super();
    }

    setApprovedPretemplates(approvapprovedPretemplates: ModelListItem[]) {
        this.approvedPretemplates = new Map(
            approvapprovedPretemplates.map(pretemplate => [pretemplate.code, pretemplate])
        );
    }

    get lastUpdatedTemplateId(): number|null {
        if (this.list.size === 0) return null;

        const found = Array.from(this.list)
            .reduce((left, right) => (left[1].updated_at > right[1].updated_at) ? left : right);

        return found[1].template_id;
    }

    get lastCreatedTemplateId(): number|null {
        if (this.list.size === 0) return null;

        const found = Array.from(this.list)
            .reduce((left, right) => (left[1].created_at > right[1].created_at) ? left : right);

        return found[1].template_id;
    }

    get asSortedByCreatedDate(): ModelItem[] {
        const sortedList = this.cachedSortedList ?? Array.from(this.list.values())
            .sort((left, right) => {
                if (left.created_at < right.created_at) return 1;
                else if (left.created_at > right.created_at) return -1;

                return 0;
            });

        if (!this.cachedSortedList) this.cachedSortedList = sortedList;

        return sortedList;
    }

    getFirstKey(): number|null {
        return this.lastCreatedTemplateId;
    }

    getIndex(id: number): number|null {
        const index = Array.from(this.list.values())
            .findIndex(template => template.template_id === id);

        return (index >= 0) ? index : null;
    }

    hasKey(id: number): boolean {
        return this.list.has(id);
    }

    getValue(id: number): ModelItem|null {
        const template = this.list.get(id);

        return template ?? null;
    }

    downloadItems(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            loadAmotemplates()
                .then(response => {
                    const list = response.length ? response
                        .map<[number, ModelItem]>(
                            item => {
                                const decoded = decodePacketResponseListData(item);
                                return [parseInt(item.id), decoded];
                            }
                        ) : [];

                    this.create(list);
                    this.cachedSortedList = undefined;

                    resolve(true);
                })
                .catch(reason => {
                    console.error("Unexpected error while downloading pretemplates list.");
                    reject(reason);
                });
        });
    }

    updateItem(template: ModelItem): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const oldTemplate = this.list.get(template.template_id);
            if (oldTemplate) {
                const payload = template.payload;
                if (payload && payload.media) {
                    uploadFileToCloud(payload.media)
                        .then(result => {
                            payload.header = result;
                            updateAmotemplate(encodePacketRequestUpdate(template))
                                .then(response => {
                                    this.set(template.template_id, template);
                                    this.cachedSortedList = undefined;
                                    resolve(true);
                                })
                                .catch(reason => {
                                    console.error("Unexpected error while updating template data. Details:", reason);
                                    reject(reason);
                                });
                        })
                        .catch(reason => {
                            console.error("Unexpected error while uploading media. Details:", reason);
                            reject(reason);
                        })
                } else {
                    updateAmotemplate(encodePacketRequestUpdate(template))
                        .then(response => {
                            this.set(template.template_id, template);
                            this.cachedSortedList = undefined;
                            resolve(true);
                        })
                        .catch(reason => {
                            console.error("Unexpected error while updating template data. Details:", reason);
                            reject(reason);
                        });
                }
            }
        });
    }

    uploadItem(template: ModelItem): Promise<number> {
        return new Promise((resolve, reject) => {
            // Присваиваем самый большой бэк-параметр sort, отвечающий за сортировку записей
            if (this.list.size) {
                template.sort = Array.from(this.list.values())
                    .reduce((left, right) => (left.sort > right.sort) ? left : right)
                    .sort + 1;
            } else {
                template.sort = 1;
            }

            const payload = template.payload;
            if (payload && payload.media) {
                uploadFileToCloud(payload.media)
                    .then(result => {
                        payload.header = result;
                        uploadAmotemplate(encodePacketRequestCreate(template))
                            .then(response => {
                                const newId = parseInt(response.id);
                                template.template_id = newId;
                                this.set(newId, template);
                                this.cachedSortedList = undefined;
                                resolve(newId);
                            })
                            .catch(reason => {
                                console.error("Unexpected error while uploading template data. Details:", reason);
                                reject(reason);
                            });
                    })
                    .catch(reason => {
                        console.error("Unexpected error while uploading media. Details:", reason);
                        reject(reason);
                    })
            } else {
                uploadAmotemplate(encodePacketRequestCreate(template))
                    .then(response => {
                        const newId = parseInt(response.id);
                        template.template_id = newId;
                        this.set(newId, template);
                        this.cachedSortedList = undefined;
                        resolve(newId);
                    })
                    .catch(reason => {
                        console.error("Unexpected error while uploading template data. Details:", reason);
                        reject(reason);
                    });
            }
        });
    }

    deleteItem(id: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const ModelItem = this.list.get(id);
            if (ModelItem) {
                ModelItem.isActive = 0;
                this.updateItem(ModelItem)
                    .then(result => {
                        this.delete(id);
                        this.cachedSortedList = undefined;
                        resolve(true);
                    })
                    .catch(reason => {
                        reject(reason);
                    })
            } else {
                reject("Cannot delete this ModelItem with id = " + id);
            }
        });
    }

    downloadItem(key: number): Promise<boolean> {
        return Promise.reject("Unsupported operation");
    }
}
