import { makeAutoObservable } from "mobx";
import { AxiosResponse } from 'axios';
import authorizedAxios  from '../http/authorizedAxios';
import parsePhoneNumber from 'libphonenumber-js';
import {UserProfile, UserData} from './types/UserProfile';

export const formatPhone = (accountId: string) => {
    const phoneNumber = parsePhoneNumber(accountId, 'RU');
    let phone = phoneNumber && phoneNumber.formatInternational();
    
    if (phone && typeof phone === 'string') {
        const [extCode, intCode, ...digits]:any = phone.split(' ');
        if (extCode && intCode && digits) {
            return `${extCode} ${intCode} ${digits.join('-')}`;
        }
    } else {
        return accountId;
    }
    
    return phone;
};

export class AppStore {
    accounts: Array<string>|null = null;
    accountName: string = '';
    selectedAccountId: string = '';
    selectedAccountPhone: string = '';
    selectedAccountPhoto: string|null = null;
    userProfile: UserProfile|null = null;

    constructor() {
        makeAutoObservable(this);
    }

    set setSelectedAccountPhoto (photo: string|null) {
        this.selectedAccountPhoto = photo;
    }

    set selectedAccount(accountId: string) {
        if (accountId) {
            this.selectedAccountId = accountId;
            const formattedPhone = formatPhone(`${accountId[0] !== '+' ? '+' : ''}${accountId}`);
            if (formattedPhone) {
                this.selectedAccountPhone = formattedPhone;
            }
            authorizedAxios.get(`/numbers.php?action=photo&account_id=${accountId}`).then((result: AxiosResponse<string>) => {
                this.setSelectedAccountPhoto = result.data;
            });
        }
    }

    set setUserProfile(userData: UserData) {
        let initials = '';
        if (userData.name.length) {
            initials = userData.name.split(' ').map((namePart, index) => index < 2 ? namePart.substr(0, 1) : '').join('').toUpperCase();
        }
        this.userProfile = {
            ...userData,
            initials
        };
    }

    async loadAccounts(): Promise<void> {
        if (!this.selectedAccountId) {
            const response = await authorizedAxios.get('/accounts.php');
            if (response.data) {
                this.accounts = response.data.accounts;
                this.accountName = response.data.name;
                if (this.accounts) {
                    this.selectedAccount = this.accounts[0];
                }
            }
        }
    }

    async loadUserProfile(): Promise<void> {
        if (!this.userProfile) {
            const response = await authorizedAxios.get<UserData>('/user_profile.php');
            if (response.data) {
                this.setUserProfile = response.data;
            }
        }
    }

    unsetData(): void {
        this.accounts = null;
        this.accountName = '';
        this.selectedAccountId = '';
        this.selectedAccountPhone = '';
        this.selectedAccountPhoto = '';
        this.userProfile = null;
    }
}