import {observer} from "mobx-react-lite";
import React, {useContext, useState} from "react";
import {
    ButtonTypes,
    HeaderTypes,
    SupportedLanguage,
    NotificationCategory, templateCategoriesInformation
} from "../../../../utils/FacebookAPI";
import {Select, SelectItemProps} from "../local_component/Select";
import {i18n} from "../../../../i18n/i18n";

import flagRU from "../../../../image/flags/RU.png";
import flagUS from "../../../../image/flags/US.png";
import flagDE from "../../../../image/flags/DE.png";
import flagFR from "../../../../image/flags/FR.png";
import flagPT from "../../../../image/flags/PT.png";
import flagES from "../../../../image/flags/ES.png";
import flagIT from "../../../../image/flags/IT.png";
import flagID from "../../../../image/flags/ID.png";
import flagFIL from "../../../../image/flags/FIL.png";
import flagHI from "../../../../image/flags/HI.png";
import flagKK from "../../../../image/flags/KK.png";

import {Input, InputWithMarker} from "../local_component/Input";
import {TemplateStatus} from "../local_component/TemplateStatus";
import {TextareaWithMarker} from "../local_component/Textarea";
import {ButtonsForm} from "./ButtonsForm";
import {MobXProviderContext} from "mobx-react";
import {buildClassName, varClass} from "../../../../utils/StringUtils";
import {Pretemplate, PretemplateStatus} from "../../../stores/types/PretemplateDataModel";
import {PretemplateExampleForm} from "./PretemplateExampleForm";
import {Pretemplates} from "../../../stores/form/pretemplate/PretemplateFormStore";
import {PhonePreview} from "../../../component/phone_preview/PhonePreview";
import {AppStore} from '../../../stores/AppStore';
import {keys, pickBy} from 'lodash';

export type PretemplateFormProps = {
    editable?: boolean,
    onSave?: (pretemplate: Pretemplate, onError?: (code?: string) => void) => void,
    onCancel?: () => void,
    onDelete?: () => void,
    PhonePreviewState: string,
    setPhonePreviewState?: (s: string) => void,
    status: PretemplateStatus,
    formStore: Pretemplates.FormStore,
    formState: Pretemplates.FormState,
}

export const PretemplateForm = ((props: PretemplateFormProps) => {
    const {formStore, formState, status, editable = true, onSave, onCancel} = props;

    const appStore = useContext(MobXProviderContext).root.app as AppStore;

    const [, redraw] = useState(false); //TODO: Временно, для принудительной перерисовки формы
    const [, setValidation] = useState(formStore.validationResult);

    function markDirty() {
        if (!formState.data.isModified) {
            if (formState.data.mode === "idle") {
                formState.storeData({mode: "modifying", isModified: true});
            } else if (formState.data.mode === "making") {
                formState.storeData({isModified: true});
            }
        }
    }

    /* Формирователи */
    function makeNotificationCategoriesItems(): SelectItemProps[] {
        const items = keys(
            (formState.data.mode === "making")
                ? pickBy(templateCategoriesInformation, ({deprecated, supported}) => supported && !deprecated)
                : templateCategoriesInformation
        );

        return items.map((name) => ({
            id: name,
            jsx: <>{i18n("whatsapp.notification_category." + name.toLowerCase())}</>,
            onOpted: () => formStore.storeData({category: name as NotificationCategory})
        }));
    }

    function makeLanguageItems(): SelectItemProps[] {
        const languagesInformation: [SupportedLanguage, string, string][] = [
            [SupportedLanguage.RUSSIAN, "whatsapp.language.ru_RU", flagRU],
            [SupportedLanguage.ENGLISH_US, "whatsapp.language.en_US", flagUS],
            [SupportedLanguage.GERMAN, "whatsapp.language.de_DE", flagDE],
            [SupportedLanguage.FRENCH, "whatsapp.language.fr_FR", flagFR],
            [SupportedLanguage.PORTUGUESE, "whatsapp.language.pt_PT", flagPT],
            [SupportedLanguage.SPANISH, "whatsapp.language.es_ES", flagES],
            [SupportedLanguage.ITALIAN, "whatsapp.language.it_IT", flagIT],
            [SupportedLanguage.INDONESIAN, "whatsapp.language.id_ID", flagID],
            [SupportedLanguage.FILIPINO, "whatsapp.language.fil_FIL", flagFIL],
            [SupportedLanguage.HINDI, "whatsapp.language.hi_HI", flagHI],
            [SupportedLanguage.KAZAKH, "whatsapp.language.kk_KK", flagKK],
        ];

        return languagesInformation.map(([language, translationKey, languageIcon]) => ({
                id: SupportedLanguage[language],
                jsx: <div className="form__select-icon">
                    <img src={languageIcon} alt="flag"/>
                    <span>{i18n(translationKey)}</span>
                </div>,
                onOpted: () => formStore.storeData({language: language})
            })
        );
    }

    function makePretemplateHeaderItems(): SelectItemProps[] {
        const items = new Array<SelectItemProps>();
        for (let index = 0; index < Object.values(HeaderTypes).length/2; ++index) {
            const name = HeaderTypes[index];
            const header = HeaderTypes[name as keyof typeof HeaderTypes];
            items.push({
                id: name,
                jsx: <>{i18n("whatsapp.template.header_type." + name.toLowerCase())}</>,
                onOpted: () => {
                    switch (header) {
                        case HeaderTypes.TEXT:
                            formStore.replaceData({header: {type: header, data: ""}})
                            break;
                        default:
                            formStore.replaceData({header: {type: header}})
                            break;
                    }
                    redraw(dummy => !dummy)
                }
            });
        }

        return items;
    }

    const PreviewBlock = observer(() => {
        function detectButtonType(type: ButtonTypes): string|undefined {
            const map: {[key: number]: string|undefined} = {
                [ButtonTypes.PHONE_NUMBER]: "PHONE_NUMBER",
                [ButtonTypes.URL]: "URL",
            };

            return map[type];
        }

        let headerMappedType = undefined;
        const headerType = formStore.data.header.type;
        switch (headerType) {
            case HeaderTypes.IMAGE: headerMappedType = "image"; break;
            case HeaderTypes.VIDEO: headerMappedType = "video"; break;
            case HeaderTypes.DOCUMENT: headerMappedType = "pdf"; break;
        }

        const focusedInput = formStore.exampleStore.data._focusedInput;
        return <PhonePreview {...{
            phoneMode: props.PhonePreviewState,
            phoneModeState: props.setPhonePreviewState,
            headerImg: appStore.selectedAccountPhoto,
            fileType: headerMappedType,
            headerTitle: appStore.selectedAccountPhone,
            messageHeader: formStore.data.header.data,
            messageText: formStore.data.body,
            messageFooter: formStore.data.footer,
            messageBtns: formStore.buttonsStore?.data
                .filter(button => button.substore.data.caption) // Не передаем кнопку, если в ней нет текста
                .map(button => ({title: button.substore.data.caption, type: detectButtonType(button.type)})),
            messageTextMarkers: formStore.exampleStore.data.body,
            messageHeaderMarker: formStore.exampleStore.data.header,
            markerReplacementStrategy: (vacancy, marker, index) => index === focusedInput,
        }}/>
    });

    const OptionsBlock = (() => {
        const TemplateName = observer(() => {
            return <div className="form__block">
                <label className="form__label" htmlFor="form-pretemplate-name">{i18n("templates.pretemplates.form.name.label")}</label>
                <Input id="form-pretemplate-name"
                       disabled={formState.data.processing === "processing" || !editable}
                       value={formStore.data.name}
                       onChange={value => {
                           formStore.storeData({name: value})
                           markDirty();
                       }}
                       coldVerified={formStore.validationResult[1].name}
                />
            </div>;
        });

        const TemplateId = observer(() => {
            const canEdit = editable && formState.data.mode === "making" && formState.data.processing === "idle";

            return <div className="form__block">
                <label className="form__label" htmlFor="form-pretemplate-code">{i18n("templates.pretemplates.form.template_code.label")}</label>
                <Input id="form-pretemplate-code"
                       disabled={!canEdit}
                       value={formStore.data.code}
                       onChange={value => {
                           formStore.storeData({code: value})
                           markDirty();
                       }}
                       hotVerifier={() => formStore.validateField("code")}
                       coldVerified={formStore.validationResult[1].code}
                />
            </div>;
        });

        const TemplateCategory = observer(() => {
            const canEdit = editable && formState.data.mode === "making" && formState.data.processing === "idle";

            return <div className="col grids-7">
                <label className="form__label">{i18n("templates.pretemplates.form.category.label")}</label>
                <Select
                    name="form-pretemplate-category"
                    disabled={!canEdit}
                    defaultOptedId={formStore.data.category}
                    items={makeNotificationCategoriesItems()}
                />
            </div>;
        });

        const TemplateLanguage = observer(() => {
            const canEdit = editable && formState.data.mode === "making" && formState.data.processing === "idle";

            return <div className="col grids-5">
                <label className="form__label">{i18n("templates.pretemplates.form.language.label")}</label>
                <Select
                    name="form-pretemplate-lang"
                    disabled={!canEdit}
                    defaultOptedId={SupportedLanguage[formStore.data.language]}
                    items={makeLanguageItems()}
                />
            </div>;
        });

        const Status = (() => {
            return (formState.data.mode !== "making") ? <div className="form__block">
                <label className="form__label">{i18n("templates.pretemplates.form.status.label")}</label>
                <TemplateStatus status={status}/>
            </div> : <></>;
        });

        return <>
            <TemplateName/>
            <TemplateId/>
            <div className="row form__block">
                <TemplateCategory/>
                <TemplateLanguage/>
            </div>
            <Status/>
        </>;
    });

    const StructureBlock = (() => {
        const Header = observer(() => {
            const isTextHeader = formStore.data.header.type === HeaderTypes.TEXT;
            const isNoneHeader = formStore.data.header.type === HeaderTypes.NONE;
            const isMakingMode = formState.data.mode === "making";
            const isIdleProcessing = formState.data.processing === "idle";

            const canEdit = editable && isMakingMode && isIdleProcessing;
            const isVisible = isMakingMode || (!isNoneHeader && isTextHeader);

            function SelectWrapper(props: {disabled?: boolean, visible: boolean}) {
                return (props.visible) ? <Select
                    className="mb8"
                    name="form-pretemplate-header-type-select"
                    disabled={props.disabled}
                    defaultOptedId={HeaderTypes[formStore.data.header?.type]}
                    items={makePretemplateHeaderItems()}
                /> : <></>;
            }

            function TextField(props: {disabled?: boolean, visible: boolean, editable: boolean}) {
                return (props.visible) ? <InputWithMarker
                    id="form-pretemplate-header"
                    disabled={props.disabled}
                    asText={props.editable}
                    value={formStore.data.header?.data}
                    onChange={value => {
                        formStore.storeData({header: {type: formStore.data.header?.type ?? HeaderTypes.TEXT, data: value}})
                        markDirty();
                    }}
                    hotVerifier={() => formStore.validateField("header")}
                    coldVerified={formStore.validationResult[1].header}
                /> : <></>;
            }

            return (isVisible) ? <div className="form__block">
                <label className="form__label" htmlFor="form-pretemplate-header">{i18n("templates.pretemplates.form.header.label")}</label>
                <SelectWrapper
                    visible={formState.data.mode === "making"}
                    disabled={!canEdit}
                />
                <TextField
                    visible={formStore.data.header?.type === HeaderTypes.TEXT}
                    disabled={!canEdit}
                    editable={formState.data.mode !== "making"}
                />
            </div> : <></>;
        });

        const Body = observer(() => {
            const canEdit = editable && formState.data.mode === "making" && formState.data.processing === "idle";

            return <div className="form__block">
                <label className="form__label" htmlFor="form-pretemplate-body">{i18n("templates.pretemplates.form.message.label")}</label>
                <TextareaWithMarker
                    id="form-pretemplate-body"
                    inputClassName="form__textarea--three_line"
                    disabled={!canEdit}
                    asText={formState.data.mode !== "making"}
                    value={formStore.data.body}
                    data-autoresize
                    onChange={value => {
                        formStore.storeData({body: value})
                        markDirty();
                    }}
                    hotVerifier={() => formStore.validateField("body")}
                    coldVerified={formStore.validationResult[1].body}
                />
            </div>;
        });

        const Footer = observer(() => {
            const canEdit = editable && formState.data.mode === "making" && formState.data.processing === "idle";
            const isVisible = formStore.data.footer !== "" || formState.data.mode === "making";

            return (isVisible) ? <div className="form__block">
                <label className="form__label" htmlFor="form-pretemplate-signature">{i18n("templates.pretemplates.form.signature.label")}</label>
                <Input id="form-pretemplate-signature"
                       value={formStore.data.footer}
                       disabled={!canEdit}
                       asText={formState.data.mode !== "making"}
                       onChange={value => {
                           formStore.storeData({footer: value})
                           markDirty();
                       }}
                       hotVerifier={() => formStore.validateField("footer")}
                       coldVerified={formStore.validationResult[1].footer}
                />
            </div> : <></>;
        });

        const Buttons = observer(() => {
            const canEdit = editable && formState.data.mode === "making" && formState.data.processing === "idle";
            const isVisible = formStore.buttonsStore.data.length > 0 || formState.data.mode === "making";

            return <div className="form__block">
                <ButtonsForm
                    store={formStore.buttonsStore}
                    exampleStore={formStore.exampleStore}
                    editable={canEdit}
                    visible={isVisible}
                    creationMode={formState.data.mode === "making"}
                    onChange={() => {
                        markDirty();
                    }}
                />
            </div>;
        });

        const Caption = (() => {
            return <div className="form__block">
                <p className="form__block-title">{i18n("templates.pretemplates.form.structure.label")}</p>
            </div>;
        });

        return <>
            <Caption/>
            <Header/>
            <Body/>
            <Footer/>
            <Buttons/>
        </>;
    });

    const ExampleBlock = observer(() => {
        return <PretemplateExampleForm
            store={formStore.exampleStore}
            hasTextHeader={formStore.data.header.type === HeaderTypes.TEXT}
            creationMode={formState.data.mode === "making"}
            editable={formState.data.processing === "idle"}
        />;
    });

    const ControlsBlock = observer(() => {
        function SaveButton(props: {disabled?: boolean, visible: boolean, processing: boolean, processingFailed: boolean, making: boolean}) {
            return (props.visible) ? <button
                disabled={props.disabled}
                type="button"
                className={buildClassName(
                    "button button--save",
                    varClass(props.processing,
                        varClass(props.processingFailed, "button-folded-hands shake", "button-loader"),
                        varClass(props.making, "button-folded-hands", "button-floppy-disk")
                    )
                )}
                onClick={e => {
                    const validation = formStore.validate();
                    setValidation(validation);

                    if (validation[0] && onSave) {
                        onSave(formStore.makeModel(), code => {
                            if (code === "emoji") {
                                formStore.spoilField("footer", [i18n("templates.pretemplates.form.error.emojis_not_allowed")])
                                redraw(dummy => !dummy);
                            }
                        });
                    }
                }}
            >
                <span className="button__text">
                    {props.making ? i18n("button.send") : i18n("button.save")}
                </span>
            </button> : <></>;
        }

        function CancelButton(props: {disabled?: boolean, visible: boolean}) {
            return (props.visible) ? <button
                type="button"
                disabled={props.disabled}
                className="button button--cancellation ml14"
                onClick={e => {
                    if (onCancel) onCancel();
                }}
            >
                {i18n("button.cancel")}
            </button> : <></>;
        }

        function Hint(props: {visible: boolean}) {
            return (props.visible) ? <div className="form__block-subtext">
               {i18n("templates.pretemplates.add_template.form.subtext.before")} <br/> {i18n("templates.pretemplates.add_template.form.subtext.after")}
            </div> : <></>;
        }

        return <>
            <div className="form__block">
                <div className="flex">
                    <SaveButton
                        visible={formState.data.mode !== "idle"}
                        disabled={!formState.data.isModified || formState.data.processing !== "idle" || !editable}
                        processing={formState.data.processing !== "idle"}
                        processingFailed={formState.data.processing === "processing-failed"}
                        making={formState.data.mode === "making"}
                    />
                    <CancelButton
                        visible={formState.data.isModified}
                        disabled={!formState.data.isModified || formState.data.processing !== "idle"}
                    />
                </div>
                <Hint visible={formState.data.mode === "making" && editable}/>
            </div>
        </>;
    });

    return <>
        <form className={`form ${(!props.editable) ? "blur" : ""}`}>
            <OptionsBlock/>
            <StructureBlock/>
            <ExampleBlock/>
            <ControlsBlock/>
        </form>
        <PreviewBlock/>
    </>;
});