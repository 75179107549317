import {useState, useContext} from "react";
import {MobXProviderContext, observer} from "mobx-react";
import authorizedAxios from '../../../http/authorizedAxios';
import {AppStore} from "../../../stores/AppStore";
import {NumbersStore} from "../store/NumbersStore";

import {Input} from "./Input";
import {Select, SelectItem} from "./Select";
import {TextareaWithMarker} from "./Textarea";
import {buildClassName} from "../../../../utils/StringUtils";
import {i18n} from "../../../../i18n/i18n";
import {
    NUMBERS_EMAIL_MAX_LENGTH,
    NUMBERS_SHORT_DESCRIPTION_MAX_LENGTH,
    NUMBERS_EXTENDED_DESCRIPTION_MAX_LENGTH,
    NUMBERS_ADDRESS_MAX_LENGTH,
    NUMBERS_WEBSITE_MAX_LENGTH
} from "../../../../utils/FacebookAPI";

// import Verified from '../../../../image/Verified.svg';
import iconEmpty from '../../../../image/empty.svg';

export const NumbersForm = observer(() => {
    const appStore = useContext(MobXProviderContext).root.app as AppStore;
    const numbersStore = useContext(MobXProviderContext).root.numbers as NumbersStore;

    const [form, setForm] = useState({...numbersStore.data});

    const [photoProfile, setPhotoProfile] = useState({
        file: '',
        size: 0,
        type: false,
    });

    const [isValid, changeValidation] = useState(true);
    const [validation, setValidation] = useState<{[key: string]: boolean}>({
        photo: true,
        about: true,
        description: true,
        address: true,
        websites: true,
        email: true
    });

    const [isCancellation, changeCancellation] = useState(false);
    const [cancellation, setCancellation] = useState<{[key: string]: boolean}>({
        photo: false,
        about: false,
        description: false,
        address: false,
        vertical: false,
        websites: false,
        email: false
    });

    function revalidate(input: string, value: boolean): void {
        let validState = {...validation};
        Object.entries(validState).forEach(entry => {
            if (entry[0] === input) {
                if (value) {
                    validState[input] = true
                }else {
                    validState[input] = false
                }
            }
        });
        setValidation({...validState});
        if (Object.values(validState).every(elem => elem === true)) {
            changeValidation(true)
        }else{
            changeValidation(false)
        }
    }

    const categories = [
        {id: 0, category: 'Automotive', i18n_key: i18n("whatsapp.number_category.automotive")},
        {id: 1, category: 'Beauty, Spa and Salon', i18n_key: i18n("whatsapp.number_category.beauty_spa_and_salon")},
        {id: 2, category: 'Clothing and Apparel', i18n_key: i18n("whatsapp.number_category.clothes")},
        {id: 3, category: 'Education', i18n_key: i18n("whatsapp.number_category.education")},
        {id: 4, category: 'Entertainment', i18n_key: i18n("whatsapp.number_category.entertainment")},
        {id: 5, category: 'Event Planning and Service', i18n_key: i18n("whatsapp.number_category.event_planning_and_service")},
        {id: 6, category: 'Finance and Banking', i18n_key: i18n("whatsapp.number_category.finance_and_banking")},
        {id: 7, category: 'Food and Grocery', i18n_key: i18n("whatsapp.number_category.food_and_grocery")},
        {id: 8, category: 'Public Service', i18n_key: i18n("whatsapp.number_category.public_service")},
        {id: 9, category: 'Hotel and Lodging', i18n_key: i18n("whatsapp.number_category.hotel_and_lodging")},
        {id: 10, category: 'Medical and Health', i18n_key: i18n("whatsapp.number_category.medical_and_health")},
        {id: 11, category: 'Non-profit', i18n_key: i18n("whatsapp.number_category.non_profit")},
        {id: 12, category: 'Professional Services', i18n_key: i18n("whatsapp.number_category.professional_services")},
        {id: 13, category: 'Shopping and Retail', i18n_key: i18n("whatsapp.number_category.shopping_and_retail")},
        {id: 14, category: 'Travel and Transportation', i18n_key: i18n("whatsapp.number_category.travel_and_transportation")},
        {id: 15, category: 'Restaurant', i18n_key: i18n("whatsapp.number_category.restaurant")},
        {id: 16, category: 'Other', i18n_key: i18n("whatsapp.number_category.other")},
        {id: 17, category: "", i18n_key: 'Не выбрано'},
    ];

    function profilePhoto(e:any) {
        const file = e.target.files.item(0);
        if(!file) return;
        let fileType = false;

        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            setPhotoProfile(state => ({...state, size: 0, type: true}));
            e.target.value = '';
            return;
        }

        if (file.size > 5242880) {
            setPhotoProfile(state => ({...state, size: file.size, type: false}));
            e.target.value = '';
            return;
        }
        try {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function () {
                let photo: any = reader.result;
                setForm(state => ({...state, photo: photo}));
                numbersStore.setPreviewPhoto(photo);
                checkInputCancellation('photo', numbersStore.data.photo, photo);
                numbersStore.setError();
                setPhotoProfile({
                    file: file,
                    size: file.size,
                    type: fileType
                })
            }
        } catch (error) {
            console.log(error);
        }
        e.target.value = '';
    }

    function checkInputCancellation(field: string, value1: any, value2: any) {
        let cancelState = {...cancellation};
        if (value1 === value2) {
            cancelState[field] = false;
        } else {
            cancelState[field] = true;
        }
        setCancellation({...cancelState});
        if (Object.values(cancelState).every(elem => elem === false)) {
            changeCancellation(false)
        }else{
            changeCancellation(true)
        }
    }

    function onCancel() {
        numbersStore.setError();
        setForm({...numbersStore.data});
        setPhotoProfile({file: '', size: 0, type: false});
        numbersStore.setPreviewPhoto();

        changeCancellation(false);
        setCancellation({
            photo: false,
            about: false,
            description: false,
            address: false,
            vertical: false,
            websites: false,
            email: false
        });

        changeValidation(true);
        setValidation({
            photo: true,
            about: true,
            description: true,
            address: true,
            websites: true,
            email: true
        });
    }

    function onUpdate() {
        numbersStore.setBusy(true);
        let formData = new FormData();
            formData.append('account_id', appStore.selectedAccountId);
            if (form.about !== numbersStore.data.about) {
                formData.append('about', form.about);
            }
            if (form.address !== numbersStore.data.address) {
                formData.append('address', form.address);
            }
            if (form.email !== numbersStore.data.email) {
                formData.append('email', form.email);
            }
            if (form.description !== numbersStore.data.description) {
                formData.append('description', form.description);
            }
            if (form.vertical !== numbersStore.data.vertical) {
                formData.append('vertical', form.vertical);
            }
            if (form.websites[0] !== numbersStore.data.websites[0]) {
                formData.append('websites', form.websites[0]);
            }
            if (photoProfile.file) {
                formData.append('file', photoProfile.file);
            }

        authorizedAxios.post('/numbers.php', formData)
        .then(() => {
            numbersStore.setStore(form);
            changeCancellation(false);
            setCancellation({
                photo: false,
                about: false,
                description: false,
                address: false,
                vertical: false,
                websites: false,
                email: false
            });
            numbersStore.setShake(false);
        }).catch(err => {
            numbersStore.setShake(true);
            numbersStore.setError({status: true, text: err.response.data});
        }).finally(() => {
            numbersStore.setBusy(false);
        });
    }

    function checkInputLength(value: string, limit: number): boolean {
        const length = value.length;
        if (length > limit) {
            return false;
        } else {
            return true;
        }
    }

    function showErrorRequest(err:any, type: string, text:string) {
        return err.error_type === type && <div className="valid-feedback">{text}</div>
    }

    return <div>
        <form className="form">
            {/* <div className="form__block">
                <label className="form__label" htmlFor="name">Название</label>
                <div className="form__group form__group--disabled">
                    <input className="form__input" type="text" disabled id="name" value="" placeholder="Введите название"/>
                    <span className="form__group-div">
                        <img src={Verified} alt="Verified"/>
                    </span>
                </div>
            </div> */}

            <div className="form__block">
                <div className="form__label">{i18n("numbers.form.profile_photo.label")}</div>
                <div className="form__profile-file valid-error">
                    <img className="form__profile-file__img" src={form.photo ?? iconEmpty} onError={(e)=>{e.currentTarget.onerror = null; e.currentTarget.src = iconEmpty}} alt="numberPhoto"/>
                    <label className="button button--border">
                        {i18n("button.upload")}
                        <input type="file" accept="image/jpeg,image/png" onChange={profilePhoto}/>
                    </label>
                </div>
                {photoProfile.size > 5242880 ? <div className="valid-feedback">{i18n("numbers.form.error.photo_size")}</div>: ''}
                {photoProfile.type ? <div className="valid-feedback">{i18n("numbers.form.error.photo_type")}</div>: ''}
                {numbersStore.error.status ? showErrorRequest(numbersStore.error.text, 'image', i18n("numbers.form.error.photo_status")): ''}
            </div>
            <div className="form__block">
                <label className="form__label" htmlFor="short-description">{i18n("numbers.form.short_description.label")}</label>
                <Input type="text"
                       id="short-description"
                       placeholder={i18n("numbers.form.short_description.placeholder")}
                       value={form.about}
                       conditions={[
                            [value => value !== "", i18n("numbers.form.error.short_description.should_not_be_empty")],
                            [value => checkInputLength(value, NUMBERS_SHORT_DESCRIPTION_MAX_LENGTH), i18n("numbers.form.error.short_description.too_long")]
                       ]}
                       handleChange={value => {
                           setForm(values => ({...values, about: value}));
                           checkInputCancellation('about', numbersStore.data.about, value)
                       }}
                       onValidate={valid => revalidate('about', valid)}/>
            </div>
            <div className="form__block">
                <label className="form__label" htmlFor="extended-description">{i18n("numbers.form.extended_description.label")}</label>
                <TextareaWithMarker placeholder={i18n("numbers.form.extended_description.placeholder")} id="extended-description"
                    inputClassName="form__textarea--three_line"
                    value={form.description}
                    conditions={[
                        [value => checkInputLength(value, NUMBERS_EXTENDED_DESCRIPTION_MAX_LENGTH), i18n("numbers.form.error.extended_description.too_long")]
                    ]}
                    handleChange={value => {
                        setForm(values => ({...values, description: value}));
                        checkInputCancellation('description', numbersStore.data.description, value)
                    }}
                    onValidate={valid => revalidate('description', valid)}/>
            </div>
            <div className="form__block">
                <label className="form__label" htmlFor="address">{i18n("numbers.form.address.label")}</label>
                <Input type="text"
                    id="address"
                    placeholder={i18n("numbers.form.address.placeholder")}
                    autoComplete="off"
                    value={form.address}
                    conditions={[
                        [value => checkInputLength(value, NUMBERS_ADDRESS_MAX_LENGTH), i18n("numbers.form.error.address.too_long")]
                    ]}
                    handleChange={value => {
                        setForm(values => ({...values, address: value}));
                        checkInputCancellation('address', numbersStore.data.address, value);
                    }}
                    onValidate={valid => revalidate('address', valid)}/>
            </div>
            <div className="form__block">
                <label className="form__label">{i18n("numbers.form.type_of_activity.label")}</label>
                <Select name="kind-of-activity" defaultOptedIndex={categories.find(({category}) => form.vertical === category)!.id}>
                    {categories.map(({id, category, i18n_key}) =>
                        <SelectItem key={id} value={category} onOpted={() => {
                            setForm(values => ({...values, vertical: category}))
                            checkInputCancellation('vertical', numbersStore.data.vertical, category)
                        }}>{i18n_key}</SelectItem>
                    )}
                </Select>
            </div>
            <div className="form__block">
                <label className="form__label" htmlFor="web-site">{i18n("numbers.form.website.label")}</label>
                <Input type="url"
                    id="web-site"
                    placeholder={i18n("numbers.form.website.placeholder")}
                    autoComplete="off"
                    value={form.websites[0]}
                    conditions={[
                            [value => value === '' || /^(http|https):\/\/([a-zа-я\d._-]{2,256})\.([a-zа-я]{2,63})($|(\/|\?)[a-zа-яA-ZА-Я\d@:%-_+.~#?&/="'{}]*$)/gm.test(value), i18n("numbers.form.error.website.incorrect_url")],
                            [value => checkInputLength(value, NUMBERS_WEBSITE_MAX_LENGTH), i18n("numbers.form.error.website.too_long")]
                    ]}
                    handleChange={value => {
                        setForm(values => ({...values, websites: [value]}));
                        checkInputCancellation('websites', numbersStore.data.websites[0], value)
                    }}
                    onValidate={valid => revalidate('websites', valid)}/>
            </div>
            <div className="form__block">
                <label className="form__label" htmlFor="email">{i18n("numbers.form.email.label")}</label>
                <Input type="email"
                       id="email"
                       placeholder={i18n("numbers.form.email.placeholder")}
                       autoComplete="off"
                       value={form.email}
                       conditions={[
                            [value => value === '' || /^([a-zA-Z\d._-]*)[a-zA-Z\d]@[a-z]([a-z._-]*)\.([a-z]{2,63})$/gm.test(value), i18n("numbers.form.error.email.incorrect_format")],
                            [value => value === '' || /^[a-zA-Z\d]/g.test(value), i18n("numbers.form.error.email.first_character")],
                            [value => !/[._-]{2}/g.test(value), i18n("numbers.form.error.email.consecutive_signs")],
                            [value => checkInputLength(value, NUMBERS_EMAIL_MAX_LENGTH), i18n("numbers.form.error.email.too_long")]
                       ]}
                       handleChange={value => {
                           setForm(values => ({...values, email: value}));
                           checkInputCancellation('email', numbersStore.data.email, value)
                       }}
                       onValidate={valid => revalidate('email', valid)}/>
            </div>
            <div className="form__block flex">
                <button type="button"
                        disabled={!isValid || numbersStore.busy || numbersStore.error.status || !isCancellation}
                        className={buildClassName("button button--save", !numbersStore.busy ? `button-floppy-disk ${numbersStore.shake ? "shake" : ""}` : "button-loader")}
                        onClick={onUpdate}>
                    <span className="button__text">{i18n("button.save")}</span>
                </button>
                {isCancellation ?
                    !numbersStore.busy && <button type="button" className="button button--cancellation ml14" onClick={onCancel}>{i18n("button.cancel")}</button>
                 : ''}
            </div>
        </form>
    </div>
});