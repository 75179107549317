import axios from "axios";
import { makeAutoObservable } from "mobx";
import { AuthResponse } from "./types/AuthDataBackend";
import { BASE_URL } from '../http/config';

export class AuthStore {
    isAuthorized = false;

    constructor() {
        makeAutoObservable(this);
    }

    setAuth(bool: boolean): void {
        this.isAuthorized = bool;
    }

    async login(login: string, password: string): Promise<void> {
        const response = await axios.post<AuthResponse>(
            `${BASE_URL}/auth/get_tokens.php`,
            JSON.stringify({login, password}),
            {headers: {"Content-Type": "application/json"}
        }).catch((error) => {
            this.setAuth(false);
        });
        if (response && response.data.access_token && response.data.refresh_token) {
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);
            this.setAuth(true);
        }
    }

    logout(): void {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        this.setAuth(false);
    }

    async checkAuth(): Promise<void> {
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
            const response = await axios.post<AuthResponse>(
                `${BASE_URL}/auth/refresh_tokens.php`, JSON.stringify({refresh_token: refreshToken}),
                {headers: {"Content-Type": "application/json"}}
            ).catch((error) => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                this.setAuth(false);
            });
            if (response) {
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('refresh_token', response.data.refresh_token);
                this.setAuth(true);
            }
        }
    }
}