import {action, makeObservable, observable} from "mobx";
import lang_ru from "./lang/ru";
import lang_en from "./lang/en";

class ObservedLanguage {
    lang: string = '';

    constructor() {
        makeObservable(this, {
            lang: observable,
            setLanguage: action
        });
    }

    setLanguage(language: any) {
        this.lang = language;
        WTRG_BUTTON.setLanguage && WTRG_BUTTON.setLanguage(language);
    }
}

let currentLanguage: ObservedLanguage = new ObservedLanguage();

const Registry: Map<string, object> = new Map([
    ['ru', lang_ru],
    ['en', lang_en],
]);

function i18n(key: string) {
    const dict: any = Registry.get(currentLanguage.lang);
    if (dict) {
        const value = dict[key];
        if (value) {
            return value;
        }
        return key;
    }

    throw new Error(`Specified dictionary not found: ${currentLanguage}`);
}

export {currentLanguage, i18n};