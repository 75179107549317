export type CacheEntry = {lastReloaded: number};
export class Timetable<K> {
    private markers = new Map<K, CacheEntry>();

    private readonly ttl: number;

    public constructor(ttl: number) {
        this.ttl = ttl;
    }

    public clear(): void {
        this.markers.clear();
    }

    public hasMarker(key: K): boolean {
        return this.markers.has(key);
    }

    public isExpired(key: K): boolean|null {
        const value = this.markers.get(key);

        if (value) {
            const isExpired = Date.now() - value.lastReloaded > this.ttl;
            if (isExpired) {
                this.markers.delete(key);
            }

            return isExpired;
        }

        return null;
    }

    public mark(key: K): void {
        this.markers.set(key, {lastReloaded: Date.now()});
    }
}