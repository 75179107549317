import React from "react";
import {buildClassName, varClass} from "../../../../utils/StringUtils";
import {useValidator, ValidatorPropsExtension, ValidatorResult} from "./hooks/Validator";

type InputProps = {
    value?: string,
    handleChange?: (value: any) => void,
    [attr: string]: any // Возможность передать любые атрибуты
}

export const Input = (props: InputProps & ValidatorPropsExtension) => {
    const {value = "", handleChange, onValidate, conditions, className, disabled, ...attributes} = props;

    let textValidation: ValidatorResult;
    function onChange(event:any) {
        const actualValidation = revalidate(event.target.value);

        if (onValidate && (actualValidation.isValid !== textValidation.isValid)) onValidate(actualValidation.isValid);
        if (handleChange) handleChange(event.target.value);

        textValidation = actualValidation;
    }

    const [revalidate] = useValidator(conditions, onValidate, value);

    textValidation = revalidate(value);

    const rootClasses = buildClassName("form__group", varClass(!textValidation.isValid, "valid-error"), varClass(disabled, "form__group--disabled"), className);
    return <>
        <div className={rootClasses}>
            <input className="form__input" disabled={disabled} {...attributes} value={value} onChange={onChange}/>
        </div>
        {(textValidation.details.length === 0) ? <></> : <div className="valid-feedback">
            {textValidation.details.map((detail, index) => <p key={index}>{detail}</p>)}
        </div>}
    </>;
};