export type AssocLike<V> = {[key: string]: V};

export class Bijection<K, V> {
    public size: number;

    private to: Map<K, V>;
    private fro: Map<V, K>;

    public constructor(...entries: Array<[K, V]>) {
        this.size = entries.length;

        this.to = new Map(entries);
        this.fro = new Map(entries.map<[V, K]>(  ([key, val]) => [val, key]  ));
    }

    public forward(key: K): V|undefined {
        return this.to.get(key);
    }

    public backward(value: V): K|undefined {
        return this.fro.get(value);
    }

    public hasKey(key: K): boolean {
        return this.to.has(key);
    }

    public hasValue(value: V): boolean {
        return this.fro.has(value);
    }

    public static compose<K, V>(a: Bijection<K, any>, b: Bijection<any, V>): Bijection<K, V> {
        const keys = a.to.keys(); const values = b.to.values();
        const result = new Array<[K, V]>();
        for (let i = 0; i < a.size; ++i) {
            const key = keys.next().value; const value = values.next().value;
            result.push([key, value]);
        }

        return new Bijection<K, V>(...result);
    }
}

export function WrapToMap<V>(assoc: AssocLike<V>): Map<string, V> {
    return new Map<string, V>(Object.entries<V>(assoc));
}

export function WrapToSet<K, V extends {[key: string]: any}>(object: Array<V>, unique: string, ...uniqueTail: Array<string>) {
    const assoc = new Map<K, V>();
    for (let element of object) {
        let key = element[unique];
        for (let index = 0; index < uniqueTail.length; ++index) {
            const uniquePart = uniqueTail[index];
            key = key[uniquePart];
        }

        if (!assoc.has(key)) assoc.set(key, element); else throw new TypeError(`Non-unique the key value was found: ${unique}: ${JSON.stringify(key)}`);
    }

    return assoc;
}

export function getKeyByValue<V extends string|number>(assoc: AssocLike<V>, value: V, otherwise: string): string {
    const found = Object.entries<V>(assoc).find(entry => entry[1] === value);
    return found ? found[0] : otherwise;
}