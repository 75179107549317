import {uploadPretemplate, loadPretemplate, updatePretemplate, loadPretemplatesList} from "./NetworkPretemplateWorker";
import {
    decodePacketResponseGet,
    decodePacketResponseListData,
    encodePacketRequestCreate,
    encodePacketRequestUpdate
} from "./translator/PretemplateTranslator";
import {ModelItem, ModelListItem} from "./types/PretemplateDataModel";
import {MINUTE} from "../../utils/Unit";
import {AppStore} from './AppStore';
import {Model} from "./core/Model";
import {Timetable} from "./core/Timetable";
import {runInAction} from "mobx";

export class PretemplateModel extends Model<string, ModelListItem, ModelItem> {
    private appStore: AppStore;

    private timetable = new Timetable<string>(5*MINUTE);
    private cachedSortedList: ModelListItem[]|undefined;

    public pretemplateItems = new Map<string, ModelItem>();

    public constructor(appStore: AppStore) {
        super();

        this.appStore = appStore;
    }

    get lastUpdatedTemplateId(): string|null {
        if (this.list.size === 0) return null;

        const found = Array.from(this.list)
            .reduce((left, right) => (left[1].updated_at > right[1].updated_at) ? left : right);

        return found[1].code;
    }

    get lastCreatedTemplateId(): string|null {
        if (this.list.size === 0) return null;

        const found = Array.from(this.list)
            .reduce((left, right) => (left[1].created_at > right[1].created_at) ? left : right);

        return found[1].code;
    }

    get asSortedByCreatedDate(): ModelListItem[] {
        const sortedList = this.cachedSortedList ?? Array.from(this.list.values())
            .sort((left, right) => {
                if (left.created_at < right.created_at) return 1;
                else if (left.created_at > right.created_at) return -1;

                return 0;
            });

        if (!this.cachedSortedList) this.cachedSortedList = sortedList;

        return sortedList;
    }

    hasKey(id: string): boolean {
        return this.list.has(id);
    }

    getIndex(id: string): number|null {
        const index = Array.from(this.list.values())
            .findIndex(template => template.code === id);

        return (index >= 0) ? index : null;
    }

    getValue(id: string): ModelListItem|null {
        const template = this.list.get(id);

        return template ?? null;
    }

    downloadItems(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            loadPretemplatesList()
                .then(response => {
                    const rawList = (response.list_templates) ? response.list_templates : [];
                    const preparedList = rawList
                        .map<[string, ModelListItem]>(
                            item => {
                                const decoded = decodePacketResponseListData(item);
                                return [item.code, decoded];
                            }
                        );
                    this.pretemplateItems = new Map<string, ModelItem>();
                    this.create(preparedList);
                    this.timetable.clear();
                    this.cachedSortedList = undefined;

                    resolve(true);
                })
                .catch(reason => {
                    console.error("Unexpected error while downloading pretemplates list.");
                    reject(reason);
                });
        });
    }

    downloadItem(code: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const isExpired = this.timetable.isExpired(code);
            const isLoaded = this.timetable.hasMarker(code);
            if (isLoaded && !isExpired) {
                resolve(false);
            } else {
                const oldListItem = this.list.get(code);
                if (oldListItem) {
                    loadPretemplate(oldListItem.template_id)
                        .then(template => {
                            const decoded = decodePacketResponseGet(template);
                            this.pretemplateItems.set(decoded.code, decoded);
                            this.set(decoded.code, {
                                template_id: oldListItem.template_id,
                                code: decoded.code,
                                name: decoded.name,
                                status: decoded.status.type,
                                language: decoded.language,
                                created_at: oldListItem.created_at,
                                updated_at: oldListItem.updated_at,
                            });

                            this.timetable.mark(code);

                            resolve(true);
                        })
                        .catch(reason => {
                            console.error("Unexpected error while downloading pretemplate.");
                            reject(reason);
                        });
                } else {
                    console.error("Missing pretempalte info.");
                    reject();
                }
            }
        });
    }

    uploadItem(template: ModelItem): Promise<string> {
        return new Promise((resolve, reject) => {
            const encoded = encodePacketRequestCreate(template, this.appStore.selectedAccountId);
            uploadPretemplate(encoded)
                .then(response => {
                    switch (response.type) {
                        case "PacketResponseCreate":
                            if (response.success) {
                                this.pretemplateItems.set(template.code, template);
                                this.set(template.code, {
                                    template_id: response.template_id,
                                    code: template.code,
                                    name: template.name,
                                    status: template.status.type,
                                    language: template.language,
                                    created_at: template.created_at,
                                    updated_at: template.updated_at,
                                });

                                this.timetable.mark(template.code);

                                this.cachedSortedList = undefined;

                                resolve(template.code);
                            } else {
                                console.error("Upload has been rejected.");
                                reject(response.message);
                            }
                            break;
                        case "PacketResponseCreateWABAReject":
                            console.error("Upload has been rejected.");
                            reject({
                                code: response.code,
                                message: response.message,
                                details: response.details,
                            });
                            break;
                        default:
                            console.error("Unexpected type of network packet.");
                            reject();
                            break;
                    }
                })
                .catch(reason => {
                    console.error("Unexpected error while uploading pretemplate.");
                    reject(reason);
                });
        });
    }

    updateItem(template: ModelItem): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const oldListItem = this.list.get(template.code);
            if (oldListItem) {
                const encoded = encodePacketRequestUpdate(oldListItem.template_id, template);
                updatePretemplate(encoded)
                    .then(response => {
                        if (response.success) {
                            const item = this.pretemplateItems.get(template.code);
                            if (item) {
                                runInAction(() => {
                                    item.name = template.name;
                                    oldListItem.name = template.name;
                                });

                                this.timetable.mark(template.code);
                                this.cachedSortedList = undefined;

                                resolve(true);
                            } else {
                                console.error("Missing pretempalte data.");
                                reject();
                            }
                        } else {
                            console.error("Update has been rejected.");
                            reject();
                        }
                    })
                    .catch(reason => {
                        console.error("Unexpected error while updating pretemplate.");
                        reject(reason);
                    });
            } else {
                console.error("Missing pretempalte info.");
                reject();
            }
        });
    }

    deleteItem(code: string): Promise<boolean> {
        return Promise.reject("Unsupported operation");
    }
}
