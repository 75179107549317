import {ModelItem} from "../types/AmotemplateDataModel";
import {PacketRequestCreate, PacketRequestUpdate, PacketResponseListData} from "../types/AmotemplateDataBackend";

export function decodePacketResponseListData(packet: PacketResponseListData): ModelItem {
    return {
        name: packet.public_name,
        code: packet.name,
        isActive: parseInt(packet.is_active),
        template_id: parseInt(packet.id),
        sort: parseInt(packet.sort),
        payload: (packet.params) ? {
            header: (packet.params?.header) ? packet.params?.header[0] : undefined,
            body: packet.params?.body,
            buttonUrl: (packet.params?.buttons_substitutions) ? packet.params?.buttons_substitutions[0] : undefined,
        } : undefined,
        created_at: Date.parse(packet.created_at),
        updated_at: Date.parse(packet.updated_at),
    };
}

export function encodePacketRequestUpdate(model: ModelItem): PacketRequestUpdate {
    const body = model.payload?.body;
    const header: [string]|undefined = (typeof model.payload?.header !== "undefined") ? [model.payload?.header] : undefined;
    const buttons: [string]|undefined = (typeof model.payload?.buttonUrl !== "undefined") ? [model.payload?.buttonUrl] : undefined;

    return {
        action: "update",
        template_id: model.template_id,
        name: model.code,
        is_active: model.isActive,
        sort: model.sort,
        public_name: model.name,
        params: (body || header || buttons) ? {
            body: body,
            header: header,
            buttons_substitutions: buttons,
        }: undefined,
    }
}

export function encodePacketRequestCreate(model: ModelItem): PacketRequestCreate {
    const body = model.payload?.body;
    const header: [string]|undefined = (typeof model.payload?.header !== "undefined") ? [model.payload?.header] : undefined;
    const buttons: [string]|undefined = (typeof model.payload?.buttonUrl !== "undefined") ? [model.payload?.buttonUrl] : undefined;

    return {
        action: "create",
        name: model.code,
        is_active: model.isActive,
        sort: model.sort,
        public_name: model.name,
        params: (body || header || buttons) ? {
            body: body,
            header: header,
            buttons_substitutions: buttons,
        }: undefined,
    }
}