import React, {ChangeEvent, Ref, useEffect, useRef, useState, useCallback} from "react";
import {observer} from "mobx-react-lite";
import {buildClassName, varClass} from "../../../../utils/StringUtils";
import {withConditional} from "../../../../hooks/ConditionalHOC";
import {DetailVerifiable} from "../../../stores/core/Validation";

export type InputCommonProps = {
    value?: string,
    onChange?: (value: string) => void,
    asText?: boolean,
    [attr: string]: any, // Возможность передать любые атрибуты
}

type InputWithMarkerProps = InputCoreProps;
export const InputWithMarker = observer((props: InputWithMarkerProps) => {
    const {value = "", onChange, ...rest} = props;

    const [inputValidator, setInputValidator] = useState<(value: string) => void>();
    const [text, setText] = useState(value);
    const ref = useRef<HTMLInputElement>(null);

    function validateMarkers(): boolean {
        const markers = text.match(/\{\{\d+\}\}/g) ?? [];
        return markers.length < 1;
    }

    function insertMarker(value: string, start: number, end: number): string {
        return `${value.substring(0, start)}{{1}}${value.substring(end)}`;
    }

    function setCursorPosition(position: number) {
        setTimeout(() => ref.current?.setSelectionRange(position, position), 10);
    }

    function getSelectionRange() {
        return {start: ref.current?.selectionStart ?? 0, end: ref.current?.selectionEnd ?? 0};
    }

    const ButtonInsertMarker = () => {
        const sequenceIsCorrect = validateMarkers();
        if (!props.disabled && sequenceIsCorrect) return <div className="form__group-div">
            <button type="button" className="button" onClick={e => {
                const element = ref.current;
                if (element) {
                    element.focus();

                    const range = getSelectionRange();
                    if (range.start >= 0 && range.end >= 0) {
                        const newText = insertMarker(text, range.start, range.end);
                        setText(newText);

                        if (inputValidator) inputValidator(newText);
                    }

                    setCursorPosition(range.start + 5); // 5 is length of the string -> {{1}}
                }
                e.preventDefault();
            }}>
                {"{{1}}"}
            </button>
        </div>;

        return <></>;
    };

    return <InputCore {...rest}
        validateSupplier={handler => setInputValidator(() => handler)}
        forwardedRef={ref}
        value={text}
        toolbar={<ButtonInsertMarker/>}
        onChange={value => {
            setText(value);
            onChange && onChange(value);
        }}
        wrapper={(inner, isValid) => {
            const rootClasses = buildClassName("form__group",
                varClass(!isValid, "valid-error"),
                varClass(props.disabled, "form__group--disabled"),
                props.className
            );
            return <div className={rootClasses}>{inner}</div>;
        }}
    />
});

type InputProps = InputCoreProps;
export const Input = (props: InputProps) => {
    const {value = "", onChange, ...rest} = props;
    const [text, setText] = useState(value);

    return <InputCore {...rest}
        value={text}
        onChange={value => {
            setText(value);
            onChange && onChange(value);
        }}
        wrapper={(inner, isValid) => {
            const rootClasses = buildClassName("form__group",
                varClass(!isValid, "valid-error"),
                varClass(props.disabled, "form__group--disabled"),
                props.className
            );
            return <div className={rootClasses}>{inner}</div>;
        }}
    />
};

export type InputCoreProps = InputCommonProps&{
    forwardedRef?: React.RefObject<HTMLTextAreaElement|HTMLInputElement>,
    toolbar?: JSX.Element,
    wrapper?: (inner: JSX.Element, isValid?: boolean) => JSX.Element, // Компонент-контейнер переданный сверху, в который можно поместить компонент\данные с этого уровня иерархии
    isTextarea?: boolean,
    autoComplete?: "on"|"off",
    validateSupplier?: (handler: (value: string) => void) => void,
    hotVerifier?: () => DetailVerifiable.PartialResult|undefined, // Коллбэк, производящий валидацию и получающий только её валидации
    coldVerified?: DetailVerifiable.PartialResult, // Готовый результат валидации, переданный сверху
    onVerifyChanged?: (isValid?: boolean) => void, // Если изменился результат валидации
}
export const InputCore = observer((props: InputCoreProps) => {
    const {validateSupplier, hotVerifier, coldVerified, onVerifyChanged, asText = false, autoComplete = "off", forwardedRef, isTextarea = false, value = "", onChange, toolbar, wrapper = inner => <>{inner}</>, ...attributes} = props;

    const [verified, setVerified] = useState<DetailVerifiable.PartialResult|undefined>(() => coldVerified);

    useEffect(() => {
        setVerified(coldVerified)
    // eslint-disable-next-line
    }, [props.coldVerified]);
    useEffect(() => { // Обсуживающая конструкция, (1) отдающая колбэк, вызывающий перевалидацию
        if (validateSupplier) {
            validateSupplier(value => { // (1) этот колбэк сохраняется и вызывается вышележащем по иерархии элементом
                resetValidation();
                if (onChange) onChange(value);
                validate();
            });
        }
    // eslint-disable-next-line
    }, []);

    function resetValidation() {
        // Сброс результатов валидации, при изменении данных для того, чтобы очистить индикацию ошибок
        // Если валидация горячая, то нет смысла этого делать
        // Также, если валидация успешная, то тоже нет смысла отображать ошибки
        const isUsingHotVerifiable = !!hotVerifier;
        if (!isUsingHotVerifiable && !verified?.isValid) {
            setVerified(undefined);
        }
    }

    function validate() {
        // Механизм горячей валидации
        if (hotVerifier) {
            const result = hotVerifier();
            setVerified(result);
            if (verified?.isValid !== result?.isValid) {
                if (onVerifyChanged) onVerifyChanged(result?.isValid);
            }
        }
    }

    // Коллбэк для обновления и оповещения об изменении актуального значения
    function handleChange(event: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) {
        resetValidation();
        if (onChange) onChange(event.target.value); // Оповещение верхнего уровня об изменении данных в поле ввода
        validate();
    }

    function ValidationDetails() {
        return (verified && !verified.isValid) ? <div className="valid-feedback">{verified?.details}</div> : <></>;
    }

    if (isTextarea) {
        const [textareaSize, setTextareaSize] = useState(forwardedRef?.current?.offsetWidth);

        const handleTextareaResize = useCallback(() => {
            setTextareaSize(forwardedRef?.current?.offsetWidth);
        }, [forwardedRef]);

        useEffect(() => {
            window.addEventListener('resize', handleTextareaResize);
            return () => {
                window.removeEventListener('resize', handleTextareaResize);
            }
        }, [handleTextareaResize]);

        useEffect(() => {
            const targetElement = (forwardedRef?.current as HTMLElement);
            if (targetElement) {
                targetElement.style.height = "auto";
                targetElement.style.height = (targetElement.scrollHeight > 342 ? 342 : targetElement.scrollHeight) + "px";
            }
        }, [textareaSize, value, forwardedRef]);
    }

    // Оборачиваем чистый инпут в div'ы и выбираем тип input'a: input или textarea
    // Добавляем боковую кнопку в тулбар, если передана
    // Рисуем div с результатом валидации поля
    return withConditional(
        <>
            {wrapper(<>
                {withConditional(
                    <input className="form__input" ref={forwardedRef as unknown as Ref<HTMLInputElement>} value={value} onChange={handleChange} autoComplete={autoComplete} {...attributes}/>,
                    <textarea ref={forwardedRef as unknown as Ref<HTMLTextAreaElement>} value={value} onChange={handleChange} autoComplete={autoComplete} {...attributes}/>,
                    () => !isTextarea
                )}
                {toolbar}
            </>, !verified || verified.isValid)}
            <ValidationDetails/>
        </>,
        <span className="ws-pw wb-ba">{value}</span>,
        () => !asText
    );
});