import {
    ResponsiveContainer,
    LineChart,
    Line,
    Tooltip,
    XAxis,
    YAxis,
    CartesianGrid
} from "recharts";
import {SECOND} from '../../../utils/Unit';
import {useState, useEffect} from 'react';
import {i18n} from "../../../i18n/i18n";

type ChartDataItem = {
    hour: number|string,
    read: number|null,
    replied: number|null
}

export type ChartDataModel = Array<ChartDataItem>;

const AnalyticsChart = (props: {chartData: ChartDataModel}) => {
    const [chartData, setChartData] = useState(props.chartData);
    const [hoursCount, setHoursCount] = useState(0);

    useEffect(() => {
        let hoursCount = 0;
        setChartData(props.chartData.map((value: ChartDataItem, index: number) => {
            if (typeof value.hour === 'number') {
                let hour = new Date(value.hour * SECOND).getHours().toString();
                hour = hour.length === 1 ? '0' + hour : hour;
                value.hour = hour + ":00";
            }
            if (typeof value.read === 'number') {
                hoursCount++
            }
            return value;
        }));
        setHoursCount(hoursCount);
    }, [props.chartData]);

    const maxChartValue = (chartData: ChartDataModel) => {
        return Math.max(
            ...chartData.map(
                chartDataItem => {
                    if(typeof chartDataItem.read == 'number' && typeof chartDataItem.replied == 'number') {
                        return Math.max(chartDataItem.read, chartDataItem.replied);
                    } else {
                        return 0;
                    }
                }
            )
        );
    };

    return <>
        <div className="chart-header">
            <div className="chart-title">{i18n("campaigns.analytics.chart.title")}</div>
            <div className="statuses-container">
                <div className="status-read">
                    <div className="status-icon"></div>
                    <div className="status-name">{i18n("campaigns.analytics.chart.status.read")}</div>
                </div>
                <div className="status-replied">
                    <div className="status-icon"></div>
                    <div className="status-name">{i18n("campaigns.analytics.chart.status.replied")}</div>
                </div>
            </div>
        </div>
        <div className="chart-container">
            <ResponsiveContainer height={239} width="99%">
                <LineChart
                    data={chartData}
                    margin={{
                        top: 20,
                        left: -7,
                        bottom: 14,
                        right: 4
                    }}
                >
                    <CartesianGrid vertical={false} strokeWidth="1" />
                    <XAxis
                        dataKey="hour"
                        tickLine={false}
                        axisLine={false}
                        padding={{ left: 20, right: 20 }}
                        tickMargin={14}
                        interval={3}
                    />
                    <YAxis
                        tickLine={false}
                        axisLine={false}
                        tickMargin={16}
                        domain={[0, maxChartValue(chartData) < 20 ? 20 : 'auto']}
                    />
                    <Tooltip 
                        formatter={
                            (value: number|string, name:string) => {
                                if (name === 'replied') {
                                    name = i18n("campaigns.analytics.chart.status.replied");
                                } else if (name === 'read') {
                                    name = i18n("campaigns.analytics.chart.status.read");
                                }
                                return [value, name];
                            }
                        }
                        itemSorter={(item) => item.name === 'replied' ? 1 : 0}
                    />
                    <Line
                        type="monotone"
                        dataKey="replied"
                        strokeWidth="2"
                        stroke="rgb(134, 134, 134)"
                        dot={hoursCount > 1 ? false : {stroke:"rgb(134, 134, 134)", strokeWidth: 3.95, r:1.975}}
                        activeDot={{ r: 5 }}
                        isAnimationActive={hoursCount > 1}
                    />
                    <Line
                        type="monotone"
                        dataKey="read"
                        strokeWidth="2"
                        stroke="#128C7E"
                        dot={hoursCount > 1 ? false : {stroke:"#128C7E", strokeWidth: 3.95, r:1.975}}
                        activeDot={{ r: 5 }}
                        isAnimationActive={hoursCount > 1}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    </>;
};

export default AnalyticsChart;