import React, {useContext, useEffect, useState} from "react";
import {MobXProviderContext, observer} from "mobx-react";
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";

import {Authorization, Logout} from "./../../../authorization/Authorization";
import {Header} from "../header/Header";
import {Sidebar} from "../sidebar/Sidebar";
import {DocumentStore, ItemNav} from "../../../stores/DocumentStore";
import {AppStore} from "../../../stores/AppStore";
import {AuthStore} from "../../../stores/AuthStore";
import {currentLanguage} from '../../../../i18n/i18n';

export const Pages = observer(() => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingLanguage, setIsLoadingLanguage] = useState(true);
    const appStore = useContext(MobXProviderContext).root.app as AppStore;
    const authStore = useContext(MobXProviderContext).root.auth as AuthStore;

    useEffect(() => {
        if (document.URL.includes('logout')) {
            setIsLoading(false);
        } else {
            authStore.checkAuth().then(() => {
                setIsLoading(false);
            });
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (authStore.isAuthorized && !document.URL.includes('logout')) {
            appStore.loadAccounts();
            appStore.loadUserProfile();
        } else {
            appStore.unsetData();
        }
        setIsLoadingLanguage(true);
    // eslint-disable-next-line
    }, [authStore.isAuthorized]);

    useEffect(() => {
        if (appStore.userProfile?.language) {
            currentLanguage.setLanguage(appStore.userProfile?.language);
            if (!localStorage.getItem('language') || localStorage.getItem('language') !== appStore.userProfile?.language) {
                localStorage.setItem('language', appStore.userProfile?.language);
            }
        } else {
            if (!localStorage.getItem('language')) {
                localStorage.setItem('language', 'ru');
                currentLanguage.setLanguage('ru');
            } else {
                currentLanguage.setLanguage(localStorage.getItem('language'));
            }
        }
        setIsLoadingLanguage(false);
    }, [appStore.userProfile]);

    if (isLoading || isLoadingLanguage) {
        return <></>;
    }

    return <>
        <Router>
            <Switch>
                <Route exact path="/" children={() => <Redirect to="/campaigns" />}/>
                <Route exact path="/login" children={<Authorization />} />
                <Route exact path="/logout" children={<Logout />} />
                <Route children={<AdminPanel isAuthorized={authStore.isAuthorized}/>} />
            </Switch>
        </Router>
    </>;
});

function AdminPanel(props: {isAuthorized: boolean}){
    const documentStore = useContext(MobXProviderContext).root.document as DocumentStore;
    const buildRoutes = () => {
        const routes = documentStore.routes.filter(element => !element.isDelimiter) as ItemNav[];
        return routes.map(({route}) => <Route key={route.path} path={route.path} component={route.page}/>);
    };
    return props.isAuthorized ? <>
        <Header/>
        <div className="page-layout">
            <Sidebar/>
            <main className="main">
                <div className="main__content">
                    {buildRoutes()}
                </div>
            </main>
        </div>
    </> : <Redirect to="/login"/>;
}
