import React, {FunctionComponent, ReactElement} from "react";

type IconProperties = {
    src: string,
    invisible?: boolean
}

type IconGroupProperties = {
    index?: number
}

export const Icon: FunctionComponent<IconProperties> = ({src, invisible}) => {
    return invisible ? <></> : <img src={src} alt="icon"/>;
};

export const IconGroup: FunctionComponent<IconGroupProperties> = ({children, index}) => {
    const iconIndex: number = index ?? 0;

    function getIcon(): ReactElement<HTMLImageElement> | null {
        let icon = null;
        React.Children.forEach(children, (child, index) => {
            if ((child as ReactElement).type !== "img")
                throw new Error('The component \'IconGroup\' cannot contain a non-image components');

            if (index === iconIndex)
                icon = child;
        });

        return icon;
    }

    return <>{getIcon()}</>;
}