import React, {useEffect, useState} from "react";
import {useDebouncing} from "../../../../hooks/DebouncingHook";
import {i18n} from "../../../../i18n/i18n";

type CampaignSearchFieldProps = {
    searchString?: string,
    debounceDelayMs?: number,
    onDebounce?: (value: string) => Promise<void>|void,
    onChange?: (value: string) => Promise<void>|void,
};

export const CampaignSearchField = ((props: CampaignSearchFieldProps) => {
    const {debounceDelayMs = 500, onDebounce, onChange} = props;

    const [value, setValue] = useState(props.searchString ?? '');
    const debounced = useDebouncing(value, debounceDelayMs);
    useEffect(() => {
        handleDebounce()
    // eslint-disable-next-line
    }, [debounced]);

    function handleDebounce() {
        if (onDebounce) {
            onDebounce(debounced);
        }
    }

    function handleChange(newValue: string) {
        setValue(newValue);
        if (onChange) onChange(newValue);
    }

    return <div className="search">
        <input className="input search__input" type="search" placeholder={i18n("search.placeholder")} value={value} onChange={e => handleChange(e.target.value)}/>
    </div>;
});