import {Store} from "../../core/Store";
import {ButtonTypes} from "../../../../utils/FacebookAPI";
import {DetailVerifiable} from "../../core/Validation";
import {PretemplateExample} from "./PretemplateExampleFormStore";
import {action, makeObservable} from "mobx";

export namespace PretemplateButtons {
    type SubstoreTuple = {
        type: ButtonTypes,
        substore: Store<any>&DetailVerifiable.Typed<any>,
    };

    export type FormData = SubstoreTuple[];
    export class FormStore extends Store<FormData> implements DetailVerifiable.Typed<{}> {
        private exampleStore: PretemplateExample.FormStore;
        private result: DetailVerifiable.Result<{ }> = [true, {}];

        constructor(exampleStore: PretemplateExample.FormStore) {
            super();

            this.exampleStore = exampleStore;

            makeObservable(this, {
                removeStore: action,
                addStore: action,
            });
        }

        public getCount(): number {
            return this.data.length;
        }

        public getStore(index: number): SubstoreTuple|undefined {
            return this.data[index];
        }

        public removeStore(index: number) {
            if (this.data[index].type === ButtonTypes.URL) {
                delete this.exampleStore.data.buttonUrl;
            }

            this.data = this.data.filter(
                (tuple, innerIndex) => index !== innerIndex
            );
        }

        public addStore(tuple: SubstoreTuple): boolean {
            if (this.getCount() < 3) {
                this.data.push(tuple);

                return true;
            }

            return false;
        }

        protected makeEmpty() { return []; }

        get validationResult(): DetailVerifiable.Result<{}> {
            return this.result;
        }

        get validationSchema(): DetailVerifiable.Schema<{}> {
            return {};
        }

        validate(): DetailVerifiable.Result<{}> {
            // Валидация зависимой формы, возможна рекурсивная проверка, если у зависимых форм есть свои зависимые формы
            let childrenIsValid = true;
            this.data.forEach(tuple => {
                const result = tuple.substore.validate();
                childrenIsValid = childrenIsValid && result[0];
            });

            this.result = [childrenIsValid, {}];
            return this.result;
        }
    }
}