export type Argument<T> = {type: "command"|"reference"|"undefined", value: string}|{type: "id", value: T}
export type ArgumentChecker<T> = (arg: string) => T|null;

export function processArgument<T=string>(argument: string, idChecker?: ArgumentChecker<T>): Argument<T> {
    const parsedArgument = argument.substr(1);

    switch (parsedArgument) {
        case "first": return {type: "reference", value: parsedArgument};
        case "add": return {type: "command", value: parsedArgument};
    }

    const parsedId = idChecker && idChecker(parsedArgument);
    if (parsedId !== null && typeof parsedId !== "undefined") return {type: "id", value: parsedId};

    return {type: "undefined", value: parsedArgument};
}