import {buildClassName, varClass} from "../../../../utils/StringUtils";

export type AddButtonProps = {
    title?: string,
    isPressed?: boolean,
    onClick?: () => void
}

export const AddButton = ((props: AddButtonProps) => {
    const {title = "", isPressed = false, onClick} = props;

    function handleClick() {
        if (onClick) onClick();
    }

    const className = buildClassName("list__button", varClass(isPressed, "list__button--active"));
    return <button type="button" className={className} onClick={handleClick}>  {/*<!-- list__button--active --> */}
        <svg className="list__button-icon" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className="circle" d="M18.0426 0.0849609C27.9599 0.0849609 36 8.12485 36 18.0425C36 27.9602 27.9599 36.0001 18.0424 36.0001C8.12479 36.0001 0.0848999 27.9602 0.0848999 18.0425C0.0848999 8.12485 8.12479 0.0849609 18.0426 0.0849609Z" fill="#DFE5E7"/>
            <path d="M18 10C17.7348 10 17.4804 10.1054 17.2929 10.2929C17.1054 10.4804 17 10.7348 17 11V17H11C10.7348 17 10.4804 17.1054 10.2929 17.2929C10.1054 17.4804 10 17.7348 10 18C10 18.2652 10.1054 18.5196 10.2929 18.7071C10.4804 18.8946 10.7348 19 11 19H17V25C17 25.2652 17.1054 25.5196 17.2929 25.7071C17.4804 25.8946 17.7348 26 18 26C18.2652 26 18.5196 25.8946 18.7071 25.7071C18.8946 25.5196 19 25.2652 19 25V19H25C25.2652 19 25.5196 18.8946 25.7071 18.7071C25.8946 18.5196 26 18.2652 26 18C26 17.7348 25.8946 17.4804 25.7071 17.2929C25.5196 17.1054 25.2652 17 25 17H19V11C19 10.7348 18.8946 10.4804 18.7071 10.2929C18.5196 10.1054 18.2652 10 18 10Z" fill="white"/>
        </svg>
        <p>{title}</p>
    </button>;
});
