import { useState, useEffect } from "react";
import _ from "lodash";
import {observer} from "mobx-react";
import {i18n} from "../../../../i18n/i18n";
import {formatPhone} from '../../../stores/AppStore';
import authorizedAxios from '../../../http/authorizedAxios';
import iconEmpty from '../../../../image/empty.svg';

export type RecipientsTableElementProps = {
    data: any,
    campaignId: any
}

export const RecipientsTableElement = observer((props: RecipientsTableElementProps) => {
    const { data, campaignId } = props;

    const onSearchImageRecipient = (e: any) => {
        if (data.photo_url) {
            window.open('https://yandex.ru/images/search?rpt=imageview&url=' + e.currentTarget.src, '_blank')
        }
    }

    const photo = data.photo_url ? data.photo_url : iconEmpty;
    const phone = formatPhone(`${data.wa_id[0] !== '+' ? '+' : ''}${data.wa_id}`);
    return (
        <div className="table-row table-item">
            <div className="table-row__cell table-row__cell-vertical_middle">
                <div className="table-row__inner">
                    <div className="table-item__title">
                        <div className="table-item__title-img">
                            <img src={photo} onClick={onSearchImageRecipient} alt="recipient" />
                        </div>
                        <div className="table-item__title-text">{data.name}</div>
                    </div>
                </div>
            </div>
            <div className="table-row__cell table-row__cell-right table-row__cell-vertical_middle">
                <div className="table-row__inner">{phone}</div>
            </div>
            <div className="table-row__cell table-row__cell-vertical_middle">
                <TooltipStatus
                    status={data.status_code}
                    recipient_id={data.recipient_id}
                    campaign_id={campaignId}/>
            </div>
            <div className="table-row__cell table-row__cell-vertical_middle">
                <div className="table-row__tags">
                    <span className="status status--mini status--dashed">{i18n("campaigns.recipients.goals.no_goals")}</span>
                </div>
            </div>
            <div className="table-row__cell table-row__cell-vertical_middle">
                <div className="table-row__inner">
                    {/* <div className="table-item__btn_open">
                        <span className="table-item__btn_open-text">Открыть</span>
                        <svg className="table-item__btn_open-icon" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 5.00014C0 4.86753 0.0526784 4.74036 0.146447 4.64659C0.240215 4.55282 0.367392 4.50014 0.5 4.50014H12.293L9.146 1.35414C9.05211 1.26026 8.99937 1.13292 8.99937 1.00014C8.99937 0.867368 9.05211 0.74003 9.146 0.646143C9.23989 0.552257 9.36722 0.499512 9.5 0.499512C9.63278 0.499512 9.76011 0.552257 9.854 0.646143L13.854 4.64614C13.9006 4.69259 13.9375 4.74776 13.9627 4.80851C13.9879 4.86926 14.0009 4.93438 14.0009 5.00014C14.0009 5.06591 13.9879 5.13103 13.9627 5.19178C13.9375 5.25252 13.9006 5.3077 13.854 5.35414L9.854 9.35414C9.76011 9.44803 9.63278 9.50077 9.5 9.50077C9.36722 9.50077 9.23989 9.44803 9.146 9.35414C9.05211 9.26026 8.99937 9.13292 8.99937 9.00014C8.99937 8.86737 9.05211 8.74003 9.146 8.64614L12.293 5.50014H0.5C0.367392 5.50014 0.240215 5.44746 0.146447 5.3537C0.0526784 5.25993 0 5.13275 0 5.00014Z" fill="#0E0E0E"/>
                        </svg>
                    </div> */}
                </div>
            </div>
        </div>
    )
});

const StatusIcon = (params: {status: string}) => {
    switch (params.status) {
        case 'sent': return <div className="tick-one"></div>;
        case 'delivered': return <div className="tick-double"></div>;
        case 'read': return <div className="tick-double-blue"></div>;
        case 'replied': return <><div className="tick-double-blue mr8"></div><div className="correspondence"></div></>;
        case 'failed': return <div className="tick-x"></div>;
        default: return null;
    }
}

const TooltipStatus = ({ status, recipient_id, campaign_id }: any) => {
    const [hover, setHover] = useState(false);
    const [statusList, setStatusList] = useState([]);

    useEffect(() => {
        if (hover && !statusList.length) {
            authorizedAxios.post(
                '/analytics_data.php',
                { action: 'statuses', campaign_id, recipient_id },
                { headers: { "Content-Type": "application/json" } }
            ).then(res => {
                setStatusList(res.data);
            }).catch(err => {
                console.log(err);
            });
        }
    }, [campaign_id, recipient_id, hover, statusList.length]);

    const formatStatusTime = (timestamp: number): string => {
        const dateObject: Date = new Date(timestamp * 1000);
        const date: string = ('0' + dateObject.getDate()).slice(-2) + '.' + ('0' + (dateObject.getMonth() + 1)).slice(-2) + '.' + dateObject.getFullYear();
        const hours: number = dateObject.getHours();
        const minutes: number = dateObject.getMinutes();
        return `${date} ${hours}:${minutes > 9 ? minutes : '0' + minutes}`;
    }

    const toolStatusItem = (title: string, time: number) => {
        return <div className="table-row__inner-block__status">{title}: {formatStatusTime(time)}</div>;
    }

    const tool = () => {
        return (
            <div className="table-row__inner-block">
                {statusList[0] && statusList[0]['code'] === 'sent' ? toolStatusItem(i18n("campaigns.recipients.status.sent"), statusList[0]['received_at']) : null}
                {statusList[0] && statusList[0]['code'] === 'failed' ? toolStatusItem(i18n("campaigns.recipients.status.error"), statusList[0]['received_at']) : null}
                {statusList[1] && statusList[1]['code'] === 'delivered' ? toolStatusItem(i18n("campaigns.recipients.status.delivered"), statusList[1]['received_at']) : null}
                {statusList[2] && statusList[2]['code'] === 'read' ? toolStatusItem(i18n("campaigns.recipients.status.read"), statusList[2]['received_at']) : null}
                {statusList[3] && statusList[3]['code'] === 'replied' ? toolStatusItem(i18n("campaigns.recipients.status.replied"), statusList[3]['received_at']) : null}
            </div>
        )
    }

    const onOver = () => onToggleHover(true);

    const onOut = () => onToggleHover(false);

    const onToggleHover = _.debounce((state: boolean) => {
        setHover(state);
    }, 500);

    const classTooltip = 'table-row__inner ' + hover ? 'table-row__inner-active' : '';
    const tooltip = hover && statusList.length !== 0 ? tool() : null;
    return (
        <div className={classTooltip}>
            <div onMouseOver={onOver} onMouseOut={onOut}>
                <div className="flex flex--ai-center">
                    <StatusIcon status={status} />
                </div>
                {tooltip}
            </div>
        </div>
    )
}