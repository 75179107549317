import React from "react";
import {observer} from "mobx-react-lite";
import {TemplateStatusType} from "../../../../utils/FacebookAPI";
import {i18n} from "../../../../i18n/i18n";
import {buildClassName} from "../../../../utils/StringUtils";
import {PretemplateStatus} from "../../../stores/types/PretemplateDataModel";

export type TemplateStatusProps = {
    status: PretemplateStatus
}

export const TemplateStatus = observer((props: TemplateStatusProps) => {
    const {status} = props;

    const statuses = {
        [TemplateStatusType.PENDING]: {i18n_key: "whatsapp.template.status.pending", indicator: "list__item-indicator--yellow"},
        [TemplateStatusType.APPROVED]: {i18n_key: "whatsapp.template.status.approved", indicator: "list__item-indicator--green"},
        [TemplateStatusType.DISABLED]: {i18n_key: "whatsapp.template.status.disabled", indicator: "list__item-indicator--red"},
        [TemplateStatusType.REJECTED]: {i18n_key: "whatsapp.template.status.rejected", indicator: "list__item-indicator--red"},
    };

    const buildInfo = () => {
        const statusStr = i18n(statuses[status.type].i18n_key);
        return statusStr + (status.reason ? ": " + status.reason : "");
    }

    const indicatorClass = buildClassName("form__indicator", statuses[status.type].indicator);
    return <div className="flex flex--ai-center">
        {/* <!-- form__indicator--red, form__indicator--yellow, form__indicator--green --> */}
        <span className={indicatorClass}/> {buildInfo()}
    </div>;
});