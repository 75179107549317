import {Config} from "../../config";
import {
    PacketRequestCreate,
    PacketRequestUpdate, PacketResponseCreate,
    PacketResponseList,
    PacketResponseUpdate
} from "./types/AmotemplateDataBackend";
import authorizedAxios from "../http/authorizedAxios";

const amotemplatesApiUrl = Config.network.base + Config.network.api.amotemplates;
const cloudApiUrl = Config.network.base + Config.network.api.cloud;

export async function loadAmotemplates(): Promise<PacketResponseList> {
    const result = await authorizedAxios.get(amotemplatesApiUrl);
    return result.data;
}

export async function updateAmotemplate(template: PacketRequestUpdate): Promise<PacketResponseUpdate> {
    const result = await authorizedAxios.post(
        amotemplatesApiUrl,
        JSON.stringify(template),
        {headers: {"Content-Type": "application/json"}}
    );
    return result.data;
}

export async function uploadFileToCloud(file: File) {
    const data = new FormData();
    data.append("file", file);

    const result = await authorizedAxios.post(cloudApiUrl, data);

    return result.data;
}

export async function uploadAmotemplate(template: PacketRequestCreate): Promise<PacketResponseCreate> {
    const result = await authorizedAxios.post(
        amotemplatesApiUrl,
        JSON.stringify(template),
        {headers: {"Content-Type": "application/json"}}
    );
    return result.data;
}