import {useEffect, useRef, useState} from "react";
import {Argument} from "../../../parser/ArgumentParser";
import {useHistory} from "react-router-dom";
import {Model} from "../../../stores/core/Model";

type AddressArgument = {id: string}

export function useInitialId<T=string>(path: string, identity: T, argument: Argument<T>, model: Model<T, unknown>): [T, (id: T) => void] {
    const history = useHistory<AddressArgument>();
    const toPath = useRef<string>();

    useEffect(() => {
        if (toPath.current) history.replace(toPath.current);
    }, [history]);

    const [id, setId] = useState<T>(() => {
        const nullId = identity;
        const firstId = model.lastCreatedTemplateId;

        if (firstId === null) {
            console.log("[Controller] (id/init) No templates");
            toPath.current = path + ":add";
            return nullId;
        }

        if (argument.type === "id") {
            const id = argument.value;
            const hasTemplate = model.hasKey(id);
            if (hasTemplate) {
                return id;
            } else {
                console.log("[Controller] (id/init) Specified id not found: ", id);
            }
        }

        if (argument.type === "reference") {
            switch (argument.value) {
                case "first":
                    toPath.current = path + ":" + firstId;
                    return firstId;
                default:
                    console.log("[Controller] (id/init) Unknown template reference");
            }
        }

        toPath.current = path + ":add";
        return nullId;
    });

    function set(id: T): void {
        setId(id);
    }

    return [id, set];
}