import { makeAutoObservable } from "mobx"

export type dataType = {
    about: string,
    address: string,
    description: string,
    email: string,
    photo: string,
    vertical: string,
    websites: string[]
}

export type errorType = {
    status: boolean,
    text: string
}

export class NumbersStore {
    isLoading: boolean = true
    busy:boolean = false
    data: dataType = {
        about: "",
        address: "",
        description: "",
        email: "",
        photo: "",
        vertical: "",
        websites: [""]
    }
    previewPhoto: string = ''
    shake:boolean = false
    error: errorType = {
        status: false,
        text: ""
    }

    constructor() {
        makeAutoObservable(this);
    }

    setStore = (data: dataType) => {
        this.data = data;
        this.previewPhoto = data.photo;
    }

    setLoading = (loading: boolean) => this.isLoading = loading;

    setPreviewPhoto = (photo: string = this.data.photo) => this.previewPhoto = photo;

    setBusy = (busy: boolean) => this.busy = busy;

    setShake = (shake: boolean) => this.shake = shake;

    setError = (error: errorType = {status: false, text: ""}) => this.error = error;

}