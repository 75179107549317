import {VerifiableStore} from "../../../core/Store";
import {DetailVerifiable} from "../../../core/Validation";
import {i18n} from "../../../../../i18n/i18n";
import {BUTTON_MESSAGE_MAX_LENGTH} from "../../../../../utils/FacebookAPI";

export namespace QuickReply {
    export type FormData = {
        caption: string
    }

    export class FormStore extends VerifiableStore<FormData> {
        protected makeEmpty(): FormData {
            return {
                caption: ""
            };
        }

        public get validationBlacklist(): (keyof FormData)[] {
            return [];
        }

        get validationSchema(): DetailVerifiable.Schema<FormData> {
            return {
                caption: [
                    [(value: string) => !value.length, i18n("templates.pretemplates.form.error.button.empty")],
                    [(value: string) => value.length > BUTTON_MESSAGE_MAX_LENGTH, i18n("templates.pretemplates.form.error.button.too_long")],
                ],
            };
        }

        get validationResult(): DetailVerifiable.Result<FormData> {
            return this.result;
        }
    }
}