import {useRef, useState} from "react";

/* Т-триггер вызывающий перерисовку */
export function useFlipFlopState(initial: boolean = false): [boolean, () => void] {
    const [flag, setFlag] = useState(initial);

    function trigger() {
        setFlag(old => !old);
    }

    return [flag, trigger];
}

/* Т-триггер не вызывающий перерисовку */
export function useFlipFlopRef(initial: boolean = false): [boolean, () => void] {
    const flag = useRef(initial);

    function trigger() {
        flag.current = !flag.current;
    }

    return [flag.current, trigger];
}