import {
    BODY_MESSAGE_MAX_LENGTH,
    BUTTON_MESSAGE_MAX_LENGTH,
    FOOTER_MESSAGE_MAX_LENGTH,
    HEADER_MESSAGE_MAX_LENGTH,
    TEMPLATE_NAME_MAX_LENGTH,
    NUMBERS_EMAIL_MAX_LENGTH,
    NUMBERS_SHORT_DESCRIPTION_MAX_LENGTH,
    NUMBERS_EXTENDED_DESCRIPTION_MAX_LENGTH,
    NUMBERS_ADDRESS_MAX_LENGTH,
    NUMBERS_WEBSITE_MAX_LENGTH
} from "../../utils/FacebookAPI";

const ru = {
    "time.lang": "ru-RU",

    "unit.mb": "МБ",

    "title.authorization": "Авторизация",
    "title.campaigns": "Рассылки",
    "title.templates": "Шаблоны",
    "title.numbers": "Номера WhatsApp",

    "sidebar.campaigns": "Рассылки",
    "sidebar.templates": "Шаблоны",
    "sidebar.numbers": "Номера",
    "sidebar.integrations": "Интеграции",
    "sidebar.qr_pages": "QR-коды",
    "sidebar.button": "Кнопка",
    "sidebar.settings": "Настройки",
    "sidebar.support": "Помощь",

    "button.enter": "Войти",
    "button.extend": "Продлить",
    "button.create": "Создать",
    "button.back": "Назад",
    "button.send": "Отправить",
    "button.save": "Сохранить",
    "button.cancel": "Отмена",
    "button.remove": "Удалить",
    "button.upload": "Загрузить",
    "button.change": "Изменить",

    "search.placeholder": "Поиск",

    "account.until": "До",

    "authorization.tab.via_whatsapp": "Через WhatsApp",
    "authorization.tab.by_password": "По паролю",
    "authorization.form.login.label": "Логин",
    "authorization.form.password.label": "Пароль",
    "authorization.form.error.invalid_password": "Неверный пароль",
    "authorization.button.forgot_password": "Забыли пароль?",

    "campaigns.table.head.date": "Дата",
    "campaigns.table.head.recipients": "Получатели",
    "campaigns.table.head.status": "Статус",
    "campaigns.table.head.read": "Прочитано",
    "campaigns.table.head.replied": "Отвечено",
    "campaigns.table.status_search.empty": "Ничего не найдено",
    "campaigns.table.button.open": "Открыть",
    "campaigns.status.sent": "Отправлено",
    "campaigns.status.started": "Запущено",
    "campaigns.status.changed": "Изменено",
    "campaigns.status.draft": "Черновик",
    "campaigns.status.waiting": "Ожидание",
    "campaigns.status.process": "В процессе",
    "campaigns.status.working": "Работает",
    "campaigns.time.in": " в ",
    "campaigns.time.at": " ",
    "campaigns.empty.title": "Вы пока не запускали рассылок :(",
    "campaigns.empty.subtitle": "Вы можете делать массовые отправки шаблонов через списки в amoCRM",
    "campaigns.empty.button": "Перейти",
    "campaigns.tab.preview": "Предпросмотр",
    "campaigns.tab.analytics": "Аналитика",
    "campaigns.tab.recipients": "Получатели",
    "campaigns.analytics.conversions": "Конверсии",
    "campaigns.analytics.conversions.sent": "Отправлено",
    "campaigns.analytics.conversions.delivered": "Доставлено",
    "campaigns.analytics.conversions.read": "Прочитано",
    "campaigns.analytics.conversions.replied": "Отвечено",
    "campaigns.analytics.chart.title": "Реакция ⏱ 24 часа",
    "campaigns.analytics.chart.status.read": "Прочитано",
    "campaigns.analytics.chart.status.replied": "Отвечено",
    "campaigns.analytics.goals": "Цели",
    "campaigns.analytics.goals.button": "Добавить новую цель",
    "campaigns.analytics.geoanalytics": "Геоаналитика",
    "campaigns.recipients.table.head.name": "Имя",
    "campaigns.recipients.table.head.phone": "Телефон",
    "campaigns.recipients.table.head.status": "Статус",
    "campaigns.recipients.table.head.goals": "Цели",
    "campaigns.recipients.table.empty": "Получателей нет",
    "campaigns.recipients.status.sent": "Отправлено",
    "campaigns.recipients.status.error": "Ошибка",
    "campaigns.recipients.status.delivered": "Доставлено",
    "campaigns.recipients.status.read": "Прочитано",
    "campaigns.recipients.status.replied": "Отвечено",
    "campaigns.recipients.goals.no_goals": "Нет целей",

    "templates.tab.pre_templates": "Пре-шаблоны",
    "templates.tab.amocrm_templates": "Шаблоны amoCRM",
    "templates.button.add_template": "Добавить шаблон",
    "templates.pretemplates.form.name.label": "Название",
    "templates.pretemplates.form.template_code.label": "Код шаблона",
    "templates.pretemplates.form.category.label": "Категория",
    "templates.pretemplates.form.language.label": "Язык",
    "templates.pretemplates.form.status.label": "Статус",
    "templates.pretemplates.form.header.label": "Шапка",
    "templates.pretemplates.form.message.label": "Сообщение",
    "templates.pretemplates.form.signature.label": "Подпись",
    "templates.pretemplates.form.structure.label": "Структура",
    "templates.pretemplates.form.example": "Пример заполнения",
    "templates.pretemplates.form.button.label": "Кнопка",
    "templates.pretemplates.form.error.emojis_not_allowed": "Подпись не может содержать эмодзи",
    "templates.pretemplates.form.error.empty_name": "Отсутствует наименование шаблона",
    "templates.pretemplates.form.error.same_code": "Такой код уже зарегистрирован",
    "templates.pretemplates.form.error.template_code_is_missing": "Отсутствует код шаблона",
    "templates.pretemplates.form.error.template_code.too_long": `Не более ${TEMPLATE_NAME_MAX_LENGTH} символов`,
    "templates.pretemplates.form.error.invalid_char": "Использован неверный символ",
    "templates.pretemplates.form.error.header.empty": "Отсутствует текст заголовка",
    "templates.pretemplates.form.error.header.too_long": `Не более ${HEADER_MESSAGE_MAX_LENGTH} символов`,
    "templates.pretemplates.form.error.body.empty": "Отсутствует текст сообщения",
    "templates.pretemplates.form.error.body.too_long": `Не более ${BODY_MESSAGE_MAX_LENGTH} символов`,
    "templates.pretemplates.form.error.invalid_sequence": "Неверный порядок параметров",
    "templates.pretemplates.form.error.footer.too_long": `Не более ${FOOTER_MESSAGE_MAX_LENGTH} символов`,
    "templates.pretemplates.form.error.button.empty": "Отсутствует название кнопки",
    "templates.pretemplates.form.error.button.too_long": `Не более ${BUTTON_MESSAGE_MAX_LENGTH} символов`,
    "templates.pretemplates.form.error.incorrect_phone": "Некорректный номер",
    "templates.pretemplates.form.error.incorrect_url": "Некорректный URL",
    "templates.pretemplates.add_template.form.subtext.before": "Шаблоны проходят обязательную модерацию в",
    "templates.pretemplates.add_template.form.subtext.after": "Facebook длительностью от 1 часа до 2 дней.",
    "templates.pretemplates.form.buttons.label": "Кнопки",
    "templates.pretemplates.form.buttons.name_button.placeholder": "Введите название кнопки",
    "templates.pretemplates.form.buttons.url.placeholder": "Введите ссылку",
    "templates.pretemplates.form.buttons.phone.placeholder": "Введите номер телефона",
    "templates.pretemplates.form.buttons.type.quick_reply": "Ответ",
    "templates.pretemplates.form.buttons.type.url": "Ссылка",
    "templates.pretemplates.form.buttons.type.phone_number": "Телефон",
    "templates.pretemplates.textarea.marker": "Параметр",
    "templates.amotemplates.empty.title": "Здесь ничего нет",
    "templates.amotemplates.empty.subtitle": "Чтобы управлять шаблонами amoCRM, сначала включите интеграцию",
    "templates.amotemplates.empty.button": "Настроить",
    "templates.amotemplates.form.name.label": "Название",
    "templates.amotemplates.form.pre_template.label": "Пре-шаблон",
    "templates.amotemplates.form.visibility.label": "Видимость",
    "templates.amotemplates.form.visibility.visible": "Вручную и Salesbot",
    "templates.amotemplates.form.visibility.visible_dp": "Только Salesbot",
    "templates.amotemplates.form.file.any": "любой",
    "templates.amotemplates.form.file.up_to": "до",
    "templates.amotemplates.form.file.or": "или",
    "templates.amotemplates.form.header.label": "Шапка",
    "templates.amotemplates.form.image.label": "Изображение",
    "templates.amotemplates.form.document.label": "Документ",
    "templates.amotemplates.form.video.label": "Видео",
    "templates.amotemplates.form.message.label": "Сообщение",
    "templates.amotemplates.form.button.label": "Кнопка",
    "templates.amotemplates.error.empty_name": "Отсутствует наименование шаблона",
    "templates.amotemplates.error.header.too_long": `Не более ${HEADER_MESSAGE_MAX_LENGTH} символов`,
    "templates.amotemplates.error.body.too_long": `Не более ${BODY_MESSAGE_MAX_LENGTH} символов`,
    "templates.amotemplates.error.whitespace_sequence": "Не более 4 пробелов подряд",
    "templates.amotemplates.error.incorrect_url": "Некорректный URL",

    "numbers.button.add_number": "Добавить номер",
    "numbers.form.profile_photo.label": "Фото профиля",
    "numbers.form.short_description.label": "Короткое описание",
    "numbers.form.short_description.placeholder": "Введите описание",
    "numbers.form.extended_description.label": "Расширенное описание",
    "numbers.form.extended_description.placeholder": "Введите текст описания",
    "numbers.form.address.label": "Адрес",
    "numbers.form.address.placeholder": "Введите адрес",
    "numbers.form.type_of_activity.label": "Вид деятельности",
    "numbers.form.website.label": "Веб-сайт",
    "numbers.form.website.placeholder": "Введите веб-сайт",
    "numbers.form.email.label": "Email",
    "numbers.form.email.placeholder": "Введите email",
    "numbers.form.error.photo_size": "Размер файла превышает предел в 5 МБ",
    "numbers.form.error.photo_type": "Тип файла не подходит, только jpg или png",
    "numbers.form.error.photo_status": "Невозможно обработать фото профиля",
    "numbers.form.error.short_description.should_not_be_empty": "Короткое описание не может быть пустым",
    "numbers.form.error.short_description.too_long": `Максимальная длина: ${NUMBERS_SHORT_DESCRIPTION_MAX_LENGTH} знаков`,
    "numbers.form.error.extended_description.too_long": `Максимальная длина: ${NUMBERS_EXTENDED_DESCRIPTION_MAX_LENGTH} знаков`,
    "numbers.form.error.address.too_long": `Максимальная длина: ${NUMBERS_ADDRESS_MAX_LENGTH} знаков`,
    "numbers.form.error.website.incorrect_url": "Веб-сайт должен быть URL-адресом http или https.",
    "numbers.form.error.website.too_long": `Максимальная длина: ${NUMBERS_WEBSITE_MAX_LENGTH} знаков`,
    "numbers.form.error.email.incorrect_format": "Неправильный формат",
    "numbers.form.error.email.first_character": "Первый символ должен быть латинской буквой (a-z) или цифрой (0-9)",
    "numbers.form.error.email.consecutive_signs": "Почта не может содержать идущие подряд знак подчеркивания, точку и минус",
    "numbers.form.error.email.too_long": `Максимальная длина: ${NUMBERS_EMAIL_MAX_LENGTH} знаков`,

    "phone_preview.preview": "Предпросмотр",
    "phone_preview.today": "Сегодня",
    "phone_preview.notification.before": "Сообщения и звонки в данном чате теперь защищены сквозным шифрованием.",
    "phone_preview.notification.after": "может использовать сторонний сервис для хранения и чтения ваших сообщений и ответа на сообщения и звонки. Подробнее.",
    "phone_preview.information": "Данная переписка ведётся с бизнес-аккаунтом. Подробнее.",

    "whatsapp.number_category.automotive": "Автосалон",
    "whatsapp.number_category.beauty_spa_and_salon": "Красота, СПА и салон",
    "whatsapp.number_category.clothes": "Одежда",
    "whatsapp.number_category.education": "Образование",
    "whatsapp.number_category.entertainment": "Развлечение",
    "whatsapp.number_category.event_planning_and_service": "Планирование мероприятий и обслуживание",
    "whatsapp.number_category.finance_and_banking": "Финансы и банковское дело",
    "whatsapp.number_category.food_and_grocery": "Еда и бакалея",
    "whatsapp.number_category.public_service": "Государственная служба",
    "whatsapp.number_category.hotel_and_lodging": "Гостиница и проживание",
    "whatsapp.number_category.medical_and_health": "Медицина и здоровье",
    "whatsapp.number_category.non_profit": "Некоммерческая",
    "whatsapp.number_category.professional_services": "Профессиональные услуги",
    "whatsapp.number_category.shopping_and_retail": "Покупки и розничная торговля",
    "whatsapp.number_category.travel_and_transportation": "Путешествия и транспорт",
    "whatsapp.number_category.restaurant": "Ресторан",
    "whatsapp.number_category.other": "Другое",
    "whatsapp.notification_category.account_update": "Обновление аккаунта",
    "whatsapp.notification_category.alert_update": "Срочное оповещение",
    "whatsapp.notification_category.appointment_update": "Обновление встречи",
    "whatsapp.notification_category.auto_reply": "Автоответ",
    "whatsapp.notification_category.issue_resolution": "Решение вопроса",
    "whatsapp.notification_category.payment_update": "Обновление платежа",
    "whatsapp.notification_category.personal_finance_update": "Обновление личных финансов",
    "whatsapp.notification_category.shipping_update": "Обновление покупки",
    "whatsapp.notification_category.reservation_update": "Обновление брони",
    "whatsapp.notification_category.ticket_update": "Информация об обращении",
    "whatsapp.notification_category.transportation_update": "Обновление транспортировки",
    "whatsapp.notification_category.transactional": "Транзакция",
    "whatsapp.notification_category.marketing": "Маркетинг",
    "whatsapp.notification_category.authentication": "Аутентификация",
    "whatsapp.notification_category.utility": "Услуги",
    "whatsapp.notification_category.otp": "Пароль",
    "whatsapp.template.status.pending": "Модерация",
    "whatsapp.template.status.approved": "Одобрено",
    "whatsapp.template.status.rejected": "Отклонено",
    "whatsapp.template.status.flagged": "Помечено",
    "whatsapp.template.status.disabled": "Отключено",
    "whatsapp.template.header_type.text": "Текстовый",
    "whatsapp.template.header_type.image": "Картинка",
    "whatsapp.template.header_type.video": "Видео",
    "whatsapp.template.header_type.document": "Документ",
    "whatsapp.template.header_type.none": "Отсутствует",
    "whatsapp.template.header_type.sticker": "Стикер",
    "whatsapp.template.header_type.audio": "Аудио",
    "whatsapp.language.ru_RU": "RU",
    "whatsapp.language.en_US": "EN (US)",
    "whatsapp.language.de_DE": "DE",
    "whatsapp.language.fr_FR": "FR",
    "whatsapp.language.pt_PT": "PT",
    "whatsapp.language.es_ES": "ES",
    "whatsapp.language.it_IT": "IT",
    "whatsapp.language.id_ID": "ID",
    "whatsapp.language.fil_FIL": "FIL",
    "whatsapp.language.hi_HI": "HI",
    "whatsapp.language.kk_KK": "KK"
}

export default ru;