type NamedObject = {[key: string]: any}; // Ассоциативный объект со строковыми ключами
type KeysAs<O, V> = {[K in keyof O]: V}; // Сопоставление ключа объекта O с типом V

export namespace DetailVerifiable {
    // Schema - сложный тип, сопоставляющий ключу объекта F
    // массив условий, каждый из которых принимает тип значения из F
    // Пример: {a: number, b: string} -> {a?: [(...values: Array<number>) => boolean], b?: [(...values: Array<string>) => boolean]}
    export type Schema<F extends NamedObject> = {[K in keyof Partial<F>]: ValidatorCondition<F[keyof Pick<F, K>]>[]};
    export type ValidatorCondition<D = any> = [ValidatorCallback<D>, Detail|undefined];
    export type ValidatorCallback<C> = (...values: Array<C>) => boolean;
    export type Result<F> = [boolean, KeysAs<Partial<F>, PartialResult>];
    export type PartialResult = {isValid: boolean, details?: Detail[]};
    export type Detail = string;

    export interface Typed<F extends NamedObject> {
        get validationResult(): Result<F>
        get validationSchema(): Schema<F>
        validate(): Result<F>
    }

    export function verify(conditions?: Array<ValidatorCondition>, ...values: Array<any>): PartialResult {
        let isValid: PartialResult["isValid"] = true;
        let details: PartialResult["details"] = [];
        if (conditions) {
            for (let condition of conditions) {
                const result = condition[0](...values);
                if (result) details.push(condition[1] ?? "Ошибка валидации");

                isValid = isValid && !result;
            }
        }

        return {isValid, details};
    }
}