import {useContext} from 'react';
import {MobXProviderContext, observer} from "mobx-react";
import {AppStore} from "../../../stores/AppStore";
import {NumbersStore} from "../store/NumbersStore";

import iconEmpty from '../../../../image/empty.svg';

const NumbersList = observer(() => {
    const appStore = useContext(MobXProviderContext).root.app as AppStore;
    const numbersStore = useContext(MobXProviderContext).root.numbers as NumbersStore;

    let list = [{
        "title": appStore.accountName,
        "phone": appStore.selectedAccountPhone,
        "photo": numbersStore.data.photo,
    }];

    return (
       <ul className="list__items">
            {list.map((v: any, i: number) => (
                <li key={i} className="list__item list__item--active">
                    <div className="list__item-link">
                        <div className="flex flex--ai-center">
                            <div>
                                <div className="list__item-img">
                                    <img src={v.photo ?? iconEmpty} onError={(e)=>{e.currentTarget.onerror = null; e.currentTarget.src=iconEmpty}} alt={v.title}/>
                                </div>
                            </div>
                            <div className="list__item-cont">
                                <p className="list__item-title">{v.phone}</p>
                                <p className="list__item-subtitle">{v.title}</p>
                            </div>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    )
});

export default NumbersList;