import React, {useEffect, useState} from "react";
import authorizedAxios from '../../http/authorizedAxios';
import {withConditional} from "../../../hooks/ConditionalHOC";
import {observer} from "mobx-react-lite";
import {Link, Route, Switch, useRouteMatch} from "react-router-dom";
import Loading from "../../component/loading/Loading";
import {CampaignElement} from "./CampaignElement";
import amocrmIcon from '../../../image/amocrm.png';
import sad_smiley from '../../../image/sad_smiley.gif';
import {SECOND, HOUR} from '../../../utils/Unit';
import { CampaignSearchField } from "./local_component/CampaignSearchField";
import {i18n} from "../../../i18n/i18n";

export const getTimeString = (timestamp: number): string => {
    const dateObject: Date = new Date(timestamp * 1000);
    const date: number = dateObject.getDate();
    const month: string = dateObject.toLocaleString(i18n("time.lang"), {month:"long"});
    const hours: number = dateObject.getHours();
    const minutes: number = dateObject.getMinutes();
    return `${date} ${month}${i18n("campaigns.time.in")}${hours}:${minutes > 9 ? minutes : '0' + minutes}`;
};

export const useRefreshData = (unixTimestamp: number, loadDataFunctions: Array<CallableFunction>) => {
    useEffect(() => {
        if (unixTimestamp) {
            const startDate = new Date(unixTimestamp * SECOND) as any;
            const now = new Date() as any;
            const hoursDifference = (now - startDate) / HOUR;
            if (hoursDifference < 1) {
                const intervalId = setInterval(() => {
                    loadDataFunctions.map(func => func());
                }, 15 * SECOND);
                return () => clearInterval(intervalId);
            }
        }
    // eslint-disable-next-line
    }, []);
};

const campaignTimeString = (campaignData: any) => {
    if (campaignData) {
        if (campaignData.finished_at) {
            return `${i18n("campaigns.status.sent")}${i18n("campaigns.time.at")}${getTimeString(campaignData.finished_at)}`;
        } else if (campaignData.started_at) {
            return `${i18n("campaigns.status.started")}${i18n("campaigns.time.at")}${getTimeString(campaignData.started_at)}`;
        } else if (campaignData.updated_at) {
            return `${i18n("campaigns.status.changed")}${i18n("campaigns.time.at")}${getTimeString(campaignData.updated_at)}`;
        }
    }
    return '';
};

export const Campaign = observer(() => {
    useEffect(() => {
        document.title = 'W-Target | ' + i18n("title.campaigns");
    }, []);
    const { path, url } = useRouteMatch();

    const [posts, setPosts] = useState<any>({
        loading: true,
        links: null
    });

    const [searchString, setSearchString] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        await authorizedAxios.post(
            '/analytics_data.php',
            {action: 'campaigns'},
            {headers: {"Content-Type": "application/json"}}
        ).then(result => {
            setPosts({loading: false, links: result.data});
        }).catch(err => {
            setPosts({loading: false, links: null});
        });
    };

    // const basket = (
    //     <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <path fillRule="evenodd" clipRule="evenodd" d="M9.33333 2.6665V1.99984C9.33333 1.64622 9.19286 1.30708 8.94281 1.05703C8.69276 0.80698 8.35362 0.666504 8 0.666504H4C3.64638 0.666504 3.30724 0.80698 3.05719 1.05703C2.80714 1.30708 2.66667 1.64622 2.66667 1.99984V2.6665H0.666667C0.489856 2.6665 0.320286 2.73674 0.195262 2.86177C0.0702379 2.98679 0 3.15636 0 3.33317C0 3.50998 0.0702379 3.67955 0.195262 3.80457C0.320286 3.9296 0.489856 3.99984 0.666667 3.99984H1.33333V11.3332C1.33333 11.8636 1.54405 12.3723 1.91912 12.7474C2.29419 13.1225 2.8029 13.3332 3.33333 13.3332H8.66667C9.1971 13.3332 9.70581 13.1225 10.0809 12.7474C10.456 12.3723 10.6667 11.8636 10.6667 11.3332V3.99984H11.3333C11.5101 3.99984 11.6797 3.9296 11.8047 3.80457C11.9298 3.67955 12 3.50998 12 3.33317C12 3.15636 11.9298 2.98679 11.8047 2.86177C11.6797 2.73674 11.5101 2.6665 11.3333 2.6665H9.33333ZM8 1.99984H4V2.6665H8V1.99984ZM9.33333 3.99984H2.66667V11.3332C2.66667 11.51 2.7369 11.6796 2.86193 11.8046C2.98695 11.9296 3.15652 11.9998 3.33333 11.9998H8.66667C8.84348 11.9998 9.01305 11.9296 9.13807 11.8046C9.26309 11.6796 9.33333 11.51 9.33333 11.3332V3.99984Z" fill="rgba(14, 14, 14, 0.35)"/>
    //         <path d="M4 5.33301H5.33333V10.6663H4V5.33301Z" fill="rgba(14, 14, 14, 0.35)"/>
    //         <path d="M6.66668 5.33301H8.00001V10.6663H6.66668V5.33301Z" fill="rgba(14, 14, 14, 0.35)"/>
    //     </svg>
    // );
    // const stop = (
    //     <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <rect x="6" width="4" height="12" fill="#C4C4C4"/>
    //         <rect width="4" height="12" fill="#C4C4C4"/>
    //     </svg>
    // );

    return <Switch>
            <Route exact path={path} children={
                withConditional(
                    <Campaigns links={posts.links} searchString={searchString} setSearchString={setSearchString} fetchData={fetchData} url={url} />,
                    <Loading/>,
                    () => posts.loading === false)
            }/>
            {withConditional(<RoutesCampaignElement links={posts.links} fetchData={fetchData} path={path} />, <></>, () => posts.loading === false)}
        </Switch>
});

function RoutesCampaignElement(props:{links: Array<any>, fetchData: CallableFunction, path: string}) {
    return <>
        {props.links.map(
            //Передача параметра fetchData - временный костыль, сделанный для обновления данных на странице списка рассылок при нажатии кнопки "Назад" в рассылке
            (link: any) => <Route key={link.campaign_id} path={`${props.path}/${link.campaign_id}/:selectedTab?`} children={<CampaignElement element={link} fetchData={props.fetchData} />}/>
        )}
    </>;
}

function Campaigns({links, searchString, setSearchString, fetchData, url}: any) {
    return <>
        <CampaignTitle/>
        {(links?.length) ? <CampaignDefaultCase links={links} searchString={searchString} setSearchString={setSearchString} fetchData={fetchData} url={url}/> : <CampaignEmptyCase/>}
    </>;
}

function CampaignEmptyCase() {
    return <div className="gray-block empty">
        <img className="empty__img" src={sad_smiley} alt="gif"/>
        <h2 className="empty__title">
            {i18n("campaigns.empty.title")}
        </h2>
        <p className="empty__subtitle">
            {i18n("campaigns.empty.subtitle")}
        </p>
        <a href="https://www.amocrm.ru/" target="_blank" className="button button--border ar-right" rel="noreferrer">{i18n("campaigns.empty.button")}</a>
    </div>;
}

function CampaignDefaultCase({links, searchString, setSearchString, fetchData, url}: any) {
    const latestCampaign = links.reduce((previous: any, current: any) => {
        return current.created_at > previous.created_at ? current : previous;
    });
    useRefreshData(latestCampaign.created_at, [fetchData]);

    return <>
        <CampaignHeader searchString={searchString} onSearchChange={setSearchString}/>
        <CampaignTable links={links} searchString={searchString} url={url}/>
    </>;
}

function CampaignTitle() {
    return <h2 className="main__title main__header--indent">
        <div className="main__title-text">
            {i18n("title.campaigns")}
        </div>
    </h2>;
}

function CampaignHeader(props: {searchString: string, onSearchChange: (value: string) => void}) {
    return <ul className="main__header main__header--indent">
        <li className="main__header-cell">
            <CampaignSearchField
                debounceDelayMs={0}
                searchString={props.searchString}
                onDebounce={props.onSearchChange}
            />
        </li>
        <li className="main__header-cell main__header-cell--left">
            <button className="button button--save button-black-plus" disabled>
                <span className="button__text">
                    {i18n("button.create")}
                </span>
            </button>
        </li>
    </ul>;
}

function CampaignTable(props: {links: any[], searchString: string, url: string}) {
    const {links, searchString, url} = props;

    const tableLinks = (links: any[], searchString: string, url: string) => {
        const foundLinks = links.filter(({name}) => {
            const preparedName = name.toLowerCase();
            const preparedSearch = searchString.toLowerCase();

            return preparedName.indexOf(preparedSearch) !== -1
        });

        const analyticsGetParam = (arr: [], code: string) => {
            if(arr) {
                const found = arr.find(item => item['param_code'] === code);
                if(found === undefined) {
                    return '-';
                } else {
                    return found['value'];
                }
            } else {
                return '-';
            }
        }

        return foundLinks.map(({campaign_id, name, status, recipients_amount, analytics_data, finished_at, started_at, updated_at}) => (
            <Link key={campaign_id} to={`${url}/${campaign_id}/preview`} className="table-row table-item table-item-h">
                <div className="table-row__cell">
                    <div className="table-row__inner">
                        <div className="table-item__title">
                            <div className="table-item__title-text">{name}</div>
                            {/* <button className="button table-item__title-btn">
                                {status === "started" ? stop: null}
                                {status === "sent" ? basket: null}
                            </button> */}
                        </div>
                        <div className="table-item__desc">
                            <p>{campaignTimeString({finished_at, started_at, updated_at})}</p>
                            <div className="flex flex--ai-center">
                                <img className="table-item__desc-img" src={amocrmIcon} alt="amoCRM"/>
                                <span>amoCRM</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-row__cell table-row__cell-right">
                    <div className="table-row__inner">{recipients_amount}</div>
                </div>
                <div className="table-row__cell">
                    <div className="table-row__inner">
                        {/* {status === 1 ? <span className="status status--draft">{i18n("campaigns.status.draft")}</span>: ''}
                        {status === 3 ? <span className="status status--waiting">{i18n("campaigns.status.waiting")}</span>: ''} */}
                        {status === "working" ? <span className="status status--process">{i18n("campaigns.status.working")}</span>: ''}
                        {status === "created" || status === "started" ? <span className="status status--process">{i18n("campaigns.status.process")}</span>: ''}
                        {status === "sent" ? <span className="status status--sent">{i18n("campaigns.status.sent")}</span>: ''}
                    </div>
                </div>
                <div className="table-row__cell table-row__cell-right">
                    <div className="table-row__inner">
                        <div className="table-item__checkbox_hover">
                            <div className="table-item__checkbox_hover-no_hover">{analyticsGetParam(analytics_data, 'read')}</div>
                            <div className="table-item__checkbox_hover-hover">{analyticsGetParam(analytics_data, 'read_rate')}</div>
                        </div>
                    </div>
                </div>
                <div className="table-row__cell table-row__cell-right">
                    <div className="table-row__inner">
                        <div className="table-item__checkbox_hover">
                            <div className="table-item__checkbox_hover-no_hover">{analyticsGetParam(analytics_data, 'replied')}</div>
                            <div className="table-item__checkbox_hover-hover">{analyticsGetParam(analytics_data, 'replied_rate')}</div>
                        </div>
                    </div>
                </div>
                <div className="table-row__cell">
                    <div className="table-row__inner">
                        <div className="table-item__btn_open">
                            <span className="table-item__btn_open-text">{i18n("campaigns.table.button.open")}</span>
                            <svg className="table-item__btn_open-icon" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M0 5.00014C0 4.86753 0.0526784 4.74036 0.146447 4.64659C0.240215 4.55282 0.367392 4.50014 0.5 4.50014H12.293L9.146 1.35414C9.05211 1.26026 8.99937 1.13292 8.99937 1.00014C8.99937 0.867368 9.05211 0.74003 9.146 0.646143C9.23989 0.552257 9.36722 0.499512 9.5 0.499512C9.63278 0.499512 9.76011 0.552257 9.854 0.646143L13.854 4.64614C13.9006 4.69259 13.9375 4.74776 13.9627 4.80851C13.9879 4.86926 14.0009 4.93438 14.0009 5.00014C14.0009 5.06591 13.9879 5.13103 13.9627 5.19178C13.9375 5.25252 13.9006 5.3077 13.854 5.35414L9.854 9.35414C9.76011 9.44803 9.63278 9.50077 9.5 9.50077C9.36722 9.50077 9.23989 9.44803 9.146 9.35414C9.05211 9.26026 8.99937 9.13292 8.99937 9.00014C8.99937 8.86737 9.05211 8.74003 9.146 8.64614L12.293 5.50014H0.5C0.367392 5.50014 0.240215 5.44746 0.146447 5.3537C0.0526784 5.25993 0 5.13275 0 5.00014Z" fill="#0E0E0E"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </Link>
        ));
    };

    const compiledLinks = tableLinks(links, searchString, url);

    return <div className="table">
        <div className="table-row table__head">
            <div className="table-row__cell" style={{width:'36%'}}>
                {/* <button className="button table__head-btn_filter"> */}
                {i18n("campaigns.table.head.date")}
                {/* <svg className="table__head-btn_filter-icon" width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.22865 0.671387L0.286652 1.61472L4.99999 6.32805L9.71399 1.61472L8.77132 0.671387L4.99999 4.44272L1.22865 0.671387Z" fill="#0E0E0E" fillOpacity="0.35"/>
                    </svg>
                </button> */}
            </div>
            <div className="table-row__cell table-row__cell-right" style={{width:'12%'}}>{i18n("campaigns.table.head.recipients")}</div>
            <div className="table-row__cell" style={{width:'15%'}}>{i18n("campaigns.table.head.status")}</div>
            <div className="table-row__cell table-row__cell-right" style={{width:'13%'}}>{i18n("campaigns.table.head.read")}</div>
            <div className="table-row__cell table-row__cell-right" style={{width:'13%'}}>{i18n("campaigns.table.head.replied")}</div>
            <div className="table-row__cell"></div>
        </div>
        {(compiledLinks.length > 0) ? compiledLinks : <div className="table__status">{i18n("campaigns.table.status_search.empty")}</div>}
    </div>;
}