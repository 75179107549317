import React, {Children, PropsWithChildren, ReactElement, useEffect, useState} from "react";
import {buildClassName, varClass} from "../../../../utils/StringUtils";

export type ListProps = {
    defaultOptedIndex?: number,
    onOpted?: (optedIndex: number) => void
}

export type ListItemProps = {
    onOpted?: (index: number) => void
}

export const TemplateListItem = ((props: PropsWithChildren<ListItemProps>) => {
    const {children} = props;

    return <>{children}</>;
});

export const TemplateList = ((props: PropsWithChildren<ListProps>) => {
    const {children, defaultOptedIndex, onOpted} = props;

    const [optedIndex, setOpted] = useState(defaultOptedIndex);

    // Примечание: это обновление\синхронизация стейта с изменившимся пропсом.
    useEffect(() => {
        if (optedIndex !== defaultOptedIndex) {
            setOpted(defaultOptedIndex);
        }
    // eslint-disable-next-line
    }, [defaultOptedIndex]);

    const handleItemClick = (clickedIndex: number) => {
        setOpted(clickedIndex);
        if (onOpted) onOpted(clickedIndex);
    }

    return <>
        {Children.map(children, (child, index) => {
            const element = (child as ReactElement<ListItemProps>);
            const {onOpted} = element.props;

            const opted = (index === optedIndex);
            const itemClasses = buildClassName("list__item", varClass(opted, "list__item--active"));
            return <div key={element.key} className={itemClasses}>
                <div className="list__item-link" onClick={e => {
                    handleItemClick(index);
                    if (onOpted) onOpted(index);
                }}>
                    {child}
                </div>
            </div>;
        })}
    </>;
});