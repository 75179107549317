import {useEffect, useState} from "react";

export function useDebouncing(initial: string, delay: number): string {
    const [value, setValue] = useState(initial);

    useEffect(() => {
        const id = setTimeout(() => setValue(initial), delay);
        return () => clearTimeout(id);
    // eslint-disable-next-line
    }, [initial]);

    return value;
}