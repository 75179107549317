import {AmotemplateForm} from "./form/AmotemplatesForm";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {MobXProviderContext} from "mobx-react";
import {AmotemplateModel} from "../../stores/AmotemplatesModel";
import {PretemplateModel} from "../../stores/PretemplatesModel";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {ModelItem} from "../../stores/types/AmotemplateDataModel";
import {AddButton} from "./local_component/AddButton";
import {ListItemProps, TemplateList, TemplateListItem} from "./local_component/TemplateList";
import {Scrollbar} from "smooth-scrollbar-react";
import {SearchField} from "./local_component/SearchField";
import {Amotemplates} from "../../stores/form/amotemplate/AmotemplateFormStore";
import {useStore} from "../../../hooks/StoreHook";
import {Message} from "./local_component/Message";
import {observer} from "mobx-react-lite";
import {useInitialId} from "./hook/InitialIdHook";
import {processArgument} from "../../parser/ArgumentParser";
import {i18n} from "../../../i18n/i18n";
import {TemplateStatusType} from "../../../utils/FacebookAPI";

type ItemIds = {index: number, id: number}

const NULL_ID = -1;
const NULL_OPTED = {index: -1, id: NULL_ID};
const PATH = "/templates/amotemplates";

const AmotemplateListItem = observer((props: ListItemProps&{id: number}) => {
    const {id, ...other} = props;
    const amotemplateModel = useContext(MobXProviderContext).root.amotemplates as AmotemplateModel;

    return <TemplateListItem {...other}>
        <div className="flex flex--ai-center">
            <div className="list__item-cont">
                <p className="list__item-title">{amotemplateModel.getValue(id)?.name}</p>
            </div>
        </div>
    </TemplateListItem>;
});

export const AmotemplateController = withRouter(((props: RouteComponentProps<{id: string}>) => {
    const amotemplateModel = useContext(MobXProviderContext).root.amotemplates as AmotemplateModel;
    const pretemplateModel = useContext(MobXProviderContext).root.pretemplates as PretemplateModel;

    const argument = processArgument<number>(props.match.params.id, arg => /^\d+$/.test(arg) ? Number.parseInt(arg) : null);
    const searchFilter = ({name}: ModelItem, filter: string) => name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;

    const [initialTemplateId, setInitialTemplateId] = useInitialId<number>(PATH, NULL_ID, argument, amotemplateModel);

    const [PhonePreviewState, setPhonePreviewState] = useState('Light');
    const [filter, setFilter] = useState("");
    const [updating, setUpdatingState] = useState(true);
    const [opted, opt] = useState<ItemIds>(() => {
        const index = amotemplateModel.getIndex(initialTemplateId);
        if (index !== null && initialTemplateId >= 0) return {index: index, id: initialTemplateId};

        return NULL_OPTED;
    });

    const [formStore, formStoreDifference, refreshStore] = useStore((id: number) => {
        const store = new Amotemplates.FormStore(pretemplateModel.pretemplateItems);

        const template = amotemplateModel.getValue(id);
        if (template) {
            store.reflectModel(template);
        } else {
            const initialPretemplate = Array.from(amotemplateModel.approvedPretemplates.values())[0];
            if (initialPretemplate) {
                store.data.pretemplate_code = initialPretemplate.code;
            }
        }

        return store;
    }, opted.id);
    const [formState, , refreshState] = useStore((initial?: Amotemplates.State) => new Amotemplates.FormState(initial));

    useEffect(() => {
        const pretemplateId = formStore.data.pretemplate_code ?? pretemplateModel.lastCreatedTemplateId;
        if (pretemplateId) {
            pretemplateModel.downloadItem(pretemplateId)
                .finally(() => {
                    setUpdatingState(false);
                    if (opted.id === NULL_ID) {
                        formState.storeData({mode: "making"});
                    }
                })
        } else {
            console.warn("[AT Controller] (effect) Pretemplate with id: ", pretemplateId, " not found. It's a bug!");
        }
    // eslint-disable-next-line
    }, []);

    function makeAmotemplateAsideItems(filter: string) {
        return amotemplateModel.asSortedByCreatedDate
            .filter(item => searchFilter(item, filter))
            .map((item, index, list) => {
                return <AmotemplateListItem
                    key={item.template_id}
                    id={item.template_id}
                    onOpted={() => {
                        setUpdatingState(true);
                        pretemplateModel.downloadItem(item.code)
                            .then(successfully => {
                                const index = list.findIndex(template => template.template_id === item.template_id);

                                opt({index: index, id: item.template_id});
                                refreshStore(item.template_id);
                                refreshState();
                                setUpdatingState(false);

                                props.history.replace(PATH + ":" + item.template_id);
                            })
                    }}
                />
            });
    }

    function createTemplate() {
        opt(NULL_OPTED);
        refreshStore(NULL_ID);
        refreshState({
            processing: "idle",
            mode: "making",
            isModified: false,
        });
    }

    function handleCreateTemplate() {
        const sortedApprovedPretemplates = pretemplateModel.asSortedByCreatedDate
            .filter(template => template.status === TemplateStatusType.APPROVED);
        const pretemplateId = sortedApprovedPretemplates[0]?.code;
        if (pretemplateId) {
            setUpdatingState(true);
            pretemplateModel.downloadItem(pretemplateId)
                .finally(() => {
                    setUpdatingState(false);
                    createTemplate();
                    props.history.replace(PATH + ":add")
                })
        } else {
            console.warn("[AT Controller] (create) Pretemplate with id: ", pretemplateId, " not found. It's a bug!");
        }
    }

    function handleSave(amotemplate: ModelItem) {
        if (formState.data.mode === "making") { // При создании нового
            formState.storeData({processing: "processing"});
            amotemplateModel.uploadItem(amotemplate)
                .then(successfully => {
                    const madeTemplateId = amotemplateModel.lastUpdatedTemplateId;
                    const madeTemplate = (madeTemplateId) ? amotemplateModel.getValue(madeTemplateId) : null;
                    if (madeTemplate) {
                        setInitialTemplateId(madeTemplate.template_id);
                        opt({index: 0, id: madeTemplate.template_id});
                        refreshStore(madeTemplate.template_id);
                        refreshState({
                            processing: "idle",
                            mode: "idle",
                            isModified: false,
                        });
                        props.history.replace(PATH + ":" + madeTemplate.template_id);
                    } else {
                        console.warn("[AT Controller] (save) Made template not found. It's a bug!");
                    }
                })
                .catch(reason => {
                    console.error("[AT Controller] (save) Unexpected error while uploading a template.");

                    formState.storeData({processing: "processing-failed"});
                    setTimeout(() => {
                        formState.storeData({processing: "idle"});
                    }, 500);
                })
        } else if (formState.data.mode === "modifying") { // При обновлении шаблона
            formState.storeData({processing: "processing"});
            amotemplateModel.updateItem(amotemplate)
                .then(successfully => {
                    formState.storeData({processing: "idle", mode: "idle", isModified: false});
                })
                .catch(reason => {
                    console.error("[AT Controller] (save) Unexpected error while update a template.");

                    formState.storeData({processing: "processing-failed"});
                    setTimeout(() => {
                        formState.storeData({processing: "idle"});
                    }, 500);
                })
        }
    }

    function handleCancel() {
        if (formState.data.mode === "making") {
            createTemplate();
        } else if (formState.data.mode === "modifying") {
            refreshStore(opted.id);
            refreshState();
        }
    }

    function handleDelete() {
        formState.storeData({processing: "processing"});
        amotemplateModel.deleteItem(opted.id)
            .then(successfully => {
                const firstTemplateId = amotemplateModel.getFirstKey();
                if (firstTemplateId) {
                    const pretemplateId = amotemplateModel.getValue(firstTemplateId)?.code;
                    if (pretemplateId) {
                        setUpdatingState(true);
                        pretemplateModel.downloadItem(pretemplateId)
                            .finally(() => {
                                setUpdatingState(false);
                                setInitialTemplateId(firstTemplateId);
                                opt({index: 0, id: firstTemplateId});
                                refreshStore(firstTemplateId);
                                refreshState({
                                    processing: "idle",
                                    mode: "idle",
                                    isModified: false,
                                });
                                props.history.replace(PATH + ":" + firstTemplateId);
                            })
                    } else {
                        console.warn("[AT Controller] (delete) Pretemplate with id: ", pretemplateId, " not found. It's a bug!");
                    }
                } else { // Если шаблонов больше нет
                    handleCreateTemplate(); // Режим создания нового шаблона
                }
            })
    }

    function handleSearch(filter: string) {
        const index = amotemplateModel.asSortedByCreatedDate
            .filter(item => amotemplateModel.approvedPretemplates.has(item.code) && searchFilter(item, filter))
            .findIndex(template => template.template_id === opted.id);

        setFilter(filter);
        opt({index: index, id: opted.id});
    }

    if (pretemplateModel.list.size === 0 || amotemplateModel.approvedPretemplates.size === 0) { // Если прешаблонов еще нет или если нет промодерированных прешаблонов
        return <Message
            title={i18n("templates.amotemplates.empty.title")}
            subtitle={i18n("templates.amotemplates.empty.subtitle")}
        >
            <a href="https://www.amocrm.ru/" target="_blank" className="button button--border" rel="noreferrer">{i18n("templates.amotemplates.empty.button")}</a>
        </Message>;
    }

    return <div className="main__grid main__grid--three">
        <div className="aside">
            <div className="list">
                <SearchField searchString={filter} onChange={handleSearch}/>
                <Scrollbar alwaysShowTracks className="list__items" continuousScrolling={false}>
                    <div>
                        <div>
                            {useMemo(
                                () => <TemplateList defaultOptedIndex={opted.index}>
                                    {makeAmotemplateAsideItems(filter)}
                                </TemplateList>,
                                [opted, filter] // eslint-disable-line react-hooks/exhaustive-deps
                            )}
                            {/* Используется opted целиком, так как при удалении первого амошаблона индекс элемента
                              * в списке(opted.index) не изменяется и не происходит перерисовки списка. */}
                        </div>
                    </div>
                </Scrollbar>
                <AddButton title={i18n("templates.button.add_template")} onClick={handleCreateTemplate} isPressed={argument.value === "add"}/>
            </div>
        </div>
        <AmotemplateForm
            key={formStoreDifference}
            formStore={formStore}
            formState={formState}
            initialPtCode={formStore.data.pretemplate_code ?? ""}
            editable={!updating}
            onSave={handleSave}
            onCancel={handleCancel}
            onDelete={handleDelete}
            PhonePreviewState={PhonePreviewState}
            setPhonePreviewState={setPhonePreviewState}
        />
    </div>;
}));
