import {observer} from "mobx-react-lite";
import {PretemplateForm} from "./form/PretemplatesForm";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {MobXProviderContext} from "mobx-react";
import {PretemplateModel} from "../../stores/PretemplatesModel";
import {TemplateStatusType} from "../../../utils/FacebookAPI";
import {Pretemplates} from "../../stores/form/pretemplate/PretemplateFormStore";
import {PretemplateExample} from "../../stores/form/pretemplate/PretemplateExampleFormStore";
import {PretemplateButtons} from "../../stores/form/pretemplate/PretemplateButtonsFormStore";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {ModelListItem, Pretemplate} from "../../stores/types/PretemplateDataModel";
import {ListItemProps, TemplateList, TemplateListItem} from "./local_component/TemplateList";
import {buildClassName} from "../../../utils/StringUtils";
import {AddButton} from "./local_component/AddButton";
import {Scrollbar} from "smooth-scrollbar-react";
import {SearchField} from "./local_component/SearchField";
import {useStore} from "../../../hooks/StoreHook";
import {useInitialId} from "./hook/InitialIdHook";
import {processArgument} from "../../parser/ArgumentParser";
import {i18n} from "../../../i18n/i18n";

type ItemIds = {index: number, id: string}

const NULL_ID = "";
const NULL_OPTED = {index: -1, id: NULL_ID};
const PATH = "/templates/pretemplates";

const PretemplateListItem = observer((props: ListItemProps&{id: string}) => {
    const {id, ...other} = props;
    const pretemplateModel = useContext(MobXProviderContext).root.pretemplates as PretemplateModel;

    const item = pretemplateModel.getValue(id);

    const statuses = {
        [TemplateStatusType.APPROVED]: "list__item-indicator--green",
        [TemplateStatusType.PENDING]: "list__item-indicator--yellow",
        [TemplateStatusType.REJECTED]: "list__item-indicator--red",
        [TemplateStatusType.DISABLED]: "list__item-indicator--red",
    };

    const indicatorClasses = buildClassName("list__item-indicator", statuses[item?.status ?? TemplateStatusType.DISABLED]);

    return <TemplateListItem {...other}>
        <div className="flex flex--ai-center">
            <div>
                <div className={indicatorClasses}/>
            </div>
            <div className="list__item-cont">
                <p className="list__item-title">{item?.name}</p>
            </div>
        </div>
        <p className="list__item-subtitle">{item?.code}</p>
    </TemplateListItem>;
});


export const PretemplateController = withRouter((props: RouteComponentProps<{id: string}>) => {
    const pretemplateModel = useContext(MobXProviderContext).root.pretemplates as PretemplateModel;

    const argument = processArgument<string>(props.match.params.id, arg => arg);
    const searchFilter = ({name, code}: ModelListItem, filter: string) => {
        const preparedFilter = filter.toLowerCase();

        const preparedName = name.toLowerCase();
        const preparedCode = code.toLowerCase();

        return preparedCode.indexOf(preparedFilter) !== -1 || preparedName.indexOf(preparedFilter) !== -1
    }

    const [initialTemplateId, setInitialTemplateId] = useInitialId<string>(PATH, NULL_ID, argument, pretemplateModel);

    const [PhonePreviewState, setPhonePreviewState] = useState('Light');
    const [filter, setFilter] = useState("");
    const [updating, setUpdatingState] = useState(true);
    const [opted, opt] = useState<ItemIds>(() => {
        const index = pretemplateModel.getIndex(initialTemplateId);
        if (index !== null && initialTemplateId !== "") return {index: index, id: initialTemplateId};

        return NULL_OPTED;
    });

    const [status, setStatus] = useState({type: TemplateStatusType.DISABLED});
    const [formState, , refreshState] = useStore((initial?: Pretemplates.State) => new Pretemplates.FormState(initial));
    const [formStore, formStoreDifference, refreshStore] = useStore((id: string, state: Pretemplates.FormState) => {
        const exampleStore = new PretemplateExample.FormStore();
        const buttonsStore = new PretemplateButtons.FormStore(exampleStore);
        const store = new Pretemplates.FormStore(pretemplateModel.list, state, exampleStore, buttonsStore);

        const template = pretemplateModel.pretemplateItems.get(id);
        if (template) {
            store.reflectModel(template);
            setStatus(template.status);
        }

        return store;
    }, opted.id, formState);

    useEffect(() => {
        if (opted !== NULL_OPTED) {
            pretemplateModel.downloadItem(opted.id)
                .finally(() => {
                    const template = pretemplateModel.pretemplateItems.get(opted.id);
                    if (template) {
                        formStore.reflectModel(template);
                        setStatus(template.status);
                        setUpdatingState(false);
                    }
                })
        } else {
            setUpdatingState(false);
            formState.storeData({mode: "making"})
        }
    // eslint-disable-next-line
    }, []);

    function makePretemplateAsideItems(filter: string) {
        return pretemplateModel.asSortedByCreatedDate
            .filter(item => searchFilter(item, filter))
            .map((item, index, list) => {
                return <PretemplateListItem
                    key={item.code}
                    id={item.code}
                    onOpted={() => {
                        setUpdatingState(true);
                        pretemplateModel.downloadItem(item.code)
                            .then(isUpdated => {
                                const index = list.findIndex(template => template.code === item.code);

                                opt({index: index, id: item.code});
                                refreshStore(item.code, refreshState());
                                setUpdatingState(false);

                                props.history.replace(PATH + ":" + item.code);
                            })
                    }}
                />}
            );
    }

    function createTemplate() {
        opt(NULL_OPTED);
        refreshStore(NULL_ID, refreshState({
            processing: "idle",
            mode: "making",
            isModified: false,
        }));
    }

    function handleCreateTemplate() {
        createTemplate();
        props.history.replace(PATH + ":add")
    }

    function handleSave(pretemplate: Pretemplate, onError?: (code?: string) => void) {
        if (formState.data.mode === "making") { // При создании нового
            formState.storeData({processing: "processing"});
            pretemplateModel.uploadItem(pretemplate)
                .then(successfully => {
                    const madeTemplateId = pretemplateModel.lastUpdatedTemplateId;
                    const madeTemplate = (madeTemplateId) ? pretemplateModel.getValue(madeTemplateId) : null;
                    if (madeTemplate) {
                        setInitialTemplateId(madeTemplate.code);
                        opt({index: 0, id: madeTemplate.code});
                        refreshStore(madeTemplate.code, refreshState({
                            processing: "idle",
                            mode: "idle",
                            isModified: false,
                        }));
                        props.history.replace(PATH + ":" + madeTemplate.code);
                    } else {
                        console.warn("[PT Controller] (save) Made template not found. It's a bug!");
                    }
                })
                .catch(reason => {
                    console.error("[PT Controller] (save) Unexpected error while uploading a template.");

                    if (reason) {
                        try {
                            const error_message = JSON.parse(reason);
                            const error_subcode = error_message.error.error_subcode;

                            if (error_subcode === 2388073) {
                                if (onError) onError("emoji");
                            }
                        } catch (e) {
                            // Nothing
                        }
                    }

                    if (onError) onError();

                    formState.storeData({processing: "processing-failed"});
                    setTimeout(() => {
                        formState.storeData({processing: "idle"});
                    }, 500);
                })
        } else if (formState.data.mode === "modifying") { // При обновлении шаблона
            formState.storeData({processing: "processing"});
            pretemplateModel.updateItem(pretemplate)
                .then(successfully => {
                    formState.storeData({processing: "idle", mode: "idle", isModified: false});
                })
                .catch(reason => {
                    console.error("[PT Controller] (save) Unexpected error while update a template.");

                    formState.storeData({processing: "processing-failed"});
                    setTimeout(() => {
                        formState.storeData({processing: "idle"});
                    }, 500);
                })
        }
    }

    function handleCancel() {
        if (formState.data.mode === "making") {
            createTemplate();
        } else if (formState.data.mode === "modifying") {
            refreshStore(opted.id, refreshState());
        }
    }

    function handleDelete() {
        // Nothing
    }

    function handleSearch(filter: string) {
        const index = pretemplateModel.asSortedByCreatedDate
            .filter(item => searchFilter(item, filter))
            .findIndex(template => template.code === opted.id);

        setFilter(filter);
        opt({index: index, id: opted.id});
    }

    return <div className="main__grid main__grid--three">
        <div className="aside">
            <div className="list">
                <SearchField searchString={filter} onChange={handleSearch}/>
                <Scrollbar alwaysShowTracks className="list__items" continuousScrolling={false}>
                    <div>
                        <div>
                            {useMemo(
                                () => <TemplateList defaultOptedIndex={opted.index}>
                                    {makePretemplateAsideItems(filter)}
                                </TemplateList>,
                                [opted.index, filter] // eslint-disable-line react-hooks/exhaustive-deps
                            )}
                        </div>
                    </div>
                </Scrollbar>
                <AddButton title={i18n("templates.button.add_template")} onClick={handleCreateTemplate} isPressed={argument.value === "add"}/>
            </div>
        </div>
        <PretemplateForm
            key={formStoreDifference}
            formStore={formStore}
            formState={formState}
            status={status}
            editable={!updating}
            onSave={handleSave}
            onCancel={handleCancel}
            onDelete={handleDelete}
            PhonePreviewState={PhonePreviewState}
            setPhonePreviewState={setPhonePreviewState}
        />
    </div>;
});
