import {Store} from "../../core/Store";

export namespace PretemplateExample {
    export type FormData = {
        header?: string, // Может иметь только один параметр
        body?: string[], // Может иметь множество параметров
        buttonUrl?: string, // Только URL кнопка может иметь параметр и только один
        _focusedInput?: number,
    }

    export class FormStore extends Store<FormData> {
        // eslint-disable-next-line
        constructor() {
            super();
        }

        protected makeEmpty(): FormData {
            return {};
        }
    }
}